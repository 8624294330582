import React from 'react';


const TextArea = ({
    placeholder,
    label,
    expanded,
    className,
    error,
    inputSimulate,
    onChange,
    value
}) => {
    const [value_opt, setValue] = React.useState(value)

    const handleChange = (e) => {
        e.preventDefault();
        if (!e.target) {
            return '';
        }
        setValue(e.target.value);
        onChange(e.target.value);
    }

    const expanded_opt = expanded ? "w-100 " : "";

    const classes = "form-group " + expanded_opt + className;
    const errored = error ? "is-invalid" : "";

    if (value !== value_opt) {
        setValue(value);
    }

    return (
        <div className={classes}>
            {
                label &&
                <label htmlFor={`${label}Textarea`} className="my-2">{label}</label>
            }
            <textarea style={{height: (inputSimulate ? 38 : 'auto'), minHeight: 38}} className={"form-control " + errored} id={`${label}Textarea`} placeholder={placeholder} value={value_opt} onChange={handleChange}></textarea>
        </div>
    );
};

export default TextArea;