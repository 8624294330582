import React from 'react';

const ProjectCreateFormStructure = ({ children }) => {
    const [projectForm, collectionForm, contactForm, planificationForm, submitButton] = children;
    const forms = [projectForm, collectionForm, contactForm, planificationForm]
    return (
        <div className="container-fluid">
            {
                forms.map(element => (
                    <div className="py-2">
                        {element}
                    </div>
                ))
            }
            <div className="d-flex justify-content-end W-100 mt-3">
                {submitButton}
            </div>
        </div>
    );
};

export default ProjectCreateFormStructure;