import React from 'react';
import ReactDOM from 'react-dom';
import Router from './utils/router';
import { Provider } from 'react-redux';
import configureStore from 'utils/store';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Provider store={configureStore()}>
        <Router />
    </Provider>,
    document.getElementById('root'));

serviceWorker.register();