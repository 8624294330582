import React, {useState, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { getUserRights } from 'utils/utils';
import { ToastContainer } from 'react-toastify';
import FadeIn from 'react-fade-in';
import IsolationFormModal from "./isolation-form-modal";
import { Button } from 'components/form/button';
var Scroll = require('react-scroll');
var Element = Scroll.Element;

const StepStyling = ({ children }) => {
    const user = useSelector(state => state.user);
    const active_step = useSelector(state => state.stepNavigation.current_step);
    const commissioningDate = useSelector(state => state.stepNavigation.comissioning);
    const onlyProjectManager = useSelector(state => state.user.onlyProjectManager);
    const { WriteRights, ReadRights, SpecialRights, name } = children.props;
    const [modal, setModal] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let isInactive = false;
    let isStepTwo = false;

    if (!getUserRights(user.position, WriteRights) && !getUserRights(user.position, ReadRights)) {
        return (
            <div className={"step-card my-3 card p-4 " + (active_step === name ? '' : 'inactive')}>
                <div className="p-5 text-center">
                    <h4 className="text-primary">Vous n'avez pas accès à cette étape. {name === active_step ? "(En attente du prescripteur)" : ""}</h4>
                </div>
            </div>
        )
    } else if (getUserRights(user.position, ReadRights) || active_step !== name) {
        isInactive = true;
    } else if (getUserRights(user.position, SpecialRights) && !onlyProjectManager) {
        isInactive = true;
    }

    if (name === 'project_confirmation' && commissioningDate && (new Date() < new Date(commissioningDate))) {
        isInactive = true;
    }

    if (name === 'temperature_technical_information') {
        isStepTwo = true;
    }

    return (
      <>
        <div id={name} className={"step-card card p-4" + (isStepTwo ? '' : ' my-3 ') + (isInactive ? ' inactive' : '') } data-toggle="tooltip" data-placement="top" title={active_step === name ? isInactive ? "En attente de l'architecte/bureau d'étude" : "" : "Cette étape est désactivée pour le moment..."}>
            {
                React.cloneElement(children, { CanConfirm: !isInactive })
            }
        </div>
        {isInactive && isStepTwo && (
          <>
              <Button button_title="Consulter le formulaire du niveau d'isolation du bâtiment" bg_color="primary" outlined extended className="col-12" onClick={() => {
                  setModal(true);
              }} />
              <IsolationFormModal isOpen={modal} onClose={() => setModal(false)} disabled />
          </>
        )}
      </>
    )
}

const StepsStructure = ({ children }) => {
    return (
        <>
            <ToastContainer />
            <FadeIn>
            {
                children.length ?
                children.map((element, i) => {
                    if (!element.props || !element.props.name) {
                        return null;
                    }
                    return (
                        <Element name={element.props.name}>
                            <StepStyling>
                                {element}
                            </StepStyling>
                        </Element>
                    )
                }
                ) : <div className="my-3 card p-4">
                    {children}
                </div>
            }
            </FadeIn>
        </>
    );
};

export default StepsStructure;
