import React from 'react';
import SidebarStructure from './sidebar-structure';
import SidebarHeader from './sidebar-header';
import SidebarMenu from './sidebar-menu';
import SidebarFooter from './sidebar-footer';

const SideBar = () => {
    const setWrapperRef = React.useRef(null);

    const handleClickOutside = (event) => {
        if (setWrapperRef.current && !setWrapperRef.current.contains(event.target) && document.querySelector('body').classList.contains('sidebar-open')) {
            var togglers = document.getElementsByClassName('sidebar-toggler');
            var body = document.querySelector('body');
            if (!togglers || !body) {
                return;
            }
            for (var i = 0; i < togglers.length; i++) {
                togglers.item(i).classList.toggle('sidebar-show');
            }
            body.classList.toggle('sidebar-open')
        }
    }

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, {passive: true});
        return () => document.removeEventListener('mousedown', handleClickOutside, {passive: true});
    }, [])

    const onSetWrapperRef = (node) => {
        setWrapperRef.current = node;
    }

    return (
        <SidebarStructure referrer={onSetWrapperRef}>
            <SidebarHeader />
            <SidebarMenu />
            <SidebarFooter />
        </SidebarStructure>
    );
};

export default SideBar;