import React from 'react';
import HistoryFormStructure from './history-form-structure';
import { HistoryFormText, HistoryFormFile, HistoryFormMail } from './history-form-inputs/history-form-types';
import api, { API_ADD_NEW_COMMENT_HISTORY } from 'utils/api';
import { useSelector, useDispatch } from 'react-redux';
import { addFeedPosts } from 'actions/feed-actions';
import { accountPositionAPI } from 'utils/utils';


const HistoryFormInputs = ({ actualForm, savePost, action, error }) => {
    const Forms = [HistoryFormText, HistoryFormFile, HistoryFormMail];

    const ActualForm = Forms[actualForm];
    return (
        <div className="tab-content" id="nav-tabContent">
            <ActualForm returnValue={savePost} value={action} error={error} />
        </div>
    )
}

const HistoryForm = () => {
    const [actualForm, setActualForm] = React.useState(0);
    const [actualPost, setActualPost] = React.useState({});
    const [action, setAction] = React.useState('');
    const [error, setError] = React.useState('');
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (error) {
            setError(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualPost])

    const HistoryFormHead = () => (
        <div className="card-title text-primary font-weight-bold">Ajouter un nouveau post</div>
    )

    const onSubmit = () => {
        if (!actualPost || actualPost.content === '') {
            setError('Il est obligatoire d\'ajouter un contenu à votre publication...');
            return;
        }

        actualPost.content_type = actualForm === 0 ? 'text' : (actualForm === 1 ? 'file' : 'email')

        api.post(API_ADD_NEW_COMMENT_HISTORY(project_id), actualPost, config)
        .then(res => {
            dispatch(addFeedPosts({
                ...res.data,
                account: {
                    position: accountPositionAPI(user.position),
                    [accountPositionAPI(user.position)]: user
                }
            }))
            setAction('resetData')
            setActualForm(0);
            setActualPost({})
            setTimeout(() => setAction(''), 500)
        })
        .catch(err => {
            setError('Une erreur est survenue lors de l\'ajout du post');
            console.error('Create a new post in history ERROR: ', err);
        })
    }

    const HistoryFormNavigator = () => (
        <div className="mt-4 d-flex justify-content-between">
            <div className="btn-group nav nav-tabs" id="nav-tab" role="tablist" aria-label="Basic example">
                <button type="button" className={"btn btn-outline-dark squared" + (actualForm === 0 ? " active" : "")} id="nav-text-tab" onClick={() => setActualForm(0)} >Texte</button>
                <button type="button" className={"btn btn-outline-dark squared" + (actualForm === 1 ? " active" : "")} id="nav-file-tab" onClick={() => setActualForm(1)} >Fichier</button>
                <button type="button" className={"btn btn-outline-dark squared" + (actualForm === 2 ? " active" : "")} id="nav-mail-tab" onClick={() => setActualForm(2)} >Email</button>
            </div>
            <button type="button" className="btn btn-primary squared" onClick={onSubmit}>Ajouter</button>
        </div>
    )

    return (
        <HistoryFormStructure>
            <HistoryFormHead />
            <p className="text-primary text-center">{error}</p>
            <HistoryFormInputs actualForm={actualForm} savePost={setActualPost} action={action} error={error} />
            <HistoryFormNavigator />
        </HistoryFormStructure>
    );
};

export default HistoryForm;