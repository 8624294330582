import React from 'react';
import { Redirect } from 'react-router-dom';
import { removeToken } from 'actions/api-actions';
import { connect } from 'react-redux';
import { loadUser } from 'actions/user-actions';

const Logout = ({ removeToken, userLoad }) => {
    localStorage.removeItem('Authorization');
    removeToken();
    userLoad({position: ''});

    return (
        <Redirect to="/login" />
    );
};

const mapDispatchToProps = dispatch => ({
    removeToken: () => dispatch(removeToken()),
    userLoad: payload => dispatch(loadUser(payload)),
})

export default connect(null, mapDispatchToProps)(Logout);