import React from 'react';
import PropTypes from 'prop-types';
import IsolationFormOptionCheckbox from '../../isolation-form-option-types/isolation-form-option-checkbox';
import IsolationFormOptionInput from '../../isolation-form-option-types/isolation-form-option-input';
import IsolationFormOptionSubtitle from '../../isolation-form-option-types/isolation-form-option-subtitle';
import IsolationFormOptionCheckInput from '../../isolation-form-option-types/isolation-form-option-check-input';
import IsolationFormOptionCheckInputAdvanced from '../../isolation-form-option-types/isolation-form-option-check-input-advanced';

const optionTypes = {
    checkbox: (props) => <IsolationFormOptionCheckbox {...props} />,
    input: (props) => <IsolationFormOptionInput {...props} />,
    subtitle: (props) => <IsolationFormOptionSubtitle {...props} />,
    "checkbox-input": (props) => <IsolationFormOptionCheckInput {...props} />,
    "checkbox-input-advanced": (props) => <IsolationFormOptionCheckInputAdvanced {...props} />
}

const IsolationFormFields = ({ fields, onChange, disabled }) => {
    const [field, setField] = React.useState(fields);

    React.useEffect(() => setField(fields), [fields]);

    const handleChange = (value, current_id) => {
        if (!disabled) {
            const newField = {
                ...field,
                options: field.options.map((el, i) => {
                    if (el.id === current_id) {
                        if (typeof value === 'object') {
                            return {
                                ...el,
                                value: value.value,
                                input: value.input,
                                details: value.details
                            }
                        }
                        return {
                            ...el,
                            value: value,
                        }
                    }
                    return el;
                })}

            if (onChange) {
                onChange(newField);
            }

            setField(newField);
        }
    };

    return (
        <div className="p-1 w-100">
            <h5 className="text-underline font-weight-bold d-inline">{field.title}</h5>
            <div className={`py-2${field.inline ? ' d-flex align-items-center justify-content-between' : ''}`}>
                {
                    field.options &&
                    field.options.map((el, i) => {
                        const Option = optionTypes[el.type];

                        return <Option
                            title={el.title}
                            checkboxVal={el.value}
                            inputVal={el.type === 'input' ? el.value : el.input}
                            onChange={(value) => handleChange(value, el.id)}
                            details={el.details}
                            key={el.id}
                            id={el.id}
                            disabled={disabled}
                        />
                    })
                }
            </div>
        </div>
    );
};

IsolationFormFields.propTypes = {
    fields: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default IsolationFormFields;
