import { toast } from 'react-toastify';
import api, { API_DOWNLOAD_FILE } from './api';
import { ON_DOWNLOAD_FILE_ERROR } from './notifications';
var fileDownload = require('js-file-download');

export const openPriceList = (project_name) => {
    if (!project_name) {
        return;
    }
    window.open(
        `/projet/${project_name}/grille_tarifaire`,
        `Grille tarifaire de ${project_name}`,
        'width=900, height=600'
    );
}

export const isObjectEqual = (x, y) => {
    if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) { return false; }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) { return x === y; }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) { return x === y; }
    if (x === y || x.valueOf() === y.valueOf()) { return true; }
    if (Array.isArray(x) && x.length !== y.length) { return false; }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) { return false; }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) { return false; }
    if (!(y instanceof Object)) { return false; }

    // recursive object equality check
    var p = Object.keys(x);
    return Object.keys(y).every(function (i) { return p.indexOf(i) !== -1; }) &&
        p.every(function (i) { return isObjectEqual(x[i], y[i]); });
}

export const getUserRights = (user_position, rights) => {
    if (!user_position || !rights) {
        return false;
    }
    for (var i = 0; i < rights.length; i++) {
        if (user_position === rights[i]) {
            return true;
        }
    }
    return false;
}

export const notify = (text) => toast.success(text, {
    position: toast.POSITION.TOP_RIGHT
});

export const notify_error = (text) => toast.error(text, {
    position: toast.POSITION.TOP_RIGHT
})

export const accountPositionAPI = (position) => {
    if (!position) {
        return '';
    }
    switch (position) {
        case 'architecture_firm':
            return 'architectureFirm';
        case 'design_office':
            return 'designOffice';
        case 'national_cic':
            return 'nationalCic';
        case 'prescriber':
            return 'prescriber';
        case 'architect':
            return 'architect';
        case 'project_manager':
            return 'projectManager';
        default:
            return '';
    }
}

export const onDownloadFile = (project_id, filename, config) => {
    if (!project_id || !filename || !config) {
        return;
    }
    api.post(API_DOWNLOAD_FILE(project_id), {file_name: filename}, {
        ...config,
        responseType: 'blob'
    })
    .then(res => {
        fileDownload(res.data, filename);
    })
    .catch(err => {
        notify_error(ON_DOWNLOAD_FILE_ERROR(filename));
        console.error('Download file ' + filename + ' ERROR: ', err);
    })
}