import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageHeaderStructure from 'components/header/page-header/page-content-header';
import StepsPageHeader from 'components/header/steps-page/steps-page-header';
import StepsPageStructure from 'components/steps/steps-page-structure';
import { Drawer } from '@material-ui/core';
import { toggleHistoryDrawer, navigateSteps, setCurrentProjectId, setCurrentStep, setProjectGamme, setAllProjectDates, setProjectCurrentPrice,setProjectRatios } from 'actions/steps-actions';
import HistoryBlock from 'components/history';
import StepConception from 'components/steps/step-conception';
import StepExecution from 'components/steps/step-execution';
import StepsConsultation from 'components/steps/step-consultation';
import LoadingScreen from 'react-loading-screen';
import api, { API_GET_PROJECT } from 'utils/api';
import { Button } from 'components/form/button';
import { notify_error } from 'utils/utils';
import Step0 from 'components/steps/step-0';
import TitleHeader from 'components/header/title-header';
import { ON_UNEXISTING_PROJECT, ON_DISABLED_PROJECT_CAN_NOT_ACCESS } from 'utils/notifications';
import { onlyProjectManager } from 'actions/user-actions';

class StepsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
        }
    }

    componentWillMount() {
        this.props.setCurrentProjectId(this.props.match.params.id);

        api.get(API_GET_PROJECT(this.props.match.params.id), this.props.config)
        .then(res => {
            const stepLibellus = res.data.currentStatus;
            const stepNumber = stepLibellus === 'Conception' ? 1 : (stepLibellus === 'Consultation' ? 2 : 3);

            this.props.setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus});
            this.props.setProjectGamme(res.data.collection && res.data.collection.libellus);
            this.props.loadAllProjectDates(res.data);
            this.props.setProjectPrice(res.data.quote && res.data.quote.price);
            this.props.setProjectRatios(res.data);
            this.props.setOnlyProjectManager(res.data.projectManagerIsArchitectureFirm);
            if (this.props.stepView.active !== stepNumber) {
                this.props.navigateSteps(stepNumber);
            }
            this.setState({loading: false});
        })
        .catch(err => {
            if (err.response && err.response.status === 404) {
                notify_error(ON_UNEXISTING_PROJECT)
            } else if (err.response && err.response.status === 400) {
                notify_error(ON_DISABLED_PROJECT_CAN_NOT_ACCESS);
            } else if (err.response && err.response.status === 403) {
                this.props.history.push('/not_allowed');
                return;
            }
            setTimeout(() => {
                this.props.history.push('/')
            }, 4000);
            this.setState({loading: false});
            console.error('Get single project details ERROR: ', err);
        })
    }

    render() {
        const { stepView, match, toggleHistoryDrawer } = this.props;
        const { loading } = this.state;

        const stepsTitle = ["Informations du Projet", "Conception", "Consultation", "Execution"]
        const steps = [Step0, StepConception, StepsConsultation, StepExecution];

        const activeStep = stepView.active;
        const StepsViewer = steps[activeStep];

        return (
            <LoadingScreen
                loading={loading}
                bgColor='#f1f1f1'
                spinnerColor='#C3002F'
                logoSrc='/assets/img/FA_HITACHI_BLA_RGB_Tagline_loading_page.png'
            >
                <PageHeaderStructure>
                    <div className="step-header_fixed-top bg-white py-3 d-flex justify-content-center">
                        <StepsPageHeader project_id={match.params.id} />
                    </div>
                </PageHeaderStructure>
                <StepsPageStructure projectStep={stepView.current_step}>
                    <TitleHeader title={stepsTitle[activeStep]} />
                    {
                        this.props.project_id === this.props.match.params.id &&
                        <StepsViewer />
                    }
                </StepsPageStructure>
                <Drawer
                    anchor="right"
                    open={stepView.pane_open}
                    onClose={() => {
                        toggleHistoryDrawer(false);
                    }}
                >
                    <div className="p-2 bg-light" style={{maxWidth: 600}}>
                        <div className="p-3 d-flex align-items-center justify-content-start">
                            <Button bg_color="primary" onClick={() => toggleHistoryDrawer(false)} button_title={<div className="mdi mdi-close" style={{fontSize: 18}}></div>}></Button>
                            <TitleHeader title="Historique" />
                        </div>
                        <HistoryBlock finished={stepView.current_step} />
                    </div>
                </Drawer>
            </LoadingScreen>
        );
    }
};

const mapStateToProps = state => ({
    stepView: state.stepNavigation,
    project_id: state.stepNavigation.project_id,
    config: state.apiR,
})

const mapDispatchToProps = dispatch => ({
    toggleHistoryDrawer: payload => dispatch(toggleHistoryDrawer(payload)),
    navigateSteps: payload => dispatch(navigateSteps(payload)),
    setCurrentProjectId: payload => dispatch(setCurrentProjectId(payload)),
    setCurrentStep: payload => dispatch(setCurrentStep(payload)),
    setProjectGamme: payload => dispatch(setProjectGamme(payload)),
    loadAllProjectDates: payload => dispatch(setAllProjectDates(payload)),
    setProjectPrice: payload => dispatch(setProjectCurrentPrice(payload)),
    setProjectRatios: payload => dispatch(setProjectRatios(payload)),
    setOnlyProjectManager: payload => dispatch(onlyProjectManager(payload))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepsPage));