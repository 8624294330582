import React from 'react';
import PageHeaderStructure from 'components/header/page-header/page-content-header';
import TableProjects from 'components/project/project-table/project-table';
import ProjectsPageHeader from '../../../components/header/projects-page/projects-page-header';
import { HomePageFooter } from 'components/footer';
import LoadingScreen from 'react-loading-screen';

const ProjectsPage = () => {
    const [loading, isLoading] = React.useState(true);

    setTimeout(() => isLoading(false), 500);

    return (
        <LoadingScreen
            loading={loading}
            bgColor='#f1f1f1'
            spinnerColor='#C3002F'
            logoSrc='/assets/img/FA_HITACHI_BLA_RGB_Tagline_loading_page.png'
        >
            <PageHeaderStructure expanded>
                <ProjectsPageHeader />
            </PageHeaderStructure>
            <div className="container-fluid px-xl-6 pull-up">
                <div className="row">
                    <div className="col-12">
                        <TableProjects />
                    </div>
                </div>
            </div>
            <HomePageFooter />
        </LoadingScreen>
    );
};

export default ProjectsPage;