import React from 'react';

const SidebarStructure = ({ children, referrer }) => {
    const [sidebar_header, sidebar_menu, sidebar_footer] = children
    return (
        <aside className="sidebar-toggler admin-sidebar sidebar-show border-0" ref={(node) => referrer(node)}>
            <div className="admin-sidebar-brand">
                {sidebar_header}
            </div>
            <div className="admin-sidebar-wrapper js-scrollbar">
                {sidebar_menu}
            </div>
            {sidebar_footer}
        </aside>
    );
};

export default SidebarStructure;