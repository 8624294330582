import React from 'react';

const NavbarStructure = props => {
    const sidebarToggle = () => {
        var togglers = document.getElementsByClassName('sidebar-toggler');
        var body = document.querySelector('body');
        if (!togglers || !body) {
            return;
        }
        for (var i = 0; i < togglers.length; i++) {
            togglers.item(i).classList.toggle('sidebar-show');
        }
        body.classList.toggle('sidebar-open')
    }

    return (
        <header className="admin-header">
            <p onClick={sidebarToggle} className="sidebar-toggle text-dark m-0" data-toggleclass="sidebar-open" data-target="body"> </p>

            {props.children}
        </header>
    );
};

export default NavbarStructure;