import React from 'react';

const HistoryFeedStructure = ({ children }) => {
    const [feed_header, feed_content] = children;

    return (
        <>
            <div className="card-header">
                {feed_header}
            </div>
            <div className="card-body">
                {feed_content}
            </div>
        </>
    );
};

export default HistoryFeedStructure;