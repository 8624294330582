import React from 'react';
import TableForm from './project-table-form';
import api, { API_GET_ALL_PROJECTS } from 'utils/api';
import { useSelector } from 'react-redux'


const TableProjects = () => {
    const config = useSelector(state => state.apiR);
    const [data, setData] = React.useState(undefined);

    React.useEffect(() => {
        api.get(API_GET_ALL_PROJECTS, config)
        .then(res => {
            setData(res.data);
        })
        .catch(err => {
            console.error('Loading all projects ERROR: ', err);
        })
    }, [config])

    return (
        <div className="card squared border">
            <div className="card-body">
                {
                    data ?
                    <TableForm data={data} />
                    :
                    <p className="text-primary text-center">Aucun projet ne vous a été assigné.</p>
                }
            </div>
        </div>
    );
};

export default TableProjects;