import React from 'react';
import HistoryBlock from 'components/history';
import LoadingScreen from 'react-loading-screen';
import PageHeaderStructure from 'components/header/page-header/page-content-header';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentProjectId, setCurrentStep, setProjectGamme } from 'actions/steps-actions';
import TitleHeader from 'components/header/title-header';
import api, { API_GET_PROJECT } from 'utils/api';

const HistoryPage = ({ match }) => {
    const [loading, isLoading] = React.useState(true);
    const [project, setProject] = React.useState({});
    const dispatch = useDispatch();
    const config = useSelector(state => state.apiR);

    setTimeout(() => isLoading(false), 500);
    dispatch(setCurrentProjectId(match.params.id))

    React.useEffect(() => {
        api.get(API_GET_PROJECT(match.params.id), config)
        .then(res => {
            setProject(res.data);
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
            dispatch(setProjectGamme(res.data.collection.libellus));
        })
        .catch(err => {
            console.error('Get project details ERROR: ', err);
        })
    }, [config, dispatch, match.params.id])

    return (
        <LoadingScreen
            loading={loading}
            bgColor='#f1f1f1'
            spinnerColor='#C3002F'
            logoSrc='/assets/img/FA_HITACHI_BLA_RGB_Tagline_loading_page.png'
        >
            <PageHeaderStructure>
                <div className="container">
                    <TitleHeader title={`Historique du project nº ${project.number}`} />
                </div>
            </PageHeaderStructure>
            <div className="container pull-up">
                <HistoryBlock finished={project.currentStep} />
            </div>
        </LoadingScreen>
    );
};

export default HistoryPage;
