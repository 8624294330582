import React from 'react';
import { Input, TextArea } from 'components/form/input';
import { FileInputDropzone } from 'components/form/dropzone';
import { Button } from 'components/form/button';
import MySelect from 'components/form/select/select';
import api, { API_GET_TEMPERATURES, API_SET_GET_TECHNICAL_INFO, API_UPDATE_TECHNICAL_INFO, API_GET_FILES_FROM_API, API_DOWNLOAD_COLLECTION_FILES, API_UPDATE_PROJECT } from 'utils/api';
import { connect, useSelector } from 'react-redux';
import { setCurrentStep, setIsolationForm } from 'actions/steps-actions';
import { notify, notify_error, getUserRights } from 'utils/utils';
import { NAME_TYPE_FILE_CONCEPTION_2 } from 'utils/constants';
import { ON_DOWNLOAD_COLLECTION_FILE_ERROR, ON_EMPTY_FIELDS_CONCEPTION2, ON_SAVE_CONCEPTION2_SUCCESS, ON_SAVE_CONCEPTION2_FAIL, WAIT_UNTIL_SAVE_TO_CONTINUE, FILL_ISOLATION_FORM_BEFORE_CONTINUATION } from 'utils/notifications';
import IsolationFormModal from '../isolation-form-modal';
import useNumerical from 'hooks/use-numerical';
var fileDownload = require('js-file-download');

const projectTypeSelect = [
    {
        value: 'new',
        label: 'Neuf'
    },
    {
        value: 'light_renovation',
        label: 'Rénovation légère'
    },
    {
        value: 'heavy_renovation',
        label: 'Rénovation lourde'
    }
]

const DownloadFileElement = ({ file_name }) => {
    const config = useSelector(state => state.apiR);
    const collection = useSelector(state => state.stepNavigation.collection);
    const project_id = useSelector(state => state.stepNavigation.project_id);

    const downloadFile = () => {
        api.get(API_DOWNLOAD_COLLECTION_FILES(project_id), {
            ...config,
            responseType: 'blob'
        })
        .then(res => {
            fileDownload(res.data, 'gamme_' + collection.toLowerCase());
        })
        .catch(err => {
            notify_error(ON_DOWNLOAD_COLLECTION_FILE_ERROR);
            console.error('Download collection files ERROR: ', err);
        })
    }

    return (
        <div className="d-flex flex-row align-items-center justify-content-center px-1">
            <div className="d-flex flex-column align-items-center justify-con pr-6">
                <div className="mdi mdi-file" style={{fontSize: 32}}></div>
                <p className="text-primary">{file_name}</p>
                <Button
                  outlined
                  button_title="Télécharger"
                  bg_color="primary"
                  onClick={downloadFile}
                />
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mdi mdi-file" style={{fontSize: 32}}></div>
                <p className="text-primary">Récapitulatif des gammes - Crédit Mutuel Alliance Fédérale - HITACHI</p>
                <Button
                  outlined
                  button_title="Télécharger"
                  bg_color="primary"
                  onClick={() => {
                      fileDownload('http://admin.hitanet.fr/uploads/recapitulatif_des_gammes.pdf', 'recapitulatif_des_gammes.pdf');

                  }}
                />
            </div>
        </div>
    )
}

const DownloadFilesList = ({ files }) => (
  <div className="d-flex align-items-center justify-content-between w-100 flex-wrap">
      {
          files.map((element, i) => (
            <DownloadFileElement file_name={element} />
          ))
      }
  </div>
)

const TemperaturesForm = ({ projectTemperatures, onChange }) => {
    const [temps, setTemps] = React.useState({
        temp_ext_cold: projectTemperatures.tmp_ext_min,
        temp_ext_hot: projectTemperatures.tmp_ext_max,
    });
    const [error, setError] = React.useState({});
    const [onModify, setModified] = React.useState(false);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);
    const [errored, onValue] = useNumerical();
    const user = useSelector(state => state.user);

    React.useEffect(() => {
        if (projectTemperatures.tmp_ext_min && projectTemperatures.tmp_ext_max) {
            setTemps({
                temp_ext_cold: projectTemperatures.tmp_ext_min.replace(' °C', ''),
                temp_ext_hot: projectTemperatures.tmp_ext_max.replace(' °C', ''),
            });
        }
    }, [projectTemperatures]);

    const handleChange = (value, name) => {
        setModified(true);

        if (onValue(value, name)) {
            setError({...error, [name]: true});
            return;
        } else if (error[name]) {
            setError({...error, [name]: false});
        }

        setTemps({...temps, [name]: value});
    }

    const handleSubmit = () => {
        var errors = {}

        Object.keys(temps).forEach(el => {
            if (!temps[el]) {
                errors[el] = true;
            }
        })

        for (var j = 0; j < Object.keys(errors).length; j++) {
            if (errors[Object.keys(errors)[j]]) {
                notify_error('Les températures sont obligatoires et doivent être remplies...');
                setError(errors);
                return;
            }
        }

        api.put(API_UPDATE_PROJECT(project_id), temps, config)
        .then(res => {
            setModified(false);
            notify('Les modifications ont bien été enregistrées.');
        })
        .catch(err => {
            notify_error('Une erreur est survenue lors de la sauvegarde des modifications...');
            console.error('On save temperature modifications ERROR: ', err);
        })
    }

    return (
        <>
            <div className="col-12 col-sm-6">
                <div className="w-100">
                    <p className="pt-2 mb-0">Intérieur Froid (ºC)</p>
                    <div className="w-100 border-bottom bg-light mt-2">
                        <p className="px-3 m-0" style={{paddingTop: 5, paddingBottom: 6}}>{projectTemperatures.tmp_int_min}</p>
                    </div>
                </div>
                <div className="w-100">
                    <p className="pt-2 mb-0">Intérieur Chaud (ºC)</p>
                    <div className="w-100 border-bottom bg-light mt-2">
                        <p className="px-3 m-0" style={{paddingTop: 5, paddingBottom: 6}}>{projectTemperatures.tmp_int_max}</p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6">
                <Input
                   // disabled={getUserRights(user.position, ['prescriber'])}
                    error={error.temp_ext_cold || errored.temp_ext_cold}
                    onBlur={() => {
                        if (onValue(temps.temp_ext_cold, 'temp_ext_cold')) {
                            setError({...error, temp_ext_cold: true});
                        } else {
                            setError({...error, temp_ext_cold: false});
                        }
                    }}
                    label="Extérieur Froid (ºC)"
                    placeholder="Extérieur Froid"
                    value={temps.temp_ext_cold || ""}
                    onChange={(value) => handleChange(value, 'temp_ext_cold')}
                />
                <Input
                    error={error.temp_ext_hot || errored.temp_ext_hot}
                    onBlur={() => {
                        if (onValue(temps.temp_ext_hot, 'temp_ext_hot')) {
                            setError({...error, temp_ext_hot: true});
                        } else {
                            setError({...error, temp_ext_hot: false});
                        }
                    }}
                    label="Extérieur Chaud (ºC)"
                    placeholder="Extérieur Chaud"
                    value={temps.temp_ext_hot || ""}
                    onChange={(value) => handleChange(value, 'temp_ext_hot')}
                />
            </div>
            {
                onModify &&
                <Button
                    button_title="Sauvegarder"
                    bg_color="dark"
                    extended
                    className="mt-2"
                    onClick={handleSubmit}
                />
            }
        </>
    )
}

class StepConception2 extends React.Component {
    constructor() {
        super();
        this.state = {
            technicalInformation: {},
            hasChange: false,
            draftSaved: false,
            initialFiles: [],
            error: {
                surface: false,
            },
            formOpen: false,
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.getFilesFromAPI = this.getFilesFromAPI.bind(this);
    }

    getFilesFromAPI = () => {
        api.post(API_GET_FILES_FROM_API(this.props.project_id), {type: NAME_TYPE_FILE_CONCEPTION_2}, this.props.config)
        .then(res => {
            this.setState({initialFiles: res.data.default});
        })
        .catch(err => {
            console.error('Getting files for technical information ERROR: ', err);
        })
    }

    componentWillMount() {
        api.get(API_GET_TEMPERATURES(this.props.project_id), this.props.config)
        .then(res => {
            let temperatures = {}

            if (!res.data || !res.data[0]) {
                return;
            }
            temperatures = {
                tmp_int_max: res.data[0].tmpInSummer && res.data[0].tmpInSummer.split(' ')[0],
                tmp_int_min: res.data[0].tmpInWinter && res.data[0].tmpInWinter.split(' ')[0],
                tmp_ext_max: res.data[0].tempExtSummer && res.data[0].tempExtSummer.split(' ')[0],
                tmp_ext_min: res.data[0].tempExtWinter && res.data[0].tempExtWinter.split(' ')[0]
            };
            api.get(API_SET_GET_TECHNICAL_INFO(this.props.project_id), this.props.config)
            .then(res => {
                if (res.data.tmpOutMax) {
                    temperatures.tmp_ext_max = res.data.tmpOutMax;
                }
                if (res.data.tmpOutMin) {
                    temperatures.tmp_ext_min = res.data.tmpOutMin;
                }
                this.props.setIsolationForm(JSON.parse(res.data.isolationLvl));
                this.setState(state => ({
                    ...state,
                    technicalInformation: {
                        ...temperatures,
                        type: projectTypeSelect.filter(el => el.value === res.data.type)[0],
                        surface: res.data.surface,
                        isolation_lvl: res.data.isolationLvl,
                        ext_group_location: res.data.extGroupLocation,
                        project_type: res.data.type
                    }
                }))
            })
            .catch(err => {
                console.error('Get technical information ERROR: ', err);
            })
        })
        .catch(err => {
            console.error('Get temperatures ERROR: ', err);
        })
        this.getFilesFromAPI();
    }

    onSubmit() {
        const { technicalInformation } = this.state;

        if (!this.props.isolation_form || !this.props.isolation_form.length) {
            notify_error(FILL_ISOLATION_FORM_BEFORE_CONTINUATION);
            return;
        }

        technicalInformation.isolation_lvl = JSON.stringify(this.props.isolation_form);

        if (this.state.initialFiles.length === 0 || !technicalInformation.isolation_lvl) {
            notify_error(ON_EMPTY_FIELDS_CONCEPTION2)
            return
        }

        api.put(API_SET_GET_TECHNICAL_INFO(this.props.project_id), technicalInformation, this.props.config)
        .then(res => {
            notify(ON_SAVE_CONCEPTION2_SUCCESS)
            this.props.setCurrentStep({current_step: res.data.project.currentStep, active: res.data.project.currentStatus});
        })
        .catch(err => {
            notify_error(ON_SAVE_CONCEPTION2_FAIL);
            console.error('Set technical info and temperatures ERROR: ', err);
        })
    }

    errorHandleNumber(value) {
        if (!value) {
            return false;
        }
        if (value === '') {
            return false;
        }
        if (value[value.length - 1] === '.' && !value.match(/^[-+]?[0-9]+\.?$/)) {
            return true;
        }
        if (!value.match(/^[-+]?[0-9]*\.?[0-9]*$/)) {
            return true;
        }
        return false;
    }

    handleChange(value, name) {
        if (name === 'surface') {
            if (this.errorHandleNumber(value)) {
                this.setState({error: {surface: true,}})
                return;
            } else if (this.state.error.surface) {
                this.setState({error: {surface: false,}})
            }
        }
        this.setState(state => ({
            ...state,
            hasChange: true,
            technicalInformation: {
                ...state.technicalInformation,
                [name]: value,
            }
        }))
    }

    render() {
        const { technicalInformation, hasChange, draftSaved, initialFiles, error } = this.state;

        const files = [`gamme_${this.props.collection && this.props.collection.toLowerCase()}.pdf`];

        if (hasChange && !draftSaved && this.props.CanConfirm) {
            this.setState({draftSaved: true});
            setTimeout(() => {
                api.put(API_UPDATE_TECHNICAL_INFO(this.props.project_id), this.state.technicalInformation, this.props.config)
                .then(res => {
                    this.setState({hasChange: false, draftSaved: false });
                })
                .catch(err => {
                    console.error('Technical information save draft ERROR: ', err);
                })
            }, 5000);
        }

        return (
            <div id="ConceptionDonneesTechniques">
                <h4 className="text-primary m-0">Températures (°C) à prendre en compte pour le dimensionnement des équipements</h4>
                <div className="row p-3">
                    <TemperaturesForm projectTemperatures={technicalInformation} onChange={(value) => this.handleChange(value, 'temperatures')} />
                </div>
                <h4 className="text-primary m-0">Informations techniques</h4>
                <div className="row px-3 pb-3">
                    <Input error={error.surface} onBlur={() => {
                        if (this.errorHandleNumber(technicalInformation.surface)) {
                            this.setState({error: {surface: true,}})
                        } else {
                            this.setState({error: {surface: false,}})
                        }
                    }} value={technicalInformation.surface || "" } onChange={(value) => this.handleChange(value, 'surface')} label={<span>Surface habitable (m<sup>2</sup>)</span>} placeholder="Surface habitable" expanded className="col-12 col-sm-6" />
                    <MySelect
                        className="col-12 col-sm-6"
                        selectElements={projectTypeSelect}
                        placeholder="Type de projet"
                        label="Type de projet"
                        noOptionsLabel={() => "Vous ne pouvez choisir que entre neuf, rénovation lourde et rénovation légère"}
                        onSelect={(value) => {
                            this.setState(state => ({
                                ...state,
                                hasChange: true,
                                technicalInformation:{
                                    ...state.technicalInformation,
                                    project_type: value.value,
                                    type: value
                                }
                            }));
                        }}
                        defaultValue={technicalInformation.type}
                    />
                    <TextArea value={technicalInformation.ext_group_location || ""} onChange={(value) => this.handleChange(value, 'ext_group_location')} label="Emplacements des groupes extérieurs" placeholder="Emplacements des groupes extérieurs" expanded className="col-12 col-md-6" />
                    <Button button_title="Remplir le formulaire du niveau d'isolation du bâtiment" bg_color="primary" outlined extended className="col-12 col-md-6 my-3 mt-4" onClick={() => this.setState({formOpen: true})} />
                    <FileInputDropzone
                        removeFile={(id) => {
                            this.setState({ initialFiles: this.state.initialFiles.filter(el => el.id !== id)})
                        }}
                        onSubmit={this.getFilesFromAPI}
                        initialFiles={initialFiles}
                        type={NAME_TYPE_FILE_CONCEPTION_2}
                        className="col-12 mt-2"
                        placeholder="Téléverser ou glisser les plans / coupes cotés en format .pdf (obligatoire) et .dwg (facultatif)"
                        button_title="Téléverser des plans"
                        attachment_box_type="not_defined"
                    />
                    <p className="text-muted px-3 mt-n3"><small>* Merci de préciser l’emplacement des groupes extérieurs sur le plan téléversé.</small></p>
                </div>
                <div className="row ml-2">
                    <div className="col-12 col-md-12">
                        <h4 className="text-primary">Documents mis à votre disposition:</h4>
                        <DownloadFilesList files={files} />
                    </div>
                    {
                        this.props.CanConfirm &&
                        <div className="col-12 pt-4 d-flex align-items-end justify-content-center w-100">
                            <Button bg_color="primary" button_title={!draftSaved ? "Confirmer" : (
                                <div class="spinner-border mx-5" style={{fontSize: 9, height: 20, width: 20}} role="status"><span class="sr-only">Loading...</span></div>
                            )} onClick={!draftSaved ? this.onSubmit : () => notify_error(WAIT_UNTIL_SAVE_TO_CONTINUE)} />
                        </div>
                    }
                </div>
                <IsolationFormModal disabled={false} isOpen={this.state.formOpen} onClose={() => this.setState({formOpen: false})} />
            </div>
        );
    }
};

const mapStateToProps = state => ({
    project_id: state.stepNavigation.project_id,
    collection: state.stepNavigation.collection,
    isolation_form: state.stepNavigation.isolation_form,
    config: state.apiR,
})

const mapDispatchToProps = dispatch => ({
    setCurrentStep: payload => dispatch(setCurrentStep(payload)),
    setIsolationForm: payload => dispatch(setIsolationForm(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StepConception2);
