import React from 'react';
import { useSelector } from 'react-redux';
import { getUserRights, notify_error, isObjectEqual } from 'utils/utils';
import { PRESCRIBER_ID } from 'utils/constants';
import { Button } from 'components/form/button';
import { Redirect } from 'react-router-dom';
import ProjectCreateFormStructure from './project-create-form-structure';
import ProjectCreateFormsProject from './project-create-forms/project-create-forms-project';
import ProjectCreateFormsCollection from './project-create-forms/project-create-forms-collection';
import ProjectCreateFormsContact from './project-create-forms/project-create-forms-contact';
import ProjectCreateFormsPlanification from './project-create-forms/project-create-forms-planification';
import api, { API_CREATE_PROJECT, API_SET_MANAGER_CIC } from 'utils/api';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CAN_NOT_CREATE_EMPTY_PROJECT, INVALID_PROJECT_DATES, NO_COLLECTION_ON_PROJECT_ERROR, NO_USERS_ON_PROJECT_ERROR, ADDING_PROJECT_MANAGER_ERROR, PROJECT_NUMBER_ALREADY_EXISTS, PROJECT_REMAINING_FIELDS_ERROR } from 'utils/notifications';

const ProjectCreateForm = ({ history }) => {
    const user = useSelector(state => state.user);
    const config = useSelector(state => state.apiR);
    const [newProject, setNewProject] = React.useState({});
    const [error, setError] = React.useState(false);

    const onSave = (values) => {
        setNewProject({
            ...newProject,
            ...values
        })
    }

    const onSubmit = () => {
        if (isObjectEqual(newProject, {})) {
            notify_error(CAN_NOT_CREATE_EMPTY_PROJECT);
            return;
        }

        if (!newProject.project_manager_state) {
            newProject.project_manager_state = 0;
        }

        if (error || !newProject.start_date || !newProject.end_date || !newProject.signature_date) {
            notify_error(INVALID_PROJECT_DATES);
            return;
        }

        if (!(newProject.project_manager_id && newProject.project_manager_state) && !newProject.architecture_firm_id && !newProject.design_office_id) {
            notify_error(NO_USERS_ON_PROJECT_ERROR);
            return;
        }

        if (!newProject.collection_id) {
            notify_error(NO_COLLECTION_ON_PROJECT_ERROR);
            return;
        }

        if (!newProject.zip_code) {
            notify_error('Le code postal est érroné, veuillez vérifier qu\'il soit complet');
            return;
        }

        if (!newProject.city || !newProject.project_department) {
            notify_error('Vous devez choisir une ville et un département pour créer le projet');
            return;
        }


        api.post(API_CREATE_PROJECT, newProject, config)
        .then(res => {
            if (newProject.manager_id) {
                api.post(API_SET_MANAGER_CIC(res.data.project.id), {project_manager_id: newProject.manager_id}, config)
                .catch(err => {
                    notify_error(ADDING_PROJECT_MANAGER_ERROR);
                    console.error('Add project manager ERROR: ', err);
                })
            }
            history.push(`/projet/${res.data.project.id}/etapes`);
        })
        .catch(err => {
            if (err.response) {
                if (err.response.status === 403) {
                    notify_error(PROJECT_NUMBER_ALREADY_EXISTS);
                } else if (err.response.status === 400) {
                    notify_error(PROJECT_REMAINING_FIELDS_ERROR);
                }
            }
            console.error('Create a project ERROR: ', err);
        })
    }

    return (
        <>
            <ToastContainer />
            {
                getUserRights(user.position, [PRESCRIBER_ID]) ?
                <ProjectCreateFormStructure>
                    <ProjectCreateFormsProject onSave={onSave} />
                    <ProjectCreateFormsCollection onSave={onSave} />
                    <ProjectCreateFormsContact onSave={onSave} />
                    <ProjectCreateFormsPlanification onSave={onSave} onError={setError} />
                    <Button
                        bg_color="primary"
                        button_title="Créer le projet"
                        onClick={onSubmit}
                    />
                </ProjectCreateFormStructure>
                :
                <Redirect to="/" />
            }
        </>
    );
};

export default withRouter(ProjectCreateForm);