import React from 'react';
import { HomePageFooter } from 'components/footer';
import HistoryBlock from 'components/history';

const StepsPageStructure = ({ children, projectStep }) => {
    const [step_header, step_body, step_footer] = children;

    return (
        <div className="container-fluid pull-up">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-8 col-md-10">
                    <div className="card squared mt-3 p-3 border">
                        {step_header}
                        <hr/>
                    </div>
                    {step_body}
                    {
                        step_footer &&
                        <div className="card-footer">
                            {step_footer}
                        </div>
                    }
                </div>
                <div id="HistoryViewer" className="col-lg-4 d-none mt-3">
                    <HistoryBlock finished={projectStep} />
                </div>
            </div>
            <HomePageFooter />
        </div>
    );
};

export default StepsPageStructure;