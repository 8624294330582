import React from 'react';
import { Input } from 'components/form/input';
import { Button } from 'components/form/button';
import GammeSelect from 'components/form/select/gamme-select';
import api, { API_GET_COMPANIES_AND_PRICES, API_SET_COMPANIES_PRICES, API_UPDATE_PROJECT, API_PRICES_CONFIRMATION } from 'utils/api';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentStep } from 'actions/steps-actions';
import { notify_error, notify, openPriceList } from 'utils/utils';
import { Datepicker } from 'components/form/datepicker';
import { defaultTimePicked } from 'utils/constants';
import useNumerical from 'hooks/use-numerical';
import { ON_SAVE_COMPANY_PRICE_ERROR, ON_INVALID_DATES_CONSULTATION2, ON_CONFIRM_STEP_CONSULTATION2_ERROR, ON_CONFIRM_SIGNATURE_DATE_SUCCESS, ON_SAVE_NEW_SIGNATURE_DATE_SUCCESS, ON_SAVE_NEW_SIGNATURE_DATE_FAIL, WAIT_UNTIL_SAVE_TO_CONTINUE, ON_PRICE_LIST_NOT_COMPLETED } from 'utils/notifications';
var moment = require('moment');

const CompanyLine = ({ company, savingData, setSavePrice }) => {
    const [price, setPrice] = React.useState(company.price || '');
    const [error, setError] = React.useState({});
    const priceRef = React.useRef(price);
    priceRef.current = price;
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);
    const savingRef = React.useRef(false);
    const [errored, onValue] = useNumerical();

    const onPriceChange = (value, name) => {
        if (onValue(value, name)) {
            setError({...error, [name]: true,})
            return;
        } else if (error[name]) {
            setError({...error, [name]: false,})
        }
        setPrice(value);
    }

    const savePrice = () => {
        var body = {
            company_id: company.company.id,
            price: priceRef.current
        }

        if (!priceRef.current) {
            body.price = '0';
        }

        setSavePrice(company.company.id, price)

        api.post(API_SET_COMPANIES_PRICES(project_id), body, config)
        .then(res => {
            savingRef.current = false;
            savingData(false);
        })
        .catch(err => {
            savingData(false);
            savingRef.current = false;
            notify_error(ON_SAVE_COMPANY_PRICE_ERROR(company.company.name));
            console.error('Set company price ERROR: ', err);
        })
    }

    return (
        <div className="row my-2" style={{lineHeight: 4}}>
            <div className="col-md-8 col-6">
                <p className="m-0">{company.company.name} - {company.company.siret}</p>
            </div>
            <div className="col-md-4 col-12 bg-white text-dark d-flex justify-content-center align-items-center">
                <Input onBlur={savePrice} error={error[company.company.id] || errored[company.company.id]} placeholder="xxx €" value={price} onChange={(value) => onPriceChange(value, company.company.id)} expanded/>
            </div>
        </div>
    )
}

const StepConsultation2 = ({ gamme_project, CanConfirm, name }) => {
    const [companies, setCompanies] = React.useState(undefined);
    const [saveData, setSaveData] = React.useState(false);
    const [dateConfirmed, setDateConfirmed] = React.useState(false);
    const { project_id, active, current_step } = useSelector(state => state.stepNavigation);
    const projectDates = useSelector(state => state.stepNavigation.dates);
    const [error, setError] = React.useState(false);
    const [dates, setDates] = React.useState({
        signature_date: new Date(projectDates.signature),
        formatted: moment(new Date(projectDates.signature)).format('YYYY-MM-DD') + ' ' + defaultTimePicked,
    });
    const config = useSelector(state => state.apiR);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setDates({
            signature_date: new Date(projectDates.signature),
            formatted: moment(new Date(projectDates.signature)).format('YYYY-MM-DD') + ' ' + defaultTimePicked,
        })
    }, [projectDates.signature])

    React.useEffect(() => {
        api.get(API_GET_COMPANIES_AND_PRICES(project_id), config)
        .then(res => {
            setCompanies(res.data);
        })
        .catch(err => {
            console.error('Get all companies and prices ERROR: ', err);
        })
    }, [project_id, config, active, current_step])

    const onSubmit = () => {
        if (error) {
            notify_error(ON_INVALID_DATES_CONSULTATION2);
            return;
        }

        for (var i = 0; i < companies.length; i++) {
            if (!companies[i].price || companies[i].price === '0') {
                notify_error('Vous devez entrez un prix à chaque entreprise');
                return;
            }
        }

        api.post(API_PRICES_CONFIRMATION(project_id), {}, config)
        .then(res => {
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
        })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                notify_error(ON_PRICE_LIST_NOT_COMPLETED);
                console.error('Confirm prices step ERROR: ', err);
                return;
            }
            notify_error(ON_CONFIRM_STEP_CONSULTATION2_ERROR);
            console.error('Confirm prices step ERROR: ', err);
        })
    }

    const handleDateChange = (dates) => {
        if (error) {
            setError(false);
        }

        setDates({
            signature_date: dates.date,
            formatted: dates.formatted
        })
    }

    const onConfirmSignature = () => {
        if ((new Date(projectDates.signature)).toString() === dates.signature_date.toString()) {
            notify(ON_CONFIRM_SIGNATURE_DATE_SUCCESS);
            setDateConfirmed(true);
            return;
        }

        api.put(API_UPDATE_PROJECT(project_id), { signature_date: dates.formatted }, config)
        .then(res => {
            notify(ON_SAVE_NEW_SIGNATURE_DATE_SUCCESS);
            setDateConfirmed(true);
        })
        .catch(err => {
            notify_error(ON_SAVE_NEW_SIGNATURE_DATE_FAIL);
            console.error('Save new signature date ERROR: ', err);
        })
    }

    const handleSavePrice = (company, price) => {
        setCompanies(companies.map(el => {
            if (el.company.id === company) {
                return {
                    ...el,
                    price: price,
                }
            }

            return el;
        }))
    }

    return (
        <>
            <h4 className="text-primary m-0">Validation de la gamme</h4>
            <GammeSelect gamme_project={gamme_project} className="px-3 d-flex align-items-center justify-content-between" />
            <h4 className="text-primary m-0 mt-2">Confirmation de la date de signature</h4>
            {
                !dateConfirmed ?
                <div className="d-flex flex-column flex-md-row justify-content-between p-3">
                    <Datepicker
                        label='Confirmer la date de signature'
                        handleDateChange={handleDateChange}
                        selectedDate={dates.signature_date}
                        minDate={new Date(projectDates.end_consultation)}
                        errored={() => setError(true)}
                        />
                    <Button
                        extended
                        className="mx-3"
                        button_title={`${((new Date(projectDates.signature)).toString() !== dates.signature_date.toString()) ? "Sauvegarder et confirmer la nouvelle" : "Confirmer la"} date de signature`}
                        bg_color="primary"
                        outlined
                        onClick={onConfirmSignature}
                    />
                </div>
                :
                <div className="p-3">
                    <p className="m-0"><span className="font-weight-bold text-underline">Date de signature confirmée:</span></p>
                    <p className="m-0">La signature ce fera le {moment(dates.signature_date).format('DD/MM/YYYY')}</p>
                </div>
            }
            <h4 className="text-primary m-0 mt-3">Entreprises</h4>
            {
                companies && companies.length > 0 ?
                <>
                    <div className="container-fluid px-xl-5">
                        {
                            companies.map((element, i) => (
                                <CompanyLine company={element} key={i} savingData={setSaveData} setSavePrice={handleSavePrice} />
                            ))
                        }
                        <small className="text-muted">* Les modifications sont sauvegardées automatiquement.</small>
                    </div>
                </>
                :
                <p className="text-muted text-center">Aucune entreprise n'a encore été sélectionnée</p>
            }
            {
                CanConfirm &&
                <div className=" d-flex justify-content-end">
                    <Button
                        className="mr-1"
                        bg_color="dark"
                        button_title="Compléter la grille tarifaire (obligatoire)"
                        onClick={() => openPriceList(project_id)}
                    />
                    <Button
                        type="button"
                        className="py-2 px-4 ml-1"
                        bg_color="primary"
                        button_title={
                            saveData ?
                            <div class="spinner-border mx-5" style={{fontSize: 9, height: 20, width: 20}} role="status"><span class="sr-only">Loading...</span></div>
                            :
                            "Accepter et confirmer l'étape"
                        }
                        onClick={saveData ? () => notify(WAIT_UNTIL_SAVE_TO_CONTINUE) : () => setTimeout(() => onSubmit(), 800)}
                    />
                </div>
            }
        </>
    );
};

export default StepConsultation2;
