import React from 'react';
import PropTypes from 'prop-types';
import IsolationFormOptionCheckbox from './isolation-form-option-checkbox';
import IsolationFormOptionInput from './isolation-form-option-input';

const IsolationFormOptionCheckInputAdvanced = ({ title, checkboxVal, inputVal, details, onChange, disabled }) => {
    const [values, setValues] = React.useState({
        value: checkboxVal,
        input: inputVal,
        details: details,
    })

    React.useEffect(() => {
        setValues({
            value: checkboxVal,
            input: inputVal,
            details: details,
        })
    }, [checkboxVal, inputVal, details])

    const handleChange = (value, name) => {
        if (!disabled) {
            setValues({
                ...values,
                [name]: value,
            });

            if (onChange) {
                onChange({
                    ...values,
                    [name]: value,
                });
            }
        }
    }

    const handleChangeDetails = (value, name) => {
        const newValues = {
            ...values,
            details: values.details.map((el, i) => {
                if (i === name) {
                    return {
                        ...el,
                        value: value,
                    };
                }
                return el;
            }),
        }

        setValues(newValues)

        if (onChange) {
            onChange(newValues)
        }
    }

    return (
        <div className="d-flex align-items-end justify-content-between">
            <div className="d-flex align-items-center justify-content-between pr-3 w-50">
                <IsolationFormOptionCheckbox title={title} checkboxVal={values.value} onChange={(value) => handleChange(value, 'value')} />
                <IsolationFormOptionInput inputSimulate inputVal={values.input} onChange={(value) => handleChange(value, 'input')} />
            </div>
            <div className="d-flex">
                {
                    values.details &&
                    values.details.map((el, i) => <IsolationFormOptionInput inputSimulate label={el.title} placeholder={el.title} inputVal={el.value} onChange={(value) => handleChangeDetails(value, i)} />)
                }
            </div>
        </div>
    );
};

IsolationFormOptionCheckInputAdvanced.propTypes = {
    title: PropTypes.string.isRequired,
    checkboxVal: PropTypes.bool,
    inputVal: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default IsolationFormOptionCheckInputAdvanced;
