import React from 'react';

const StepElementUnvisited = ({children, tooltip}) => {
    return (
        <div data-toggle="tooltip" data-placement="top" title={tooltip}>
            <div className="avatar avatar-sm">
                <div className="avatar-title rounded-circle text-white" style={{boxShadow: 'none', backgroundColor: '#00000030'}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StepElementUnvisited;