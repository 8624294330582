import React from 'react';
import {useSelector, useDispatch, connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProductSelect from 'components/form/select/product-select';
import { Button } from 'components/form/button';
import { FileInputDropzone } from 'components/form/dropzone';
import GammeSelect from 'components/form/select/gamme-select';
import MySwitch from 'components/form/button/switch';
import { openPriceList, notify_error, notify } from 'utils/utils';
import { Datepicker } from 'components/form/datepicker';
import api, { API_GET_PROJECT, API_GET_PROJECT_PRODUCTS, API_GET_FILES_FROM_API, API_SET_COMMISSIONING_DATE, API_SET_PROJECT_TO_FINISH, API_GET_COMMISSIONING_DATE, API_CONFIRM_EXECUTION } from 'utils/api';
import { NAME_TYPE_FILE_EXECUTION_FINAL_STUDY, NAME_TYPE_FILE_EXECUTION_ORDER_SOLD, NAME_TYPE_FILE_EXECUTION_COMMISSIONING, defaultTimePicked } from 'utils/constants';
import { confirmAlert } from 'react-confirm-alert';
import { Drawer } from '@material-ui/core';
import TitleHeader from 'components/header/title-header';
import { ProductBlock } from 'components/products';
import { ON_CHANGE_COMMISSIONING_DATE_ERROR, INVALID_DATE_FINISH_PROJECT, ON_FINISH_PROJECT, ON_FINISH_REDIRECT, ON_FINISH_PROJECT_ERROR, ON_SAVE_MODIFICATIONS_BUTTON, ON_FINISH_PROJECT_WITHOUT_PRODUCTS, ON_FINISH_PROJECT_WITHOUT_FILES, AN_ERROR_OCCURED, FULLFILL_PRICE_LIST_BEFORE_FINISH_PROJECT, SAVE_PRODUCT_MODIFICATION_BEFORE_CONTINUE, ON_CONFIRM_EXECUTION_STEP, ON_CONFIRM_EXECUTION_STEP_FAIL } from 'utils/notifications';
import { reloadPriceProject } from 'actions/feed-actions';
import { toggleHistoryDrawer, navigateSteps, setCurrentProjectId, setCurrentStep, setProjectGamme, setAllProjectDates, setProjectCurrentPrice,setProjectRatios } from 'actions/steps-actions';
var moment = require('moment');

const EXTERIOR_GROUP = 'eg';
const INTERIOR_UNITS = 'ui';
const ORDERED = 'o';

const StepExecution2 = ({ gamme_finale, CanConfirm, history, onIsMESHitachi, setProjectPrice }) => {
    const [MESHitachi, setMESHitachi] = React.useState(true);
    const [selectedDate, setSelectedDate] = React.useState({ date: null, formatted: '' });
    const [initialProducts, setInitialProducts] = React.useState({
        [EXTERIOR_GROUP]: [],
        [INTERIOR_UNITS]: [],
        [ORDERED]: [],
    })
    const [files, setFiles] = React.useState({
        final_study: [],
        order_sold: [],
    })
    const [error, setError] = React.useState(false);
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [productModified, setProductModified] = React.useState(false);
    const [drawerData, setDrawerData] = React.useState(undefined);
    const filesRef = React.useRef(files);
    const config = useSelector(state => state.apiR);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const projectDates = useSelector(state => state.stepNavigation.dates);
    const projectPrice = useSelector(state => state.stepNavigation.current_price);
    const dispatch = useDispatch();
    filesRef.current = files;

    React.useEffect(() => {
        if (!projectDates.comissioning) {
            setMESHitachi(false);
            onIsMESHitachi(false);
            return;
        }
        setSelectedDate({
            date: new Date(projectDates.comissioning),
            formatted: moment(new Date(projectDates.comissioning)).format('YYYY-MM-DD') + ' ' + defaultTimePicked,
        })
    }, [projectDates.comissioning, onIsMESHitachi])

    React.useEffect(() => {
        api.get(API_GET_PROJECT_PRODUCTS(project_id), config)
          .then(res => {
              setInitialProducts({
                  [EXTERIOR_GROUP]: res.data['products_' + EXTERIOR_GROUP.toUpperCase()].map(el => ({...el.productEg, amount: el.amount, name: el.productEg.reference, group: EXTERIOR_GROUP})),
                  [INTERIOR_UNITS]: res.data['products_' + INTERIOR_UNITS.toUpperCase()].map(el => ({...el.productUi, amount: el.amount, name: el.productUi.reference, group: INTERIOR_UNITS})),
                  [ORDERED]: res.data['products_' + ORDERED.toUpperCase()].map(el => ({...el.productO, amount: el.amount, name: el.productO.reference, group: ORDERED}))
              })
          })
          .catch(err => {
              console.error('Get all products of project ERROR: ', err);
          })
        api.get(API_GET_COMMISSIONING_DATE(project_id), config)
          .then(res => {
              if (res.data !== '') {
                  setSelectedDate({ date: new Date(res.data), formatted: res.data });
              }
          })
          .catch(err => {
              console.error('Get commissioning date ERROR: ', err);
          })
        getAllFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, project_id])

    const onMESHitachi = () => {
        onIsMESHitachi(!MESHitachi);
        setMESHitachi(!MESHitachi);
    }

    const handleDateChange = (value) => {
        if (error) {
            setError(false);
        }
        setSelectedDate(value);
        dispatch(setAllProjectDates({commissioningDate: value.date}))
        api.post(API_SET_COMMISSIONING_DATE(project_id), {commissioning_date: value.formatted}, config)
          .catch(err => {
              notify_error(ON_CHANGE_COMMISSIONING_DATE_ERROR);
              console.error('Set commissioning date ERROR: ', err);
          })
    }

    const getFiles = (file_type, file_field) => {
        api.post(API_GET_FILES_FROM_API(project_id), { type: file_type }, config)
          .then(res => {
              setFiles({
                  ...filesRef.current,
                  [file_field]: res.data.default,
              });
          })
          .catch(err => {
              console.error('Get uploaded files ERROR: ', err);
          })
    }

    const getAllFiles = () => {
        const files_fields = ['final_study', 'order_sold', 'commissioning'];
        const files_types = [NAME_TYPE_FILE_EXECUTION_FINAL_STUDY, NAME_TYPE_FILE_EXECUTION_ORDER_SOLD, NAME_TYPE_FILE_EXECUTION_COMMISSIONING];

        files_fields.forEach((file_field, i) => {
            getFiles(files_types[i], file_field);
        });
    }

    const onSubmitFinish = () => {
        api.post(API_SET_PROJECT_TO_FINISH(project_id), {}, config)
          .then(res => {
              dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
              notify(ON_FINISH_PROJECT)
              notify(ON_FINISH_REDIRECT);
              setTimeout(() => history.push('/'), 5000);
          })
          .catch(err => {
              if (err.response && err.response.status === 406) {
                  notify_error(FULLFILL_PRICE_LIST_BEFORE_FINISH_PROJECT);
                  console.error('Set project to finish ERROR: ', err);
                  return;
              }
              console.error('Set project to finish ERROR: ', err);
              notify_error(ON_FINISH_PROJECT_ERROR);
          })
    }

    const errorHandleOnFinish = () => {
        try {
            if (MESHitachi && error) {
                notify_error(INVALID_DATE_FINISH_PROJECT);
                return false;
            }

            var products_list = {
                [EXTERIOR_GROUP]: [],
                [INTERIOR_UNITS]: [],
                [ORDERED]: [],
            }
            Object.keys(initialProducts).forEach(el => products_list[el] = [...products_list[el], ...initialProducts[el]]);

            if (productModified) {
                notify_error(SAVE_PRODUCT_MODIFICATION_BEFORE_CONTINUE);
                return;
            }
            if (!products_list[EXTERIOR_GROUP].length || !products_list[INTERIOR_UNITS].length || !products_list[ORDERED].length) {
                notify_error(ON_FINISH_PROJECT_WITHOUT_PRODUCTS);
                return false;
            }

            if (!files || !files.final_study || !files.order_sold || !files.final_study.length || !files.order_sold.length) {
                notify_error(ON_FINISH_PROJECT_WITHOUT_FILES);
                return false;
            }
        } catch {
            notify_error(AN_ERROR_OCCURED);
            return false;
        }
        return true;
    }

    const onConfirmStep = () => {
        api.post(API_CONFIRM_EXECUTION(project_id), {}, config)
          .then(res => {
              dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
              notify(ON_CONFIRM_EXECUTION_STEP);
          })
          .catch(err => {
              if (err.response && err.response.status === 401) {
                  notify_error(FULLFILL_PRICE_LIST_BEFORE_FINISH_PROJECT);
                  console.error('Set project to finish ERROR: ', err);
                  return;
              }
              if (err.resposne && err.response.status === 403) {
                  notify_error(INVALID_DATE_FINISH_PROJECT);
                  console.error('Set project to finish ERROR: ', err);
                  return;
              }
              notify_error(ON_CONFIRM_EXECUTION_STEP_FAIL);
              console.error('Confirm execution step ERROR: ', err);
          })
    }

    const updatePrice = project_id => {
        setLoadingButton(true);
        api.get(API_GET_PROJECT(project_id), config)
          .then(res => {
              setProjectPrice(res.data.quote && res.data.quote.price);
              setLoadingButton(false);
          })
          .catch(err => {
              setLoadingButton(false);
              console.error('Get single project details ERROR: ', err);
          })
    }

    return (
      <>
          <h4 className="text-primary">Validation des données entrées précédemment</h4>
          <div className="px-2 mb-4">
              <GammeSelect topClass="py-2" gamme_project={gamme_finale} className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-2" />
              <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
                  <p className="m-0 text-underline">Valider le tarif final: {projectPrice} €</p>
                  <Button
                    className="mt-2 mt-sm-0"
                    button_title="Accéder à la grille tarifaire"
                    bg_color="dark"
                    onClick={() => openPriceList(project_id)}
                  />
              </div>
              <Button className="mt-2"
                      button_title={loadingButton ? 'En cours...' : 'Mettre à jour le tarif final depuis la grille tarifaire'}
                      bg_color="dark" onClick={() => updatePrice(project_id)} />
          </div>
          <h4 className="text-primary mb-3">Sélectionner le matériel à commander</h4>
          <div className="px-2">
              <h5 className="font-weight-bold">Groupes extérieurs</h5>
              <ProductSelect onProductChange={setProductModified} onShowDetails={setDrawerData} type={EXTERIOR_GROUP} initialProducts={initialProducts[EXTERIOR_GROUP]} />
              <h5 className="font-weight-bold">Unités intérieures</h5>
              <ProductSelect onProductChange={setProductModified} onShowDetails={setDrawerData} type={INTERIOR_UNITS} initialProducts={initialProducts[INTERIOR_UNITS]} />
              <h5 className="font-weight-bold">Commandes</h5>
              <ProductSelect onProductChange={setProductModified} onShowDetails={setDrawerData} type={ORDERED} initialProducts={initialProducts[ORDERED]} />
          </div>
          {
              productModified &&
              <Button
                button_title="Sauvegarder les changements"
                bg_color="primary"
                outlined
                className="ml-auto"
                onClick={() => {
                    setProductModified(false);
                    notify(ON_SAVE_MODIFICATIONS_BUTTON);
                    api.get(API_GET_PROJECT_PRODUCTS(project_id), config)
                      .then(res => {
                          setInitialProducts({
                              [EXTERIOR_GROUP]: res.data['products_' + EXTERIOR_GROUP.toUpperCase()].map(el => ({...el.productEg, amount: el.amount, name: el.productEg.reference, group: EXTERIOR_GROUP})),
                              [INTERIOR_UNITS]: res.data['products_' + INTERIOR_UNITS.toUpperCase()].map(el => ({...el.productUi, amount: el.amount, name: el.productUi.reference, group: INTERIOR_UNITS})),
                              [ORDERED]: res.data['products_' + ORDERED.toUpperCase()].map(el => ({...el.productO, amount: el.amount, name: el.productO.reference, group: ORDERED}))
                          })
                      })
                      .catch(err => {
                          console.error('Get all products of project ERROR: ', err);
                      })
                }}
              />
          }
          <h4 className="text-primary mb-3">Importer les documents finaux</h4>
          <div className="row px-2">
              <FileInputDropzone
                attachment_box_type='not_defined'
                onSubmit={() => getFiles(NAME_TYPE_FILE_EXECUTION_FINAL_STUDY, 'final_study')}
                initialFiles={files.final_study}
                type={NAME_TYPE_FILE_EXECUTION_FINAL_STUDY}
                className="col-12 col-sm-6"
                placeholder="Téléverser l'étude finale"
                button_title="Téléverser"
                removeFile={(file_id) => setFiles({...files, final_study: files.final_study.filter(el => el.id !== file_id)})}
              />
              <FileInputDropzone
                attachment_box_type='not_defined'
                onSubmit={() => getFiles(NAME_TYPE_FILE_EXECUTION_ORDER_SOLD, 'order_sold')}
                initialFiles={files.order_sold}
                type={NAME_TYPE_FILE_EXECUTION_ORDER_SOLD}
                className="col-12 col-sm-6"
                placeholder="Téléverser le bon de commande"
                button_title="Téléverser"
                removeFile={(file_id) => setFiles({...files, order_sold: files.order_sold.filter(el => el.id !== file_id)})}
              />
          </div>
          <div className="my-3 d-flex align-items-center justify-content-between">
              <p className="m-0">Mise en service effectuée par Hitachi, par une station technique Hitachi ou par une entreprise ayant suivi la formation adaptée ?</p>
              {
                  MESHitachi !== undefined &&
                  <div className="d-flex align-items-center">
                      <span>Non</span>
                      <MySwitch onChange={onMESHitachi} defaultStatus={MESHitachi} />
                      <span>Oui</span>
                  </div>
              }
          </div>
          {
              MESHitachi &&
              <>
                  <div className="row bg-light">
                      <div className="col-12 p-3 ">
                          {
                              projectDates.signature ?
                                <Datepicker
                                  label="Choissez une date de mise en service"
                                  handleDateChange={handleDateChange}
                                  selectedDate={selectedDate.date}
                                  minDate={new Date(projectDates.signature)}
                                  errored={() => setError(true)}
                                />
                                :
                                <p>La date de signature n'a pas encore été enregistrée...</p>
                          }
                      </div>
                  </div>
                  {
                      CanConfirm &&
                      <div className="pt-2 ml-auto">
                          <Button
                            onClick={() => {
                                if (errorHandleOnFinish()) {
                                    onConfirmStep();
                                }
                            }}
                            button_title="Valider l'étape d'éxécution"
                            bg_color="primary"
                            bold
                          />
                      </div>
                  }
              </>
          }
          {
              CanConfirm && !MESHitachi &&
              <div className="pt-2">
                  <Button
                    onClick={() => {
                        if (errorHandleOnFinish()) {
                            confirmAlert({
                                title: 'Le projet est-il terminé ?',
                                message: 'À partir de ce moment vous ne pourrez plus modifier le projet, mais vous serez toujours en mesure de voir les étapes et télécharger les fichiers depuis l\'historique.',
                                buttons: [
                                    {
                                        label: 'Finaliser le projet',
                                        onClick: () => onSubmitFinish()
                                    },
                                    {
                                        label: 'Annuler',
                                    }
                                ]
                            });
                        }
                    }}
                    extended
                    button_title="PROJET TERMINÉ"
                    bg_color="primary"
                    bold
                  />
              </div>
          }
          <Drawer
            anchor="left"
            open={drawerData}
            onClose={() => {
                setDrawerData(undefined);
            }}
          >
              {
                  drawerData &&
                  <div className="p-2 bg-light h-100" style={{maxWidth: 600}}>
                      <div className="p-3 d-flex align-items-center justify-content-start">
                          <Button bg_color="primary" onClick={() => setDrawerData(undefined)} button_title={<div className="mdi mdi-close" style={{fontSize: 18}}></div>}></Button>
                          <TitleHeader title={`Informations techniques de: ${drawerData.reference}`} />
                      </div>
                      <ProductBlock data={drawerData} type={drawerData.type} />
                  </div>
              }
          </Drawer>
      </>
    );
}

const mapStateToProps = state => ({
    reloadProjectPrice: state.feedR.reloadProjectPrice,
})

const mapDispatchToProps = dispatch => ({
    reloadPriceProject: payload => dispatch(reloadPriceProject(payload)),
    setProjectPrice: payload => dispatch(setProjectCurrentPrice(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StepExecution2);
