import React from 'react';
import PropTypes from 'prop-types';
import IsolationFormGroup from './isolation-form-types/isolation-form-group';

const IsolationFormContent = ({ options, onChange, disabled }) => {
    console.log('dans le form 1', disabled)
    const handleChange = (value, current_id) => {
        if (!disabled) {
            if (onChange) {
                onChange(options.map(el => {
                    if (el.id === current_id) {
                        return value;
                    }
                    return el;
                }))
            }
        }
    }

    return (options ? options.map((el, i) => <IsolationFormGroup groups={el} onChange={(value) => handleChange(value, el.id)} disabled={disabled} />) : null);
};

IsolationFormContent.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default IsolationFormContent;
