import React from 'react';
import PriceListDatatableExpandContent from './price-list-datatable-expand-content';

const formatData = (data) => {
    if (!data || !data.quote) {
        return;
    }
    var quotesFields = Object.keys(data.quote).filter(el => el !== 'collection');
    let nbr_collections = 0;
    let oneDerive = [];
    let quotes = [];
    let current_collection = '';

    quotesFields.forEach(el => {
        if (!data.quote[el]) {
            return;
        }
        if (nbr_collections < data.quote[el].length) {
            nbr_collections = data.quote[el].length;
        }
    })

    for (var i = 0; i < nbr_collections; i++) {
        // var actualQuoteCollection = undefined;
        for (var j = 0; j < quotesFields.length; j++) {
            if (data.quote[quotesFields[j]][i] && data.quote[quotesFields[j]][i].collection && current_collection.libellus !== data.quote[quotesFields[j]][i].collection.libellus) {
                current_collection = data.quote[quotesFields[j]][i].collection;
                break;
            }
        }
        if (current_collection === '') {
            continue;
        }

        try {
            for (var k = 0; k < quotesFields.length; k++) {
                var step = data.quote[quotesFields[k]][i];
                var oneStep = {
                    step: quotesFields[k],
                    ...step,
                };

                if (!step || !step.collection || step.collection.libellus !== current_collection.libellus) {
                    // oneDerive.push({step: quotesFields[k], price: '', accessoires: '', smallTertiary: '', monoMulti: '', drv: ''});
                    continue;
                }

                oneDerive.push(oneStep);
                oneStep = {};
            }
        }
        catch {
            oneDerive = [];
            continue;
        }


        if (step && oneDerive) {
            quotes.push({
                collection: step.collection,
                status: oneDerive.sort((elA, elB) => {
                    return sortedQuotes.find((element) => element.name === findTitle(elA.step)).value - sortedQuotes.find((element) => element.name === findTitle(elB.step)).value;
                })
            });
        }
        oneDerive = [];
    }

    return quotes;
}

const sortedQuotes = [
    {
        name: 'standard',
        value: 1
    },
    {
        name: 'conception',
        value: 2
    },
    {
        name: 'consultation',
        value: 3
    },
    {
        name: 'negocié',
        value: 4
    },
    {
        name: 'commandé',
        value: 5
    },
]

const findTitle = (element) => {
    element = element.replace('Quotes', '');
    switch (element) {
        case 'ordered':
            return 'commandé';
        case 'negotiated':
            return 'negocié';
        default:
            return element;
    }
}

const PriceListDatatableExpand = ({ data }) => {
    let formattedData = data;
    if (data.quote) {
        formattedData = formatData(data);
    }

    return (
        <div className="container-fluid mb-3 bg-light">
            <div className="row">
                {
                    data.quote && formattedData ?
                    formattedData.map(el => <PriceListDatatableExpandContent data={el} className={"col-12 " + (formattedData.length !== 1 ? "col-xl-6" : "")} />)
                    :
                    <h4 className="text-primary m-auto p-3">Aucune gamme a été sélectionnée pour ce projet.</h4>
                }
            </div>
        </div>
    );
};

export default PriceListDatatableExpand;