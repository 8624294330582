import React from 'react';
import MyCreatableSelect from 'components/form/select/creatable-select';
import { Input } from 'components/form/input';
import { Button } from 'components/form/button';
import { notify_error } from 'utils/utils';
import { ON_REQUIRED_FIELDS } from 'utils/notifications';
import useNumerical from 'hooks/use-numerical';

const CreatableUserSelectForm = ({
    username,
    data,
    label,
    create_new_title,
    onInputChange,
    onCreate,
    onSelect,
    onClear,
    type,
    noOptionsMsg,
    block
}) => {
    const [newUser, setNewUser] = React.useState(undefined);
    const [defaultInput, setDefaultInput] = React.useState('');
    const [error, setError] = React.useState({});
    const [errored, onValue] = useNumerical();

    const handleChange = (name, value) => {
        if (name === 'phone') {
            if (onValue(value, name)) {
                setError({...error, [name]: true,})
                return;
            }
        }

        if (error[name]) {
            setError({...error, [name]: false,})
        }

        setNewUser({
            ...newUser,
            [name]: value
        });
    }

    const onSubmit = () => {
        const userDataKeys = (Object.keys(newUser) && Object.keys(newUser).filter(element => element !== 'phone'))

        if (userDataKeys.length < (type === 'project_manager' ? 3 : 5)) {
            notify_error(ON_REQUIRED_FIELDS);
            return;
        }

        let errored = false;

        for (var i = 0; i < userDataKeys.length; i++) {
            if (newUser[userDataKeys[i]] === "") {
                setError({...error, [userDataKeys[i]]: true});
                errored = true;
            }
        }

        if (errored) {
            notify_error(ON_REQUIRED_FIELDS);
            return;
        }

        if (newUser.email && !newUser.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)) {
            setError({...error, email: true});
            return;
        } else if (!newUser.email) {
            setError({...error, email: true});
            return;
        }

        onCreate(newUser, (value) => {
            if (value) {
                setDefaultInput(`${type === 'project_manager' ? '' : newUser.siret + ' - '}${newUser.first_name} ${newUser.last_name}`);
                setNewUser(undefined);
            }
        });
    }

    const onInputChange_fct = (value) => {
        if (onInputChange) {
            onInputChange(value);
        }
        setDefaultInput(value);
    }

    return (
        <div className="my-2">
            <h5 className="m-o mb-1"><small className="text-underline font-weight-bold text-capitalize">{label || username + " :"}</small></h5>
            {
                !block ?
                <div className="px-2">
                    <MyCreatableSelect
                        label={`Choisissez un ${username} ${type === 'project_manager' ? '(NOM)' : '(SIRET)'}`}
                        id={username + "Select"}
                        placeholder={`Entrez le ${type === 'project_manager' ? 'nom' : 'numéro SIRET'} ${username === 'architecte' ? "de l'entreprise d'" : "du "}${username === 'architecte' ? 'architecture' : username}`}
                        onChange={(value, action) => {
                            switch (action.action) {
                                case 'create-option':
                                    if (type !== 'project_manager') {
                                        setNewUser({siret: value.label});
                                    } else {
                                        setNewUser({last_name: value.label})
                                    }
                                    onClear();
                                    break;
                                case 'clear':
                                    setNewUser(undefined);
                                    onClear();
                                    break;
                                case 'select-option':
                                    onSelect(value, username);
                                    if (newUser) {
                                        setNewUser(undefined);
                                    }
                                    break;
                                default:
                                    return;
                            }
                        }}
                        defaultValue={data ? data.filter(el => el.label === defaultInput)[0] : null}
                        inputValue={defaultInput}
                        isClearable_opt
                        data={data}
                        onInputChange={onInputChange_fct}
                        formatCreateLabelText={(inputValue) => `Renseigner un ${username === 'architecte' ? 'nouvel' : 'nouveau'} ${username} avec le ${type === 'project_manager' ? 'nom' : 'siret'} suivant: "${inputValue}"`}
                        noOptionsMsg={noOptionsMsg}
                    />
                    {
                        newUser &&
                        <div className="row pt-2 bg-light mb-3 mb-sm-0 mx-1 border mt-2">
                            <h6 className="col-12 text-primary m-0">{create_new_title} :</h6>
                            <Input error={error.last_name} value={newUser.last_name || ""} onChange={(value) => handleChange('last_name', value)} className={`col-12 col-sm-6 col-md-${type === 'project_manager' ? 6 : 4}`} label="Nom *" placeholder="Nom" expanded />
                            <Input error={error.first_name} value={newUser.first_name || ""} onChange={(value) => handleChange('first_name', value)} className={`col-12 col-sm-6 col-md-${type === 'project_manager' ? 6 : 4}`} label="Prénom *" placeholder="Prénom" expanded />
                            <Input type="email" error={error.email} value={newUser.email || ""} onChange={(value) => handleChange('email', value)} className={`col-12 col-sm-12 col-md-${type === 'project_manager' ? 6 : 4}`} label="Adresse mail *" placeholder="Adresse mail" expanded />
                            <Input error={error.phone || errored.phone} value={newUser.phone || ""} onChange={(value) => handleChange('phone', value)} className='col-12 col-sm-6' label="Numéro de téléphone" placeholder="Numéro de téléphone" expanded />
                            {
                                type !== 'project_manager' &&
                                <>
                                    <Input error={error.siret} value={newUser.siret || ""} onChange={(value) => handleChange('siret', value)} className="col-12 col-sm-6" label="SIRET" placeholder="SIRET *" expanded />
                                    <Input error={error.social_reason} value={newUser.social_reason || ""} onChange={(value) => handleChange('social_reason', value)} className="col-12" label="Raison sociale *" placeholder="Raison sociale" expanded />
                                </>
                            }
                            <Button
                                bg_color="primary"
                                button_title="Créer"
                                bold
                                extended
                                className="ml-auto col-12 mt-3"
                                onClick={onSubmit}
                            />
                        </div>
                    }
                </div>
                :
                <p className="text-muted">Le chargé de projet CIC est à maîtrise d'oeuvre.</p>
            }
        </div>
    );
};

export default CreatableUserSelectForm;