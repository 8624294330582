import React from 'react';

const StepElementActive = ({children, tooltip}) => {
    return (
        <div data-toggle="tooltip" data-placement="top" title={tooltip}>
            <div className="avatar avatar-sm">
                <div className="avatar-title shadow rounded-circle bg-primary border border-light text-white" style={{borderWidth: 2 + '!important', borderColor: '#00000030'}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StepElementActive;