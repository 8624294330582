import React from 'react';
import ProfilePageStructure from './profile-page-structure';
import PageHeaderStructure from 'components/header/page-header/page-content-header';
import { Input } from 'components/form/input';
import { useSelector, useDispatch } from 'react-redux';
import { PRESCRIBER_ID, NATIONAL_CIC_ID, ARCHITECTURE_FIRM_ID, ARCHITECT_ID, DESIGN_OFFICE_ID, PROJECT_MANAGER_ID } from 'utils/constants';
import { Button } from 'components/form/button';
import api, { API_UPDATE_USER_PROFILE } from 'utils/api';
import { loadUser } from 'actions/user-actions';
import { ToastContainer } from 'react-toastify';
import { notify, notify_error } from 'utils/utils';
import TitleHeader from 'components/header/title-header';
import { ON_SAVED_PROFILE_DATA_SUCCESS, AN_ERROR_OCCURED } from 'utils/notifications';

const ProfilePage = () => {
    const config = useSelector(state => state.apiR);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [userData, setUserData] = React.useState({
        ...user,
        first_name: user.firstName,
        last_name: user.lastName
    });

    const handleChange = (value, name) => {
        setUserData({
            ...userData,
            [name]: value,
        })
    }

    React.useEffect(() => {
        let position = '';
        switch (user.position) {
            case PRESCRIBER_ID:
                position = 'prescripteur Hitachi'
                break;
            case NATIONAL_CIC_ID:
                position = 'national CIC'
                break;
            case ARCHITECTURE_FIRM_ID:
                position = 'cabinet d\'architecte'
                break;
            case ARCHITECT_ID:
                position = 'architecte référent'
                break;
            case DESIGN_OFFICE_ID:
                position = 'bureau d\'études'
                break;
            case PROJECT_MANAGER_ID:
                position = 'chargé de projet CIC'
                break;
            default:
                position = ''
                break;
        }
        setUserData({
            ...user,
            position: position,
            first_name: user.firstName,
            last_name: user.lastName
        })
    }, [user])

    const onSubmit = () => {
        api.put(API_UPDATE_USER_PROFILE, {...userData, position: user.position}, config)
        .then(res => {
            notify(ON_SAVED_PROFILE_DATA_SUCCESS);
            dispatch(loadUser(res.data));
        })
        .catch(err => {
            notify_error(AN_ERROR_OCCURED);
            console.error('Update user information ERROR: ', err);
        })
    }

    return (
        <>
            <PageHeaderStructure>
            </PageHeaderStructure>
            <ToastContainer />
            <ProfilePageStructure>
                <>
                    <TitleHeader title="Données personnelles" />
                    {
                        userData.position &&
                        <p className="m-0 px-2 mt-n2 text-primary">Vous êtes connecté en tant que {userData.position}.</p>
                    }
                </>
                <div className="row px-3">
                    <Input value={userData.last_name} onChange={(value) => handleChange(value, 'last_name')} className="col-12 col-sm-6" label="Nom" placeholder="Nom" />
                    <Input value={userData.first_name} onChange={(value) => handleChange(value, 'first_name')} className="col-12 col-sm-6" label="Prénom" placeholder="Prénom" />
                    <Input value={userData.phone} onChange={(value) => handleChange(value, 'phone')} className="col-12 col-sm-6" label="Numéro de téléphone" placeholder="Numéro de téléphone" type="tel" />
                    <div className="col-12 col-sm-6">
                        <p className="pt-2 mb-0">Adresse mail </p>
                        <div className="w-100 border-bottom bg-light mt-2">
                            <p className="px-3 m-0" style={{paddingTop: 5, paddingBottom: 6}}>{userData.email}</p>
                        </div>
                    </div>
                    {/* <Input value={userData.email} onChange={(value) => handleChange(value, 'email')} className="col-12 col-sm-6" label="Adresse email" placeholder="Adresse email" type="email" /> */}
                </div>
                <Button onClick={onSubmit} bg_color="primary" button_title="Sauvegarder" className="m-2 mt-3 ml-auto" />
            </ProfilePageStructure>
        </>
    );
};

export default ProfilePage;