import React from 'react';

const SidebarMenu = () => {
    const menu_elements = [
        {
            "title": "Projets",
            "logo": "folder-multiple",
            "href": "/"
        },
        {
            "title": "Mon profil",
            "logo": "account",
            "href": "/mon_profil"
        }
    ]

    return (
        <ul className="menu">
            {
                menu_elements &&
                menu_elements.map((element, i) => (
                    <li className="menu-item active" key={i}>
                        <a href={element.href} className="menu-link">
                            <span className="menu-label">
                                 <span className="menu-name">{element.title}</span>
                            </span>
                            <span className="menu-icon squared">
                                <i className={`icon-placeholder mdi mdi-${element.logo}`}></i>
                            </span>
                        </a>
                    </li>
                ))
            }
        </ul>
    );
};

export default SidebarMenu;