const user = {
    position: "",
}

export default (state = user, action) => {
    switch(action.type) {
        case 'loadUser':
            return {
                ...user,
                ...action.payload
            }
        case 'removeUser':
            return user;
        case 'isOnlyHimOnProject':
            return {
                ...state,
                onlyProjectManager: action.payload
            }
        default:
            return state;
    }
}