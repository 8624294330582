import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'components/form/input';

const IsolationFormOptionInput = ({ placeholder, label, inputSimulate, onChange, inputVal, disabled }) => {
    const [value, setValue] = React.useState(inputVal || '');

    React.useEffect(() => setValue(inputVal), [inputVal]);

    const handleChange = (textValue) => {
        if (!disabled) {
            setValue(textValue);

            if (onChange) {
                onChange(textValue);
            }
        }
    }

    return (
        <TextArea className="m-0" placeholder={placeholder} label={label} inputSimulate={inputSimulate} onChange={handleChange} value={value} />
    );
};

IsolationFormOptionInput.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    inputSimulate: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    inputVal: PropTypes.string,
};

export default IsolationFormOptionInput;
