import React from 'react';

const HomeStructure = ({ children }) => {
    const [home_sidebar, home_navbar_top, home_page_content] = children;
    return (
        <div className="page">
            {home_sidebar}
            <main className="admin-main">
                {home_navbar_top}
                <section className="admin-content">
                    {home_page_content}
                </section>
            </main>
        </div>
    );
};

export default HomeStructure;