import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'components/form/button';
import { useSelector, useDispatch } from 'react-redux';
import { ARCHITECTURE_FIRM_ID, DESIGN_OFFICE_ID } from 'utils/constants';
import { getUserRights, accountPositionAPI } from 'utils/utils';
import { Input } from 'components/form/input';
import api, { API_REGISTER } from 'utils/api';
import queryString from 'query-string';
import { loadUser } from 'actions/user-actions';
import { setToken } from 'actions/api-actions';
import Lottie from 'react-lottie';
import * as errorAnim from 'assets/anims/error-anim.json';

const errorOptionsAnim = {
    loop: false,
    autoplay: true,
    animationData: errorAnim.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const RegisterForm = ({ location, history }) => {
    const user = useSelector(state => state.user);
    const [form, setValue] = React.useState(user ? {
        first_name: user.firstName,
        last_name: user.lastName,
        phone_number: user.phone_number,
        siret: user.siret,
        password: "",
        confirm_new_password: ""
    } : {});
    const [error, setError] = React.useState({});
    const [error_msg, setErrorMessage] = React.useState('');
    const [notAllowed, setNotAllowed] = React.useState(false);
    const dispatch = useDispatch();

    const handleChange = (name, data, other) => {
        if (name === 'phone_number') {
            if (data && !data.match(/^\d+(\.\d+)*$/)) {
                setError({...error, [name]: true,})
                return;
            }
        }


        if (error[name]) {
            setError({...error, [name]: false});
        }

        if (name.includes('password')) {
            if (data !== form[other]) {
                setError({...error, new_password: true, confirm_new_password: true});
            } else {
                setError({...error, new_password: false, confirm_new_password: false})
            }
        }

        setValue({...form, [name]: data})
    }

    const onRegister = (e) => {
        e.preventDefault();

        if (form && (!form.first_name || !form.last_name)) {
            setErrorMessage('Le prénom ou le nom doivent être renseignés...');
            return;
        }

        for (var i = 0; i < Object.keys(error).length; i++) {
            if (error[Object.keys(error)[i]]) {
                setErrorMessage('Verifiez que tous les champs sont bien remplis avant de confirmer.');
                return;
            }
        }

        if (getUserRights(user.position, [ARCHITECTURE_FIRM_ID, DESIGN_OFFICE_ID]) && !form.siret) {
            setErrorMessage('Le numéro de SIRET doit être renseigné obligatoirement.');
            return;
        }

        var params = {
            ...form,
            username: user ? user.email : '',
            token: queryString.parse(location.search).token
        }

        if (!params.token) {
            setErrorMessage('Une erreur est survenue lors de la confirmation de l\'inscription... Essayez en reouvrant le lien du mail. Merci.')
            return;
        }

        api.post(API_REGISTER, params)
        .then(res => {
            if (!res.data || !res.data.account || !res.data.token) {
                setErrorMessage('Une erreur est survenue...');
                return;
            }
            dispatch(loadUser({
                position: res.data.account.position,
                ...res.data.account[accountPositionAPI(res.data.account.position)]
            }));
            dispatch(setToken(res.data.token));
            history.push('/');
        })
        .catch(err => {
            setErrorMessage('Une erreur est survenue lors de la confirmation de l\'inscription... Essayez en reouvrant le lien du mail. Merci.')
            console.error('On register ERROR: ', err);
        })
    }

    if ((queryString.parse(location.search) && !queryString.parse(location.search).token) && !notAllowed) {
        setNotAllowed(true);
    }

    return (
        <>
        {
            !notAllowed ?
            <>
                <h3 class="text-center p-b-20 font-weight-bold">S'inscrire</h3>
                {
                    error_msg &&
                    <p className="text-primary text-center">{error_msg}</p>
                }
                <form class="needs-validation" onSubmit={onRegister}>
                    <div class="form-row">
                        <Input error={error.first_name} className="col-12 col-md-6" value={form.first_name || ''} label="Prénom" placeholder="Prénom" onChange={value => handleChange('first_name', value)} />
                        <Input error={error.last_name} className="col-12 col-md-6" value={form.last_name || ''} label="Nom" placeholder="Nom" onChange={value => handleChange('last_name', value)} />
                        <Input error={error.phone_number} className="col-12 col-md-12" value={form.phone_number || ''} label="Numéro de téléphone" placeholder="Numéro de téléphone" onChange={value => handleChange('phone_number', value)} />
                        {
                            getUserRights(user.position, [ARCHITECTURE_FIRM_ID, DESIGN_OFFICE_ID]) &&
                            <Input error={error.siret} className="col-12 col-md-12" value={form.siret || ''} label="SIRET" placeholder="SIRET" onChange={value => handleChange('siret', value)} />
                        }
                        <Input type="password" error={error.password} className="col-12 col-md-12" value={form.password || ''} label="Nouveau mot de passe" placeholder="Nouveau mot de passe" onChange={value => handleChange('password', value, 'confirm_new_password')} />
                        <Input type="password" error={error.confirm_new_password} className="col-12 col-md-12" value={form.confirm_new_password || ''} label="Confirmation nouveau mot de passe" placeholder="Confirmation nouveau mot de passe" onChange={value => handleChange('confirm_new_password', value, 'password')} />
                    </div>
                    <Button
                        outlined
                        bold
                        extended
                        type="submit"
                        bg_color="primary"
                        className="py-2 px-4 mt-3"
                        button_title="Confirmer"
                        />
                </form>
            </>
            :
            <>
                <Lottie options={errorOptionsAnim} width="120" height="120" />
                <p className="text-center position-absolute mt-2">Une erreur est survenue lors du chargement de cette page</p>
                <Button onClick={() => history.push('/login')} className="mt-6 position-absolute" style={{width: 200, left: -50}} bg_color="dark" button_title="Vers la page de connection" outlined />
            </>
        }
        </>
    );
};

export default withRouter(RegisterForm);
