import React from 'react';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import api, { API_UPLOAD_FILES } from 'utils/api';
import { notify_error } from 'utils/utils';
import { DELETE_FILE_ERROR } from 'utils/notifications';

let removedOnSubmit = false;

const FileInputDropzone = ({
    placeholder,
    className,
    type,
    from_name,
    initialFiles,
    removeFile,
    onSubmit,
    history,
    attachment_box_type }) => {
    const project_id = useSelector(state => state.stepNavigation.project_id)
    const config = useSelector(state => state.apiR)

    const getUploadParams = async ({file, meta}) => {
        const body = new FormData();
        const getBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        })

        body.append('type', type)
        if (history) {
            onSubmit({
                type: type,
                file_name: file.name,
                data: (await getBase64(file)).split(',')[1],
            })
            body.append('uploaded_files', JSON.stringify([['', '', '']]))
            body.append('attachment_box_name', attachment_box_type);
            return {
                url: 'https://admin.hitanet.fr' + API_UPLOAD_FILES(project_id),
                body: body,
                headers: config.headers
            }
        } else {
            body.append('uploaded_files', JSON.stringify([[(await getBase64(file)).split(',')[1], file.name, from_name]]))
            body.append('attachment_box_name', attachment_box_type);
            return {
                url: 'https://admin.hitanet.fr' + API_UPLOAD_FILES(project_id),
                body: body,
                headers: config.headers
            }
        }
    };

    const handleChangeStatus = async ({ file, meta }, status) => {
        if (status === 'removed' && !removedOnSubmit) {
            const body = new FormData();
            const getBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            })

            body.append('type', type);
            body.append('deleted_files', JSON.stringify([[(await getBase64(file)).split(',')[1], file.name, from_name]]))
            body.append('attachment_box_name', attachment_box_type);
            api.post(API_UPLOAD_FILES(project_id), body, config)
            .catch(err => {
                console.error('Remove some files ERROR: ', err);
            })
        }
    }

    const onDeleteFile = (file_name, id) => {
        const body = new FormData();

        body.append('type', type);
        body.append('deleted_files', JSON.stringify([['', file_name, from_name]]))
        api.post(API_UPLOAD_FILES(project_id), body, config)
        .then(res => {
            if (removeFile) {
                removeFile(id);
            }
        })
        .catch(err => {
            notify_error(DELETE_FILE_ERROR(file_name));
            console.error('Remove some files ERROR: ', err);
        })
    }

    const handleSubmit = (files, allFiles) => {
        onSubmit(allFiles);
        removedOnSubmit = true;
        allFiles.forEach(file => file.remove());
        removedOnSubmit = false;
    }

    return (
        <>
            <div className={className + " py-3"}>
                {
                    initialFiles && initialFiles.length > 0 &&
                    <div className="py-3">
                        <h5 className="text-primary">Fichiers déjà ajoutés</h5>
                        {
                            initialFiles.map((el) => (
                                <div className="d-flex justify-content-between align-items-center border-bottom py-1">
                                    <span className="ml-2">{el.realName}</span>
                                    <i className="mdi mdi-delete text-primary" style={{cursor: 'pointer', fontSize: 24}} onClick={() => onDeleteFile(el.realName, el.id)}></i>
                                    {/* <Button
                                        button_title="Télécharger"
                                        bg_color="dark"
                                        outlined
                                    /> */}
                                </div>
                            ))
                        }
                    </div>
                }
                <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    inputContent={() => placeholder}
                    submitButtonContent="Ajouter"
                    inputWithFilesContent="Ajouter d'autres fichiers"
                    styles={{
                        dropzone: {
                            border: 'dashed',
                            borderColor: '#b3b3b3',
                            borderRadius: 0,
                            overflow: 'hidden'
                        },
                        dropzoneActive: {
                            borderColor: '#C3002F'
                        },
                        inputLabel: {
                            paddingLeft: 10,
                            paddingRight: 10,
                            color: '#C3002F',
                            textAlign: 'center',
                            fontFamily: 'Source Sans Pro'
                        },
                        submitButtonContainer: {
                            marginTop: 5,
                            marginBottom: 5,
                        },
                        submitButton: {
                            backgroundColor: '#C3002F',
                            borderRadius: 0,
                            color: '#fff',
                            marginLeft: 'auto',
                            display: (history ? 'none' : 'block'),
                            fontFamily: 'Source Sans Pro'
                        },
                        inputLabelWithFiles: {
                            color: '#fff',
                            backgroundColor: '#1f1e1e',
                            borderRadius: 0,
                            margin: 5,
                            fontFamily: 'Source Sans Pro'
                        },
                        preview: {
                            color: '#C3002F',
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 3 + '%',
                            paddingRight: 3 + '%',
                            fontFamily: 'Source Sans Pro'
                        }
                    }}
                />
            </div>
        </>
    )
};

export default FileInputDropzone;
