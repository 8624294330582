import React from 'react';
import ProductsStructure from './products-structure';
import ProductsDetailsTypes from './products-details-types';

const ProductsBlock = ({ data, type }) => {
    return (
        <ProductsStructure>
            <ProductsDetailsTypes data={data} type={type} />
            <div style={{height: 100}}></div>
        </ProductsStructure>
    );
};

export default ProductsBlock;