import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageHeaderStructure from 'components/header/page-header/page-content-header';
import { PriceListStructure } from 'components/price-list';
import PriceListDatatable from 'components/price-list/price-list-datatable/price-list-datatable';
import LoadingScreen from 'react-loading-screen';
import { getUserRights } from 'utils/utils';
import { PRESCRIBER_ID, NATIONAL_CIC_ID } from 'utils/constants';

const PriceListGeneralPage = () => {
    const [loading, isLoading] = React.useState(true);
    const user_position = useSelector(state => state.user.position);

    setTimeout(() => {
        isLoading(false);
    }, 500);

    return (
        <LoadingScreen
            loading={loading}
            bgColor='#f1f1f1'
            spinnerColor='#C3002F'
            logoSrc='/assets/img/FA_HITACHI_BLA_RGB_Tagline_loading_page.png'
        >
            <PageHeaderStructure>
            </PageHeaderStructure>
            {
                getUserRights(user_position, [PRESCRIBER_ID, NATIONAL_CIC_ID]) ?
                <PriceListStructure>
                    <PriceListDatatable />
                </PriceListStructure>
                :
                <Redirect to="/" />
            }
        </LoadingScreen>
    );
};

export default PriceListGeneralPage;