import React from 'react';
import MySelect from './select';
import { useSelector } from 'react-redux';
import { Button } from '../button';
import { Input } from '../input';
import api, { API_ADD_PRODUCT_TO_PROJECT, API_SET_PRODUCT_AMOUNT, API_REMOVE_PRODUCT_OF_PROJECT, API_GET_PRODUCTS_LIST, API_GET_PRODUCT_DETAILS } from 'utils/api';
import { notify_error } from 'utils/utils';
import { CHANGE_AMOUNT_PRODUCTS_ERROR, SHOW_PRODUCT_DETAILS_ERROR, ALREADY_ADDED_PRODUCT_TO_PROJECT, ADD_NEW_PRODUCT_ERROR, REMOVE_ADDED_PRODUCT_ERROR } from 'utils/notifications';
import useNumerical from 'hooks/use-numerical';


const ProductLine = ({ product, remove_product, onChange, onDetails }) => {
    const [amount, setAmount] = React.useState(product.amount);
    const [remove, setRemove] = React.useState(false);
    const [errored_input, setErroredInput] = React.useState(false);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);
    const [error, onValue] = useNumerical();

    const onRestore = () => {
        setRemove(false);
        setAmount(product.amount);
    }

    const onRemove = () => {
        remove_product(product);
    }

    const onBlurAmount = () => {
        if (amount !== product.amount) {
            const body = {
                product_id: product.id,
                product_group: product.group,
                product_amount: amount
            }

            api.post(API_SET_PRODUCT_AMOUNT(project_id), body, config)
            .then(res => {
                onChange();
            })
            .catch(err => {
                notify_error(CHANGE_AMOUNT_PRODUCTS_ERROR(product.name));
                console.error('Change the amount of products ERROR: ', err);
            })
        }
    }

    const handleInput = (value) => {
        if (onValue(value, 'input')) {
            setErroredInput(true);
            return;
        } else {
            setErroredInput(false);
        }
        setAmount(value);
    }

    const loadProduct = () => {
        if (!product.id) {
            notify_error(SHOW_PRODUCT_DETAILS_ERROR);
            return;
        }
        onDetails(product.id);
    }

    return (
        <li className="border-bottom py-2" key={product.id}>
            {
                remove ?
                <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0">Voulez-vous vraiment supprimer le produit: "{product.name}" ?</p>
                    <div className="d-flex align-items-center">
                        <Button bg_color="primary" button_title="Oui" className="mx-2" bg-color="primary" onClick={onRemove} />
                        <Button bg_color="primary" outlined button_title="Non" bg-color="primary" onClick={onRestore} />
                    </div>
                </div> :
                <div className="row">
                    <div className="col-4 d-flex align-items-center">
                        <p className="m-0 text-underline" style={{cursor: 'pointer'}} onClick={loadProduct}>
                            {product.name}
                        </p>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-start justify-content-md-center">
                        <Input onBlur={onBlurAmount} centered placeholder="Quantité" error={errored_input || error.input} expanded value={amount} onChange={handleInput} />
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-end">
                        <Button outlined button_title={<i className="mdi mdi-delete" style={{fontSize: 18}}></i>} bg_color="dark" onClick={setRemove} />
                    </div>
                </div>
            }
        </li>
    )
}

const ProductSelect = ({ ProductList, type, initialProducts, onProductChange, onShowDetails }) => {
    const [searchList, setSearchList] = React.useState([]);
    const [products, setProducts] = React.useState(initialProducts)
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);

    React.useEffect(() => setProducts(initialProducts), [initialProducts]);

    const addProduct = (product) => {
        const body = {
            product_id: product.value,
            product_group: type
        }

        api.post(API_ADD_PRODUCT_TO_PROJECT(project_id), body, config)
        .then(res => {
            setProducts([
                ...products,
                {
                    id: product.value,
                    name: product.label,
                    amount: 1,
                    group: type
                }
            ])
            onProductChange(true);
        })
        .catch(err => {
            if (err.response && err.response.status === 406) {
                notify_error(ALREADY_ADDED_PRODUCT_TO_PROJECT);
            } else {
                notify_error(ADD_NEW_PRODUCT_ERROR(product.label));
            }
            console.error('Add new product to project ERROR: ', err);
        })
    }

    const onSelectGrpExtProduct = (valueType, actionType) => {
        if (actionType.action === "select-option") {
            if (!products.filter(data => data.name === valueType.label).length) {
                addProduct(valueType);
            }
        }
    }

    const onRemoveProduct = (product) => {
        const body = {
            product_id: product.id,
            product_group: product.group,
        }

        api.post(API_REMOVE_PRODUCT_OF_PROJECT(project_id), body, config)
        .then(res => {
            setProducts([
                ...products.filter(data => data.name !== product.name),
            ]);
            onProductChange(true);
        })
        .catch(err => {
            notify_error(REMOVE_ADDED_PRODUCT_ERROR(product.name));
            console.error('Remove product from project ERROR: ', err);
        })
    }


    const getSearchedCompanies = (filter) => {
        if (filter === '') {
            return;
        }

        var body = {
            product_group: type,
            filter: filter
        }

        api.post(API_GET_PRODUCTS_LIST, body, config)
        .then(res => {
            if (res.data) {
                setSearchList(res.data.map(el => ({label: el.reference, value: el.id})))
            }
        })
        .catch(err => {
            console.error('Get search product list for ' + type + ' ERRROR: ', err);
        })
    }

    const onLoadDetails = (id) => {
        var body = {
            product_id: id,
            product_type: type
        }

        api.post(API_GET_PRODUCT_DETAILS, body, config)
        .then(res => {
            res.data.type = type;
            onShowDetails(res.data);
        })
        .catch(err => {
            notify_error(SHOW_PRODUCT_DETAILS_ERROR);
            console.error('Get product details ERROR: ', err);
        })
    }

    return (
        <>
            <MySelect
                placeholder="Sélectionnez des produits"
                selectElements={searchList}
                onSelect={onSelectGrpExtProduct}
                noOptionsLabel={() => "Aucun produit trouvé"}
                isClearable
                onInputChange={getSearchedCompanies}
            />
            {
                products && products.length > 0 &&
                <ul className="px-2 mt-n3 bg-light" style={{listStyleType: 'none'}}>
                    {
                        products.map((element, i) => (
                            <ProductLine onDetails={onLoadDetails} product={element} key={element.id} remove_product={onRemoveProduct} onChange={() => onProductChange(true)} />
                        ))
                    }
                </ul>
            }
            {/* <Drawer /> */}
        </>
    );
};

export default ProductSelect;