import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { isObjectEqual } from 'utils/utils';

export const selectStyle = {
    control: (styles, { isFocused }) => ({
        ...styles,
        borderRadius: 0,
        boxShadow: '#C3002F',
        borderColor: isFocused ? "#C3002F" : "#d3e0e9",
        ':active': {
            borderColor: '#C3002F',
        },
        ':hover': {
            borderColor: '#C3002F',
        }
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? '#C3002F' : isFocused ? '#00000059' : '#fff',
        ':active': {
            backgroundColor: '#C3002F50'
        }
    }),
    theme: styles => ({
        ...styles,
        borderRadius: 0
    }),
    input: styles => ({...styles, borderRadius: 0}),
    menu: styles => ({...styles, borderRadius: 0})
}

const MySelect = ({ selectElements,
    placeholder,
    label,
    className,
    onSelect,
    noOptionsLabel,
    defaultValue,
    isClearable,
    onInputChange,
    error }) => {
    const allCollections = useSelector(state => state.stepNavigation.collections);
    const [elements, setElements] = React.useState(typeof selectElements === 'string' ? allCollections : selectElements);
    const [defaultVal, setDefaultVal] = React.useState(defaultValue);
    const selectRef = React.useRef();

    React.useEffect(() => {
        if (typeof defaultValue === 'string') {
            var newDefault = elements.filter(el => el.label === defaultValue)[0];
            if (!isObjectEqual(newDefault, defaultVal)) {
                setDefaultVal(newDefault);
            }
        } else {
            setDefaultVal(defaultValue);
        }
        if (typeof selectElements !== 'string' && !isObjectEqual(selectElements, elements)) {
            if (selectRef.current.select.commonProps.getValue().length) {
                selectRef.current.select.clearValue();
            }
            setElements(selectElements);
        }
    }, [defaultValue, elements, defaultVal, selectElements]);

    React.useEffect(() => {
        if (selectElements === 'collections' && allCollections) {
            setElements(allCollections);
        }
    }, [allCollections, setElements, selectElements])

    const onChange = (data, action) => {
        if (action.action !== 'clear') {
            onSelect(data, action);
        }
    }

    return (
        <div className={"form-group " + (className || "")}>
            {
                label &&
                <label htmlFor={`${label}Select`} className={"my-2" + (error ? ' text-primary' : '')}>{label}{error ? ' X' : ''}</label>
            }
            <Select
                ref={ref => {
                    selectRef.current = ref;
                }}
                isClearable={isClearable}
                onChange={onChange}
                id={`${label || ""}Select`}
                options={elements}
                placeholder={placeholder}
                styles={selectStyle}
                noOptionsMessage={noOptionsLabel}
                defaultValue={defaultVal}
                value={defaultVal}
                onInputChange={onInputChange}
                openMenuOnFocus
                clearValue={true}
            />
        </div>
    );
};

export default MySelect;