import React from 'react';

const PageFooter = () => {
    return (
        <div className="w-100" style={{height: 80}}>
            
        </div>
    );
};

export default PageFooter;