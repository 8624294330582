import React from 'react';
import PropTypes from 'prop-types';
import GammeSelect from 'components/form/select/gamme-select';

const DeriveAdder = ({ cancelAction, onGammeAdded }) => {
    return (
        <div className="p-2 position-relative">
            {
                cancelAction &&
                <div className="position-absolute" style={{right: 5, top: 5, cursor: 'pointer'}} onClick={cancelAction}>
                    <i className="mdi mdi-close p-1" style={{fontSize: 18}} />
                </div>
            }
            <GammeSelect onSaved={onGammeAdded} />
        </div>
    );
};

DeriveAdder.propTypes = {
    cancelAction: PropTypes.func,
    onGammeAdded: PropTypes.func.isRequired,
};

export default DeriveAdder;