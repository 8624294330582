import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigateSteps } from 'actions/steps-actions';
import { StepElementActive, StepElementVisited, StepElementUnvisited } from './states';
import { getUserRights } from 'utils/utils';
import { PRESCRIBER_ID } from 'utils/constants';

const ActiveSeparator = () => (<div className="bg-primary" style={{width: 50, height: 2}}></div>)

const UnvisitedSeparator = () => (<div style={{width: 50, height: 2, backgroundColor: '#00000030'}}></div>)

class Stepper extends Component {
    constructor() {
        super();
        this.handleNavigationSteps = this.handleNavigationSteps.bind(this);
    }

    handleNavigationSteps = (view) => {
        this.props.navigateSteps(view);
    }

    render() {
        const Step0 = this.props.step === 0 ? StepElementActive : StepElementVisited;
        const Step1 = this.props.step === 1 ? StepElementActive : (this.props.step > 1 ? StepElementVisited : StepElementUnvisited);
        const Step2 = this.props.step === 2 ? StepElementActive : (this.props.step > 2 ? StepElementVisited : StepElementUnvisited);
        const Step3 = this.props.step === 3 ? StepElementActive : StepElementUnvisited;
        const Separator0 = this.props.step > 0 ? ActiveSeparator : UnvisitedSeparator;
        const Separator1 = this.props.step > 1 ? ActiveSeparator : UnvisitedSeparator;
        const Separator2 = this.props.step === 3 ? ActiveSeparator : UnvisitedSeparator;
        return (
        <div className="steps d-flex flex-row align-items-center">
            {
                getUserRights(this.props.user_position, [PRESCRIBER_ID]) &&
                <>
                    <div onClick={() => this.handleNavigationSteps(0)} style={{cursor: 'pointer'}}>
                        <Step0 tooltip="Informations du projet">
                            0
                        </Step0>
                    </div>
                    <Separator0 />
                </>
            }
            <div onClick={() => this.handleNavigationSteps(1)} style={{cursor: 'pointer'}}>
                <Step1 tooltip="Conception">
                    1
                </Step1>
            </div>
            <Separator1 />
            <div onClick={() => this.handleNavigationSteps(2)} style={{cursor: 'pointer'}}>
                <Step2 tooltip="Consultation">
                    2
                </Step2>
            </div>
            <Separator2 />
            <div onClick={() => this.handleNavigationSteps(3)} style={{cursor: 'pointer'}}>
                <Step3 tooltip="Execution">
                    3
                </Step3>
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    step: state.stepNavigation.active,
    user_position: state.user.position
})

const mapDispatchToProps = dispatch => ({
    navigateSteps: payload => dispatch(navigateSteps(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Stepper);