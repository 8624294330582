import React from 'react';
import ConnectionStructure from '../connection-structure';
import RegisterForm from 'components/connection/register/register-form';

const Register = () => {
    return (
        <ConnectionStructure>
            <RegisterForm />
            <div className="col-xl-8 col-md-4 d-none d-md-block bg-cover bg-primary" style={{backgroundImage: "url('assets/img/background_image_login.jpg')"}}></div>
        </ConnectionStructure>
    );
};

export default Register;