import React from 'react';
import PropTypes from 'prop-types';

const IsolationFormOptionSubtitle = ({ title }) => {
    return (
        <p className="m-0">{title}</p>
    );
};

IsolationFormOptionSubtitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default IsolationFormOptionSubtitle;