import React from 'react';

const HistoryStructure = ({ children }) => {
    const [history_form, history_feed] = children;

    return (
        <>
            <div className="card w-100 mb-2 squared border">
                {history_form}
            </div>
            <div className="card w-100 squared">
                {history_feed}
            </div>
        </>
    );
};

export default HistoryStructure;