import React from 'react';
import Datatable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PriceListDatatableExpand } from './price-list-datatable-expand';
import { Button } from 'components/form/button';
import { Input } from 'components/form/input';
import api, { API_GET_ALL_PRICES_LISTS, API_PROJECT_CONNECTION_LOG } from 'utils/api';
import TitleHeader from 'components/header/title-header';
import DatatablePagination from 'components/form/pagination/datatable-pagination';

const Filter = ({ onFilter }) => (
    <div className="row d-flex align-items-center flex-wrap">
        <p className="col-sm-3 col-4 m-0 p-0 text-center">Rechercher :</p>
        <Input
            id="search"
            type="search"
            role="search"
            placeholder="Rechercher un projet"
            className="col-sm-6 col-8 pr-sm-2"
            onChange={value => onFilter(value)}
        />
        <Button
            className="col-sm-3 mt-2 mt-sm-0"
            bold
            extended
            tooltip={{
                placement: "bottom",
                content:"Exporter en CSV"
            }}
            prefix={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={{width: 1.5 + "rem"}} className="mr-1"><path fill="currentColor" d="M 7 2 L 7 48 L 39.796875 48 L 41.496094 50 L 50 40 L 45 40 L 45 31 L 43 31 L 43 15.599609 L 29.400391 2 L 7 2 z M 30 5.4003906 L 39.585938 14.984375 L 30 14.984375 L 30 5.4003906 z M 18.460938 23 C 19.230937 23 19.771 23.155875 20 23.296875 L 19.650391 24.888672 C 19.370391 24.747672 19.089297 24.644531 18.529297 24.644531 C 17.252297 24.644531 15.941406 25.816156 15.941406 29.035156 C 15.941406 32.201156 17.131828 33.339844 18.548828 33.339844 C 18.985828 33.339844 19.423125 33.232906 19.703125 33.128906 L 19.929688 34.703125 C 19.579688 34.878125 18.967266 35 18.197266 35 C 15.503266 35 13.964844 32.79525 13.964844 29.15625 C 13.964844 24.62625 16.361937 23 18.460938 23 z M 28.451172 23 L 30.535156 23 L 31.53125 28.376953 C 31.76225 29.694953 32.064187 31.279281 32.242188 32.738281 L 32.296875 32.738281 C 32.456875 31.261281 32.722656 29.730797 32.972656 28.341797 L 33.935547 23 L 36 23 L 33.240234 35 L 31.15625 35 L 28.451172 23 z M 25.382812 23.001953 C 26.133813 23.001953 26.818812 23.228875 27.132812 23.421875 L 26.800781 25.029297 C 26.469781 24.819297 25.977656 24.628906 25.347656 24.628906 C 24.280656 24.628906 23.773437 25.3435 23.773438 26.0625 C 23.773438 26.9725 24.245125 27.393109 25.453125 28.162109 C 26.940125 29.141109 27.533203 30.278109 27.533203 31.537109 C 27.533203 33.654109 26.030109 35.001953 23.912109 35.001953 C 23.037109 35.001953 22.145344 34.757719 21.777344 34.511719 L 22.09375 32.902344 C 22.53075 33.164344 23.299109 33.375 23.912109 33.375 C 25.067109 33.375 25.697266 32.709516 25.697266 31.728516 C 25.697266 30.784516 25.121203 30.242172 24.158203 29.576172 C 22.846203 28.737172 21.935547 27.566063 21.935547 26.289062 C 21.935547 24.452063 23.177812 23.001953 25.382812 23.001953 z M 40 33 L 43 33 L 43 42 L 45.599609 42 L 41.496094 47 L 37.400391 42 L 40 42 L 40 33 z"></path></svg>
            }
            bg_color="primary"
            button_title="Exporter"
            onClick={() => alert('Cette fonctionnalité est en cours de développement...')}
        />
    </div>
);

const StepBadge = ({ currentStatus }) => {
    const color = (currentStatus !== "Terminé" ? (currentStatus !== "Perdue" ? "warning" : "danger") : "success");

    return (
        <div className="d-flex align-items-center">
            <span className={"badge datatable-element text-capitalize p-2 text-dark font-weight-bold squared badge-" + color}>
                {currentStatus}
            </span>
        </div>
    )
}

const datatableTheme = {
    expander: {
        expanderColor: '#C3002F'
    }
}

const PriceListDatatable = () => {
    const [data, setData] = React.useState([]);
    const [filterText, setFilterText] = React.useState('');
    const filteredItems = data.filter(item => {
        if (item.number.toString().includes(filterText)
        || item.name.toLowerCase().includes(filterText.toLowerCase())) {
            return true;
        }
        return false;
    });
    const config = useSelector(state => state.apiR);

    const emptyData = "Aucun projet ne vous a été assigné."

    const columnsList = [
        {
            name: "Numéro de projet",
            selector: "number",
            sortable: "true",
            cell: (item) => <Link to={`/projet/${item.id}/etapes`} onClick={() => {
                    api.get(API_PROJECT_CONNECTION_LOG(item.id), config).catch(err => console.error('Add project connection log ERROR: ', err))
            }}>nº {item.number}</Link>
        },
        {
            name: "Nom du projet",
            selector: "name",
            sortable: "true",
            cell: (item) => <Link to={`/projet/${item.id}/etapes`} onClick={() => {
                    api.get(API_PROJECT_CONNECTION_LOG(item.id), config).catch(err => console.error('Add project connection log ERROR: ', err))
            }} className="text-underline">{item.name}</Link>
        },
        {
            name: "Gamme",
            selector: "quote",
            sortable: "false",
            cell: element => <span>{element.quote && element.quote.collection && element.quote.collection.libellus}</span>
        },
        {
            name: "État du projet",
            selector: 'currentStatus',
            sortable: 'true',
            cell: el => <StepBadge {...el} />
        },
        {
            name: "Premier prix concurrent",
            selector: 'competitorPriceOne',
            cell: el => <p>{el.quote.competitorPriceOne}</p>
        },
        {
            name: "Second prix concurrent",
            selector: 'competitorPriceTwo',
            cell: el => <p>{el.quote.competitorPriceTwo}</p>
        },
        {
            name: "Prix fourni posé",
            selector: 'composedPrice',
            cell: el => <p>{el.quote.composedPrice}</p>
        }
    ]

    React.useEffect(() => {
        api.get(API_GET_ALL_PRICES_LISTS, config)
        .then(res => {
            setData(res.data);
        })
        .catch(err => {
            console.error('Get all prices lists ERROR: ', err);
        })
    }, [config])

    return (
        <>
            <TitleHeader title='Grille tarifaire' />
            <Datatable
                noHeader={true}
                defaultSortField="number"
                columns={columnsList}
                data={filteredItems}
                noDataComponent={emptyData}
                highlightOnHover
                customTheme={datatableTheme}
                className="text-primary"
                subHeader
                subHeaderComponent={<Filter onFilter={value => setFilterText(value)} />}
                pagination
                paginationComponent={(data) => <DatatablePagination data={data} />}
                expandableRows
                expandableRowsComponent={<PriceListDatatableExpand />}
            />
        </>
    );
};

export default PriceListDatatable;
