import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'components/form/button';
import { onDownloadFile } from 'utils/utils';
import { EMAIL_REGEX } from 'utils/constants';
var moment = require('moment');

export const HistoryPostFile = ({description, user, data, date, bg_color}) => {
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);

    const onDownload = (name) => {
        onDownloadFile(project_id, name, config);
    }

    if (!user) {
        return null;
    }

    return (
        <div className="timeline-wrapper">
            <div className="">
                <div className="avatar avatar-sm">
                    <div className={"avatar-title rounded-circle bg-" + bg_color}>
                        {user.firstName[0] + user.lastName[0]}
                    </div>
                </div>
            </div>
            <div className="col">
                <h6 className="m-0 text-primary">{user.firstName} {user.lastName}</h6>
                <div className="text-muted">{moment(date).lang('fr').format('MMM D YYYY')}</div>
                <h6 className="p-t-10">{description}</h6>
                <div className="card mb-2">
                    <div className="card-body">
                        <div className="row">
                            {/* {
                                data.map((element, i) => (
                                    <div className="col pr-1">
                                        <img src={element.img} alt={element.alt}/>
                                        <Button
                                            outlined
                                            extended
                                            bg_color="primary"
                                            className="d-none d-md-block my-1"
                                            button_title="Télécharger"
                                        />
                                    </div>
                                ))
                            } */}
                            <div className="col pr-1">
                                <p className="text-center">{data}</p>
                                <Button
                                    outlined
                                    extended
                                    bg_color="primary"
                                    className="d-none d-md-block my-1"
                                    button_title="Télécharger"
                                    onClick={() => onDownload(data)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const HistoryPostText = ({ user, date, description, bg_color }) => {
    if (!user) {
        return null;
    }

    return (
        <div className="timeline-wrapper">
            <div className="">
                <div className="avatar avatar-sm">
                    <div className={"avatar-title rounded-circle bg-" + bg_color}>
                        {user.firstName[0] + user.lastName[0]}
                    </div>
                </div>
            </div>
            <div className="col-auto">
                <h6 className="m-0 text-primary">{user.firstName} {user.lastName}</h6>
                <div className="text-muted">{moment(date).lang('fr').format('MMM D YYYY')}</div>
                <p className="m-0">{description}</p>
            </div>
        </div>
    );
};

export const HistoryPostMail = ({ user, description, date, data, bg_color }) => {
    const [emailParsed, setEmailParsed] = React.useState({});
    const [email, setEmail] = React.useState(data);
    const [onError, setOnError] = React.useState(undefined);

    React.useEffect(() => {
        var email_json = null;
        try {
            email_json = JSON.parse(data);
            setEmail(email_json);
        }
        catch {
            setOnError(<HistoryPostText user={user} date={date} description={<small className="font-italic">-- Une erreur est survenue lors du chargement du mail relié à ce post... --</small>} bg_color={bg_color} />)
            return;
        }
        if (email_json[3].length === 0) {
            setOnError(<HistoryPostText user={user} date={date} description={<small className="font-italic">-- Une erreur est survenue lors du chargement du mail relié à ce post... --</small>} bg_color={bg_color} />)
            return;
        }

        if (email_json && email_json.length < 8) {
            return null;
        }

        setEmailParsed({
            sender: email_json[3].map(el => <span>{el.address}, </span>),
            reciever: email_json[1].filter(el => el.address.match(EMAIL_REGEX)).map(el => <span>{el.address}, </span>),
            object: email_json[4],
            content: email_json[7].slice(0, 500)
        })
    }, [bg_color, data, date, user])

    if (onError) {
        return onError;
    }

    if (!user) {
        return null;
    }

    return (
        <div className="timeline-wrapper">
            <div className="">
                <div className="avatar avatar-sm">
                    <div className={"avatar-title rounded-circle bg-" + bg_color}>
                        {user.firstName[0] + user.lastName[0]}
                    </div>
                </div>
            </div>
            <div className="col-auto" style={{width: 90 + '%'}}>
                <h6 className="m-0 text-primary">{user.firstName} {user.lastName}</h6>
                <div className="text-muted">{date}</div>
                <p>{description}</p>
                <div className="card">
                    <div className="card-body">
                        <p className="m-0 mb-2"><strong>De: </strong><a className="text-underline" href={`mailto:${emailParsed.sender}`} target="_blank" rel="noopener noreferrer">{emailParsed.sender}</a></p>
                        <p className="m-0 mb-2"><strong>À: </strong><a className="text-underline" href={`mailto:${emailParsed.reciever}`} target="_blank" rel="noopener noreferrer">{emailParsed.reciever}</a></p>
                        <p className="m-0 mb-2"><strong>Objet: </strong>{emailParsed.object}</p>
                        <p className="m-0 mb-2"><strong>Contenu: </strong><br />
                        <div dangerouslySetInnerHTML={{__html: emailParsed.content}}></div>
                        {
                            email[7].length > 500 && emailParsed.content.length === 500 &&
                            <span className="text-underline" onClick={() => setEmailParsed({...emailParsed, content: email[7]})} style={{cursor: 'pointer'}}>Afficher plus de données...</span>
                        }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};