import Axios from 'axios';

export default Axios.create({
    baseURL: "https://admin.hitanet.fr/"
})


//CONNECTION

export const API_USER_LOGIN = '/user_login';

export const API_USER_LOGOUT = '/logout';

export const API_EMAIL_LOGIN = '/email_login';

export const API_FORGOT_PASSWORD = '/account/reset/password';

export const API_RESET_PASSWORD = (id, token) => `/account/resetpwd/${id}/${token}`

export const API_RESET_PASSWORD_CHECK_VALIDITY = (email, id, token) => `/account/forget_password/check_validity?email=${email}&id=${id}&token=${token}`

export const API_REGISTER = '/registration';

// CREATION DE PROJET

export const API_AUTOCOMPLETE_ADDRESS = '/api/search/address/info';

export const API_GET_ARCHITECTURE_FIRM_IDS = (page, offset) => `/api/architecture_firm/search/${page}/${offset}`;

export const API_GET_DESIGN_OFFICE = (page, offset) => `/api/design_office/search/${page}/${offset}`;

export const API_GET_MANAGERS_CIC = '/api/project_manager/search';

export const API_SET_MANAGER_CIC = (project) => `/api/project/${project}/project_manager/add`;

export const API_CREATE_ARCHITECTURE_FIRM_ID = '/api/architecture_firm/creation';

export const API_CREATE_DESIGN_OFFICE = '/api/creation/design_office';

export const API_CREATE_PROJECT_MANAGER = '/api/project_manager/create';

export const API_CREATE_PROJECT = '/api/project/create';

// COMMON API's

export const API_SET_PROJECT_TO_FINISH = (project) => `/api/project/${project}/finish`;

export const API_GET_ALL_PROJECTS = '/api/projects';

export const API_GET_PROJECT = (project_id) => `/api/project/${project_id}`;

export const API_GET_COLLECTIONS = '/api/collections';

export const API_GET_USER_DETAILS = '/api/account/profile/info';

export const API_CHANGE_COLLECTION = (project) => `/api/project/${project}/collection/change`;

export const API_DOWNLOAD_FILE = (project) => `/api/project/${project}/attachments/download`;

export const API_PROJECT_CONNECTION_LOG = (project) => `/api/project/${project}/connection/log`;

// PRICE LIST

export const API_GET_PROJECT_PRICE_LIST = (project_id) => `/api/project/${project_id}/quote`;

export const API_ADD_INFO_TO_QUOTE = (project_id) => `/api/project/${project_id}/quote/update`;

export const API_REMOVE_PROJECT_SUB_QUOTE = (project_id) => `/api/project/${project_id}/quote/sub/remove`;

export const API_GET_ALL_PRICES_LISTS = '/api/quotes';

export const API_SAVE_PRICE_CONCURRENCE_COMPOSED = (project) => `/api/project/${project}/quote/info/edit`;

// STEP 0

export const API_GET_PROJECT_INFO_CREATION = (project_id) => `/api/project/${project_id}/creation/info`;

export const API_UPDATE_PROJECT = (project_id) => `/api/project/${project_id}/update/creation/info`;

export const API_DISABLE_PROJECT = '/api/project/disable';

// CONCEPTION STEP

export const API_DOWNLOAD_COLLECTION_FILES = (project) => `/api/project/${project}/attachments/collection`;

export const API_SET_REFERENT_ARCHITECT = (project) => `/api/project/${project}/conception/architect_ref`;

export const API_GET_REFERENT_ARCHITECT = (project) => `/api/project/${project}/architect_ref`

export const API_SET_GET_TECHNICAL_INFO = (project) => `/api/project/${project}/conception/tmp_tech_info`;

export const API_UPDATE_TECHNICAL_INFO = (project) => `/api/project/${project}/conception/tmp_tech_info/update`;

export const API_GET_TEMPERATURES = (project) => `/api/project/${project}/temperature`

export const API_GET_FILES_FROM_API = (project) => `/api/project/${project}/attachment`

export const API_UPLOAD_FILES = (project) => `/api/upload/${project}`;

export const API_CONFIRM_UPLOADED_FILES = (project) => `/api/project/${project}/conception/document_study`;

export const API_SUBMIT_CHANGE_ON_FILE = (project) => `/api/project/${project}/document_study/file/reject`;

export const API_SUBMIT_ALL_CHANGES_FOR_FILES = (project) => `/api/project/${project}/document_study/files/confirm`;

export const API_CONFIRM_STEP_CONCEPTION = (project) => `/api/project/${project}/conception/confirmation`;

// CONSULTATION STEP

export const API_ADD_COMPANY = (project) => `/api/project/${project}/company/add`;

export const API_CREATE_NEUTRAL_COMPANY = `/api/company/new`;

export const API_GET_SEARCHED_COMPANIES = (project) => `/api/project/${project}/companies/neutral/search`;

export const API_GET_ADDED_COMPANIES = (project) => `/api/project/${project}/companies/show`;

export const API_REMOVE_SELECTED_COMPANY = (project) => `/api/project/${project}/companies/company/remove`;

export const API_CONFIRM_ADD_COMPANIES = (project) => `/api/project/${project}/companies/add/confirmation`;

export const API_GET_COMPANIES_AND_PRICES = (project) => `/api/project/${project}/companies/prices`

export const API_SET_COMPANIES_PRICES = (project) => `/api/project/${project}/companies/company/price/set`;

export const API_CONFIRM_STEP_CONSULTATION = (project) => `/api/project/${project}/consultation/confirmation`;

export const API_PRICES_CONFIRMATION = (project) => `/api/project/${project}/companies/company/price/confirm`;

export const API_SET_A_NEW_NEGOCIATION = (project) => `/api/project/${project}/consultation/is_negociated`;

export const API_EXPORT_PROJECT = (project) => `/api/project/${project}/quote/export`;
// EXECUTION STEP

export const API_GET_COMPANIES_STATE = (project) => `/api/project/${project}/companies/states`;

export const API_SET_COMPANY_STATE = (project) => `/api/project/${project}/companies/company/state/set`;

export const API_CONFIRM_COMPANY_STATE = (project) => `/api/project/${project}/companies/states/confirm`;

export const API_GET_PRODUCTS_LIST = '/api/product/group/search';

export const API_ADD_PRODUCT_TO_PROJECT = (project) => `/api/project/${project}/product/add`;

export const API_REMOVE_PRODUCT_OF_PROJECT = (project) => `/api/project/${project}/product/remove`;

export const API_SET_PRODUCT_AMOUNT = (project) => `/api/project/${project}/product/amount/set`;

export const API_GET_PROJECT_PRODUCTS = (project) => `/api/project/${project}/products`;

export const API_SET_COMMISSIONING_DATE = (project) => `/api/project/${project}/commissioning/set`;

export const API_GET_COMMISSIONING_DATE = (project) => `/api/project/${project}/commissioning_date/view`;

export const API_GET_PRODUCT_DETAILS = '/api/products/details';

export const API_CONFIRM_EXECUTION = (project) => `/api/project/${project}/execution/confirm`;

// HISTORIQUE

export const API_GET_HISTORIQUE = (project) => `/api/project/${project}/history`;

export const API_ADD_NEW_COMMENT_HISTORY = (project) => `/api/project/${project}/history/comment`;

// USER PROFILE

export const API_UPDATE_USER_PROFILE = '/api/account/profile/info';
