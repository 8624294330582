import React from 'react';
import Datatable from 'react-data-table-component';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { PRESCRIBER_ID, NATIONAL_CIC_ID } from 'utils/constants';
import { getUserRights } from 'utils/utils';
import { useSelector } from 'react-redux';
import TitleHeader from 'components/header/title-header';
import DatatablePagination from 'components/form/pagination/datatable-pagination';
import api, { API_PROJECT_CONNECTION_LOG } from 'utils/api';

const Filter = ({ onFilter }) => (
    <div className="row d-flex align-items-center">
        <p className="col-md-4 col-6 m-0" style={{whiteSpace: 'nowrap'}}>Rechercher :</p>
        <input
            id="search"
            type="search"
            role="search"
            data-toggle="tooltip"
            data-placement="top"
            title="Rechercher par numéro, nom ou étape du projet"
            placeholder="Rechercher un projet"
            className="form-control col-md-8 col-6"
            onChange={e => onFilter(e.target.value)}
        />
    </div>
);

const StepBadge = ({ currentStatus }) => {
    const color = (currentStatus !== "Terminé" ? (currentStatus !== "Perdue" ? "warning" : "danger") : "success");

    return (
        <div className="d-flex align-items-center">
            <span className={"badge datatable-element text-capitalize p-2 text-dark font-weight-bold squared badge-" + color}>
                {currentStatus}
            </span>
        </div>
    )
}

const onExportProject = (id) => {
    alert('Cette fonctionnalité est en cours de développement...')
}

const TableForm = ({ data }) => {
    const user = useSelector(state => state.user);
    const [filterText, setFilterText] = React.useState('');
    const filteredItems = data.filter(item => {
        if (item.number >= 0 && item.name && item.currentStatus) {
            if (item.number.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.name.toLowerCase().includes(filterText.toLowerCase())
            || item.currentStatus.toLowerCase().includes(filterText.toLowerCase())) {
                return true;
            }
        }
        return false;
    });
    const config = useSelector(state => state.apiR);

    const columnsList = [
        {
            name: "Numéro de projet",
            selector: "number",
            sortable: "true",
            cell: row => <Link to={`/projet/${row.id}/etapes`} onClick={() => {
                api.get(API_PROJECT_CONNECTION_LOG(row.id), config).catch(err => console.error('Add project connection log ERROR: ', err))
            }} className="datatable-element">nº {row.number}</Link>
        },
        {
            name: "Nom du projet",
            selector: "name",
            sortable: "true",
            wrap: true,
            cell: row => <Link to={`/projet/${row.id}/etapes`} onClick={() => {
                api.get(API_PROJECT_CONNECTION_LOG(row.id), config).catch(err => console.error('Add project connection log ERROR: ', err))
            }} className="datatable-element text-underline">{row.name}</Link>
        },
        {
            name: "État du projet",
            selector: "currentStatus",
            sortable: "true",
            cell: row => <StepBadge {...row} />
        },
        {
            name: "Tarif",
            selector: "tarif",
            sortable: "true",
            cell: row => <span className="datatable-element">{row.quote ? row.quote.price : '---'} €</span>
        },
        {
            name: "Historique",
            button: true,
            cell: row => <Link to={`/projet/${row.id}/historique`}><div onClick={() => {
                api.get(API_PROJECT_CONNECTION_LOG(row.id), config).catch(err => console.error('Add project connection log ERROR: ', err))
            }} className="mdi mdi-email text-primary" data-toggle="tooltip" data-placement="bottom" title="Voir historique de la correspondance" style={{fontSize: 24}}></div></Link>
        },
        {
            name: 'Exporter',
            button: true,
            cell: row => (<p className="m-0 text-primary" style={{cursor: 'pointer'}} onClick={() => onExportProject(row.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" style={{width: 1.5 + "rem"}}><path fill="currentColor" d="M 7 2 L 7 48 L 39.796875 48 L 41.496094 50 L 50 40 L 45 40 L 45 31 L 43 31 L 43 15.599609 L 29.400391 2 L 7 2 z M 30 5.4003906 L 39.585938 14.984375 L 30 14.984375 L 30 5.4003906 z M 18.460938 23 C 19.230937 23 19.771 23.155875 20 23.296875 L 19.650391 24.888672 C 19.370391 24.747672 19.089297 24.644531 18.529297 24.644531 C 17.252297 24.644531 15.941406 25.816156 15.941406 29.035156 C 15.941406 32.201156 17.131828 33.339844 18.548828 33.339844 C 18.985828 33.339844 19.423125 33.232906 19.703125 33.128906 L 19.929688 34.703125 C 19.579688 34.878125 18.967266 35 18.197266 35 C 15.503266 35 13.964844 32.79525 13.964844 29.15625 C 13.964844 24.62625 16.361937 23 18.460938 23 z M 28.451172 23 L 30.535156 23 L 31.53125 28.376953 C 31.76225 29.694953 32.064187 31.279281 32.242188 32.738281 L 32.296875 32.738281 C 32.456875 31.261281 32.722656 29.730797 32.972656 28.341797 L 33.935547 23 L 36 23 L 33.240234 35 L 31.15625 35 L 28.451172 23 z M 25.382812 23.001953 C 26.133813 23.001953 26.818812 23.228875 27.132812 23.421875 L 26.800781 25.029297 C 26.469781 24.819297 25.977656 24.628906 25.347656 24.628906 C 24.280656 24.628906 23.773437 25.3435 23.773438 26.0625 C 23.773438 26.9725 24.245125 27.393109 25.453125 28.162109 C 26.940125 29.141109 27.533203 30.278109 27.533203 31.537109 C 27.533203 33.654109 26.030109 35.001953 23.912109 35.001953 C 23.037109 35.001953 22.145344 34.757719 21.777344 34.511719 L 22.09375 32.902344 C 22.53075 33.164344 23.299109 33.375 23.912109 33.375 C 25.067109 33.375 25.697266 32.709516 25.697266 31.728516 C 25.697266 30.784516 25.121203 30.242172 24.158203 29.576172 C 22.846203 28.737172 21.935547 27.566063 21.935547 26.289062 C 21.935547 24.452063 23.177812 23.001953 25.382812 23.001953 z M 40 33 L 43 33 L 43 42 L 45.599609 42 L 41.496094 47 L 37.400391 42 L 40 42 L 40 33 z"></path></svg>
                </p>)
        }
    ]

    const isAdmin = getUserRights(user.position, [PRESCRIBER_ID, NATIONAL_CIC_ID]);
    const columns = columnsList.filter((element, i) => {
        if (isAdmin) {
            return true;
        } else {
            return element.selector !== 'tarif';
        }
    })

    // INFO If the API failed, the message need to change
    const emptyData = "Aucun projet ne vous a été assigné."

    return (
        <>
            <TitleHeader title="Vos projets" />
            <Datatable
                noHeader={true}
                defaultSortField="number"
                columns={columns}
                data={filteredItems}
                noDataComponent={emptyData}
                highlightOnHover
                className="text-primary"
                subHeader
                subHeaderComponent={<Filter onFilter={value => setFilterText(value)} />}
                pagination
                paginationComponent={(data) => <DatatablePagination data={data} />}
            />
        </>
    );
};

export default TableForm;