import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import api, { API_EMAIL_LOGIN } from 'utils/api';
import { loadUser } from 'actions/user-actions';
import { setToken } from 'actions/api-actions';
import Lottie from 'react-lottie';
import * as errorAnim from 'assets/anims/error-anim.json';
import { Button } from 'components/form/button';
import { accountPositionAPI } from 'utils/utils';

const errorOptionsAnim = {
    loop: false,
    autoplay: true,
    animationData: errorAnim.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const EmailLogin = ({ location, history }) => {
    const [state, setState] = React.useState(false);
    const [error, setError] = React.useState(false);
    const dispatch = useDispatch();
    const paramsRef = React.useRef('');

    React.useEffect(() => {
        var params = queryString.parse(location.search);
        paramsRef.current = params

        api.post(API_EMAIL_LOGIN, params)
        .then(res => {
            if (!res.data && !res.data.account && !res.data.token) {
                return;
            }
            dispatch(loadUser({
                position: res.data.account.position,
                ...res.data.account[accountPositionAPI(res.data.account.position)]
            }));
            dispatch(setToken(res.data.token));
            paramsRef.current.token = res.data.token
            setState(true);
        })
        .catch(err => {
            setError(true);
            console.error('Email login ERROR: ', err);
        })
    }, [dispatch, location.search])

    return (
        <>
            {
                !error && state ?
                <Redirect to={"/register?token=" + paramsRef.current.token} />
                :
                <div className="position-absolute" style={{top: 25 + '%', left: 'calc(50% - 60px)'}}>
                    {
                        error ?
                        <>
                            <Lottie options={errorOptionsAnim} width="120" height="120" />
                            <p className="text-center position-absolute mt-2">Vous n'avez plus accès à cette page...</p>
                            <Button onClick={() => history.push('/login')} className="mt-6 position-absolute" style={{width: 200, left: -50}} bg_color="dark" button_title="Vers la page de connection" outlined />
                        </>
                        :
                        <div className="spinner-border text-primary" style={{marginLeft: 5, marginTop: 5, width: 90, height: 90}} role="status">
                            <span className="sr-only">En attente de réponse...</span>
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default withRouter(EmailLogin);