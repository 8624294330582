import React from 'react';

const TitleHeader = ({ title }) => {
    return (
        <div className="d-flex flex-column pl-2">
            <p className="text-primary m-0 mt-n4" style={{fontSize: 36}}>_</p>
            <h4 className="font-weight-bold">{title}</h4>
        </div>
    );
};

export default TitleHeader;