import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Home } from 'pages/home';
import { Login } from 'pages/connection/login';
import { Logout } from 'pages/connection/logout';
import { Register } from 'pages/connection/register';
import { EmailLogin } from 'pages/connection/email-login';
import PriceListPage from 'pages/page-content/price-list-page/price-list-page';
import PrivateRoutes from './private-routes';
import DataLoader from 'components/data-loader';
import { ForgotPassword } from 'pages/connection/forgot-password';
import { ResetPassword } from 'pages/reset-password';

function Router() {
    return (
        <BrowserRouter>
            <DataLoader />
            <Switch>
                <Route path="/login" component={Login} exact />
                <Route path="/register" component={Register} exact />
                <Route path="/email_login" component={EmailLogin} exact />
                <Route path="/email_reset_password" component={ForgotPassword} exact />
                <Route path="/forget_password" component={ResetPassword} exact />
                <PrivateRoutes path="/logout" component={Logout} exact />
                <PrivateRoutes path="/projet/:id/grille_tarifaire" component={PriceListPage} exact />
                <PrivateRoutes path="/" component={Home} />
            </Switch>
        </BrowserRouter>
    )
}

export default Router;