import React from 'react';
import {connect, useSelector} from 'react-redux';
import { Input } from 'components/form/input';
import { Select } from 'components/form/select';
import { Button } from 'components/form/button';
import { isObjectEqual } from 'utils/utils';
import api, { API_ADD_INFO_TO_QUOTE } from 'utils/api';
import useNumerical from 'hooks/use-numerical';
import { reloadPriceProject } from 'actions/feed-actions';

const soldes = [
    {
        label: 'Mono/Multi (%)',
        name: 'mono_multi',
        variable: 'monoMulti',
        value: '',
    },
    {
        label: 'Accessoires (%)',
        name: 'accessories',
        variable: 'accessories',
        value: ''
    },
    {
        label: 'Petit tertiaire (%)',
        name: 'small_tertiary',
        variable: 'smallTertiary',
        value: '',
    },
    {
        label: 'DRV (%)',
        name: 'drv',
        variable: 'drv',
        value: ''
    }
]

const PriceListRow = ({ data, className, type, disabled, currentStepUniq, reloadPriceProject, reloadProjectPrice }) => {
    const [currentContent, setCurrentContent] = React.useState({
        ...data,
        collection: {
            ...data.collection
        },
        soldes: soldes.map(el => {
            return {
                ...el,
                value: data[el.variable]
            };
        })
    });
    const [modifiedData, setModifiedData] = React.useState({
        ...data,
        collection: {
            ...data.collection
        },
        soldes: soldes.map(el => {
            return {
                ...el,
                value: data[el.variable]
            };
        })
    })
    const [isModified, setIsModified] = React.useState(false);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);
    const [error, setError] = React.useState({});
    const [errored, onValue] = useNumerical();
    const [errorMessage, setErrorMessage] = React.useState();

    React.useEffect(() => {
        if (!(isObjectEqual(modifiedData, currentContent))) {
            setIsModified(true);
        } else {
            setIsModified(false);
        }

        for (var i = 0; i < modifiedData.soldes.length; i++) {
            if (!(isObjectEqual(modifiedData.soldes[i], currentContent.soldes[i]))) {
                setIsModified(true);
            }
        }
    }, [modifiedData, currentContent])

    function onSave() {
        if (!disabled) {
            if (currentStepUniq === 'execution_confirmation') {
                // dispatch action to reload price on project
                reloadPriceProject(true);
            }
            const body = {
                type: type,
                price: modifiedData.price,
                collection_id: modifiedData.collection.id,
            }
            modifiedData.soldes.map(el => (body[el.name] = el.value))

            onValue();

            api.put(API_ADD_INFO_TO_QUOTE(project_id), body, config)
              .then(res => {
                  setIsModified(false);
                  setModifiedData({
                      ...modifiedData,
                      ...res.data[0],
                      collection: {
                          ...res.data[0].collection
                      },
                      soldes: soldes.map(el => {
                          return {
                              ...el,
                              value: res.data[0][el.variable]
                          };
                      })
                  });
                  setCurrentContent({
                      ...currentContent,
                      ...res.data[0],
                      collection: {
                          ...res.data[0].collection
                      },
                      soldes: soldes.map(el => {
                          return {
                              ...el,
                              value: res.data[0][el.variable]
                          };
                      })
                  });
              })
              .catch(err => {
                  if (err.response && err.response.status === 400) {
                      setErrorMessage('Vous devez entrer au moins une des quatre remises...');
                      console.error('Update project quote ERROR: ', err);
                      return;
                  }
                  setErrorMessage('Une erreur est survenue lors de la sauvegarde des prix...');
                  console.error('Update project quote ERROR: ', err);
              })
        }
    }

    function handleChange(value) {
        if (!disabled) {
            if (onValue(value, 'price')) {
                setError({price: true});
                return;
            } else if (error.price) {
                setErrorMessage(undefined);
                setError({price: false});
            }

            setModifiedData({
                ...modifiedData,
                price: value
            })
        }
    }

    function handleChangeSoldes(name, value) {
        if (!disabled) {
            if (!modifiedData.soldes) {
                return;
            }

            if (onValue(value, name)) {
                setError({[name]: true});
                return;
            }

            if (error[name]) {
                setErrorMessage(undefined);
                setError({[name]: false});
            }

            setModifiedData({
                ...modifiedData,
                soldes: modifiedData.soldes.map((element) => {
                    if (element.name !== name) {
                        return element;
                    }
                    return {
                        ...element,
                        value: value
                    };
                })
            })
        }
    }

    function handleSelect(value) {
        if (!disabled) {
            setModifiedData({
                ...modifiedData,
                collection: { libellus: value.label, id: value.value }
            })
        }
    }

    return (
        <>
            <div className={"row " + className}>
                {
                    errorMessage &&
                    <div className="col-12 px-md-2">
                        <p className="text-primary">{errorMessage}</p>
                    </div>
                }
                <div className="col-12 px-md-2">
                    <small className="font-weight-bold">Gamme</small>
                    <Select
                        placeholder="Gamme"
                        selectElements='collections'
                        defaultValue={{
                            label: modifiedData.collection.libellus,
                            value: modifiedData.collection.id
                        }}
                        noOptionsLabel={(value) => `Aucune gamme sous le nom de "${value.inputValue}"`}
                        onSelect={handleSelect}
                    />
                </div>
                <div className="col-12 px-md-2">
                    <small className="font-weight-bold">Prix (€)</small>
                    <Input error={error.price || errored.price} expanded value={modifiedData.price} placeholder="Prix" centered onChange={handleChange} />
                </div>
                <div className="row mt-2">
                    {
                        modifiedData.soldes.map((element, i) => (
                            <div className="col-6">
                                <d><small className="font-weight-bold">{element.label}</small></d>
                                <Input
                                    error={error[element.name] || errored[element.name]}
                                    placeholder="Remise (%)"
                                    expanded
                                    value={element.value}
                                    onChange={(value) => handleChangeSoldes(element.name, value)}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                isModified &&
                <Button
                    style={{bottom: 0, left: 0}}
                    className="mt-3"
                    bg_color="primary"
                    extended
                    button_title="Enregistrer"
                    prefix={
                        <i className="mdi mdi-content-save mr-2" style={{fontSize: 20}}></i>
                    }
                    onClick={onSave}
                />
            }
        </>
    );
};

const mapStateToProps = state => ({
    reloadProjectPrice: state.feedR.reloadProjectPrice,
})

const mapDispatchToProps = dispatch => ({
    reloadPriceProject: payload => dispatch(reloadPriceProject(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceListRow);
