import React from 'react';
import { useSelector } from 'react-redux';
import StepsStructure from '../steps-structure';
import StepExecution1 from './step-execution-1';
import StepExecution2 from './step-execution-2';
import { PRESCRIBER_ID, NATIONAL_CIC_ID, NAME_STEP_EXECUTION_1, NAME_STEP_EXECUTION_2, NAME_STEP_EXECUTION_3 } from 'utils/constants';
import StepExecution3 from './step-execution-3';
var Scroll = require('react-scroll');
var scroller = Scroll.scroller;

const StepsExecution = ({ active_step }) => {
    const [onScroll, setOnScroll] = React.useState(false);
    const [MESHitachi, setMESHitachi] = React.useState(true);
    const project_collection = useSelector(state => state.stepNavigation.collection);

    if (!onScroll && active_step) {
        setOnScroll(true);
        setTimeout(() => {
            scroller.scrollTo(active_step, {
                duration: 300,
                delay: 100,
                smooth: true,
                offset: -75,
            })
        }, 500)
    }

    return (
        <StepsStructure>
            <StepExecution1 name={NAME_STEP_EXECUTION_1} ReadRights={[NATIONAL_CIC_ID]} WriteRights={[PRESCRIBER_ID]} />
            <StepExecution2 onIsMESHitachi={setMESHitachi} name={NAME_STEP_EXECUTION_2} ReadRights={[NATIONAL_CIC_ID]} WriteRights={[PRESCRIBER_ID]} gamme_finale={project_collection} />
            {
                MESHitachi &&
                <StepExecution3 name={NAME_STEP_EXECUTION_3} ReadRights={[NATIONAL_CIC_ID]} WriteRights={[PRESCRIBER_ID]} />
            }
        </StepsStructure>
    );
};

export default StepsExecution;