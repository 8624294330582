import React, { Component } from 'react';
import { HistoryPostText, HistoryPostFile, HistoryPostMail } from 'components/history/history-posts-types/history-post-types';
import api, { API_GET_HISTORIQUE } from 'utils/api';
import { connect } from 'react-redux';
import { accountPositionAPI } from 'utils/utils';
import { loadFeedPosts } from 'actions/feed-actions';
import FadeIn from 'react-fade-in';

const FeedIdentificator = ({data}) => {
    let FeedPost = () => (<div>Undefined Post !!</div>);
    if (!data || !data.account) {
        return null;
    }

    const accountPosition = accountPositionAPI(data.account.position);

    if (!data.account[accountPosition]) {
        return null;
    }

    if (data.logType.libellus === "file_upload") {
        FeedPost = () => <HistoryPostFile {...data} user={data.account[accountPosition]} bg_color="dark"/>;
    } else if (data.logType.libellus === "email_upload") {
        FeedPost = () => <HistoryPostMail {...data} user={data.account[accountPosition]} bg_color="dark"/>;
    } else {
        FeedPost = () => <HistoryPostText {...data} user={data.account[accountPosition]} bg_color="dark"/>;
    }

    return (
        <div className="timeline-item">
            <FeedPost />
        </div>
    );
}

class HistoryFeedContent extends Component {
    constructor(props) {
        super();
        this.state = {
            update: false,
            project_id: props.project_id,
            feed: props.feed,
            loading: true,
        }
    }

    componentWillMount() {
        if (this.props.project_id && (this.state.feed && this.state.feed.length === 0)) {
            api.get(API_GET_HISTORIQUE(this.props.project_id), this.props.config)
            .then(res => {
                this.setState(({project_id: this.props.project_id, feed: res.data, loading: false}))
            })
            .catch(err => {
                console.error('Get history values for ' + this.props.project_id + ' ERROR: ', err)
            })
        }
    }

    componentWillReceiveProps() {
        api.get(API_GET_HISTORIQUE(this.props.project_id), this.props.config)
        .then(res => {
            this.setState(({project_id: this.props.project_id, feed: res.data, loading: false}))
        })
        .catch(err => {
            console.error('Get history values for ' + this.props.project_id + ' ERROR: ', err)
        })
    }

    render() {
        const { feed, loading } = this.state;

        return (
            <>
            {
                !loading ?
                <div className="timeline" style={{maxWidth: 400}}>
                {
                    feed.length > 0 &&
                    <FadeIn delay="20">
                    {
                        feed.splice(0).reverse().map((element, i) => <FeedIdentificator data={element} key={i} />)
                    }
                    </FadeIn>
                }
                </div>
                :
                <div class="spinner-border text-primary m-auto" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            }
            </>
        );
    }
}

const mapStateToProps = state => ({
    project_id: state.stepNavigation.project_id,
    feed: state.feedR.feed_posts,
    config: state.apiR,
})

const mapDispatchToProps = dispatch => ({
    loadFeedPosts: payload => dispatch(loadFeedPosts(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryFeedContent);