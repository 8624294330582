export const loadFeedPosts = (payload) => ({
    type: 'loadPostsFeed',
    payload
})

export const addFeedPosts = (payload) => ({
    type: 'addPostFeed',
    payload
})

export const reloadPriceProject = (payload) => ({
    type: 'reloadPriceProject',
    payload
});
