import React from 'react';
import HistoryStructure from './history-structure';
import { HistoryForm } from './history-form';
import { HistoryFeed } from './history-feed';

const HistoryBlock = ({ finished }) => {
    return (
        <HistoryStructure>
            {
                (finished !== 'Perdue' && finished !== 'Terminé') &&
                <HistoryForm />
            }
            <HistoryFeed />
        </HistoryStructure>
    );
};

export default HistoryBlock;