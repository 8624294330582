import React from 'react';
import PropTypes from 'prop-types';

const IsolationFormOptionCheckbox = ({ title, checkboxVal, onChange, disabled }) => {
    const [isChecked, setChecked] = React.useState(checkboxVal);
    const random_identifier = '_' + Math.random().toString(36).slice(2);

    React.useEffect(() => {
        setChecked(checkboxVal);
    }, [checkboxVal])

    const handleChange = () => {
        if (!disabled) {
            if (onChange) {
                onChange(!isChecked);
            }

            setChecked(!isChecked);
        }
    };

    return (
        <div className="custom-control custom-checkbox">
            <input type="checkbox" checked={isChecked} className="custom-control-input squared" id={"customCheck" + title.replace(' ', '_') + random_identifier} onClick={handleChange} />
            <label className="custom-control-label" for={"customCheck" + title.replace(' ', '_') + random_identifier}>{title}</label>
        </div>
    );
};

IsolationFormOptionCheckbox.propTypes = {
    title: PropTypes.string.isRequired,
    checkboxVal: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default IsolationFormOptionCheckbox;
