import React from 'react';
import { Input, TextArea } from 'components/form/input';
import { Select } from 'components/form/select';
import useAutocompleteDate from 'hooks/use-autocomplete-date';
import useNumerical from 'hooks/use-numerical';

const ProjectCreateFormsProject = ({ onSave }) => {
    const [projectData, setProjectData] = React.useState({});
    const [cities, departments, handleAddressCompletion, filterDepartments] = useAutocompleteDate();
    const [error, setError] = React.useState({});
    const [errored, onValue] = useNumerical();

    const handleChange = (value, name) => {
        if (name === 'project_number' && onValue(value, name)) {
            setError({...error, [name]: true});
            return;
        }

        if (error[name]) {
            setError({...error, [name]: false});
        }

        if (name === 'zip_code') {
            handleAddressCompletion('');
            setProjectData({
                ...projectData,
                [name]: value,
                city: undefined,
                project_department: undefined,
            })
            onSave({
                city: undefined,
                project_department: undefined,
                [name]: value
            })
            return;
        }

        setProjectData({
            ...projectData,
            [name]: value
        })
        onSave({
            [name]: value
        });
    }

    const handleSelect = (value, name) => {
        if (name === 'city') {
            if (projectData.zip_code.length !== 5) {
                setProjectData({
                    ...projectData,
                    zip_code: value.zipCode && value.zipCode.slice(0,5),
                    [name]: value.label
                })
                onSave({
                    zip_code: value.zipCode && value.zipCode.slice(0,5),
                    [name]: value.label
                })
            } else {
                setProjectData({
                    ...projectData,
                    [name]: value.label
                });
                onSave({[name]: value.label});
            }
            filterDepartments(value.value);
        } else {
            setProjectData({
                ...projectData,
                [name]: value.label
            })
            onSave({
                [name]: value.label
            })
        }
    }

    return (
        <>
            <h4 className="text-primary m-0">Projet</h4>
            <div className="row px-2">
                <Input error={error.project_number || errored.project_number} onBlur={() => {
                    if (projectData.project_number && projectData.project_number.match(/^\d+(\.\d+)*$/)) {
                        setError({...error, project_number: false});
                    }
                }} value={projectData.project_number || ""} onChange={(value) => handleChange(value, 'project_number')} placeholder="Numéro de projet" label="Numéro de projet" className="col-12 col-md-3" />
                <Input value={projectData.project_name || ""} onChange={(value) => handleChange(value, 'project_name')} placeholder="Nom du projet" label="Nom du projet" className="col-12 col-md-9" />
                <TextArea value={projectData.project_address || ""} onChange={(value) => handleChange(value, 'project_address')} inputSimulate={true} placeholder="Adresse du projet" label="Adresse du projet" className="m-0 col-12 col-sm-3" />
                <Input onBlur={() => handleAddressCompletion(projectData.zip_code)} value={projectData.zip_code || ""} onChange={(value) => handleChange(value, 'zip_code')} placeholder="Code postal" label="Code postal" className="col-12 col-sm-3" />
                <Select
                    placeholder="Ville"
                    label="Ville"
                    selectElements={cities}
                    defaultValue={cities.filter((el) => el.label === projectData.city)[0]}
                    className="col-12 col-sm-3"
                    onSelect={(value) => handleSelect(value, 'city')}
                    noOptionsLabel={() => 'Entrez un code postal pour choisir la ville'}
                />
                <Select
                    placeholder="Département"
                    label="Département"
                    selectElements={departments}
                    className="col-12 col-sm-3"
                    onSelect={(value) => handleSelect(value, 'project_department')}
                    noOptionsLabel={() => 'Entrez un code postal pour choisir le département'}
                />
            </div>
        </>
    );
};

export default ProjectCreateFormsProject;