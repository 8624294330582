import React from 'react';
import CreatableUserSelectForm from 'pages/page-content/projects-page/creatable-user';
import { useSelector } from 'react-redux';
import api, { API_GET_ARCHITECTURE_FIRM_IDS, API_GET_DESIGN_OFFICE, API_CREATE_ARCHITECTURE_FIRM_ID, API_CREATE_DESIGN_OFFICE, API_GET_MANAGERS_CIC, API_CREATE_PROJECT_MANAGER } from 'utils/api';
import { notify_error } from 'utils/utils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { USER_EMAIL_ALREADY_IN_USE, ON_CREATE_USER_ERROR } from 'utils/notifications';
import MySwitch from 'components/form/button/switch';

const ARCHITECT_ID = 'architecture_firm'
const DESIGN_OFFICE_ID = 'design_office'
const MANAGER_ID = 'project_manager'

let oldInput = null;

const UserSelectors = ({ onSaveSelect }) => {
    const config = useSelector(state => state.apiR);
    const [selectArchitects, setSelectArchitects] = React.useState(undefined);
    const [selectDesignOffice, setSelectDesignOffice] = React.useState(undefined);
    const [selectManager, setSelectManager] = React.useState(undefined);
    const [selectedUser, setSelectedUser] = React.useState({
        [ARCHITECT_ID]: undefined,
        [DESIGN_OFFICE_ID]: undefined,
        [MANAGER_ID]: undefined,
    })
    const [isOnlyManager, setOnlyManager] = React.useState(false);

    const UserDetails = ({ option }) => (
        <div className="px-3 bg-light">
            <h5 className="text-primary">Vous avez sélectionné</h5>
            <div className="row px-3">
                <p className="col-12 col-md-6"><span className="text-underline">Nom:</span> {selectedUser[option].lastName}</p>
                <p className="col-12 col-md-6"><span className="text-underline">Prénom:</span> {selectedUser[option].firstName}</p>
                <p className="col-12 col-md-6"><span className="text-underline">Numéro de téléphone:</span> {selectedUser[option].phone || selectedUser[option].phoneNumber}</p>
                <p className="col-12 col-md-6"><span className="text-underline">Adresse mail:</span> {selectedUser[option].email}</p>
                {
                    option !== MANAGER_ID &&
                    <>
                        <p className="col-12 col-md-6"><span className="text-underline">Siret:</span> {selectedUser[option].siret}</p>
                        <p className="col-12 col-md-6"><span className="text-underline">Raison sociale:</span> {selectedUser[option].socialReason}</p>
                    </>
                }
            </div>
        </div>
    )

    const onCreate = (values, option, cb) => {
        const API_URL = (option === ARCHITECT_ID ? API_CREATE_ARCHITECTURE_FIRM_ID : (option === MANAGER_ID ? API_CREATE_PROJECT_MANAGER : API_CREATE_DESIGN_OFFICE))

        api.post(API_URL, values, config)
        .then(res => {
            onSaveSelect({ value: res.data.id }, option + '_id');
            setSelectedUser({
                ...selectedUser,
                [option]: {
                    ...values,
                    firstName: values.first_name,
                    lastName: values.last_name,
                    phone: values.phone,
                    socialReason: values.social_reason,
                    id: res.data.id,
                }
            })
            cb(true);
        })
        .catch(err => {
            if (err.response && err.response.status === 406) {
                notify_error(USER_EMAIL_ALREADY_IN_USE);
                console.error(`Create ${option} user ERROR: `, err);
                return;
            }
            notify_error(ON_CREATE_USER_ERROR);
            console.error(`Create ${option} user ERROR: `, err);
        })

        const API_URL_SEARCH = (option === ARCHITECT_ID ? API_GET_ARCHITECTURE_FIRM_IDS(1, 0) : option === MANAGER_ID ? API_GET_MANAGERS_CIC : API_GET_DESIGN_OFFICE(1, 0))

        api.post(API_URL_SEARCH, { filter: '' }, config)
            .then(res => {
                if (option === ARCHITECT_ID) {
                    setSelectArchitects(res.data.ArchitectureFirms);
                } else if (option === DESIGN_OFFICE_ID) {
                    setSelectDesignOffice(res.data.designer_office);
                } else if (option === MANAGER_ID) {
                    setSelectManager(res.data);
                }
            })
            .catch(err => {
                console.error(`Get ${option} ERROR: `, err);
            })
    }

    const handleInputChange = (value, option) => {
        const API_URL = (option === ARCHITECT_ID ? API_GET_ARCHITECTURE_FIRM_IDS(1, 0) : option === MANAGER_ID ? API_GET_MANAGERS_CIC : API_GET_DESIGN_OFFICE(1, 0))

        if (value !== oldInput && value !== '') {
            oldInput = value;
            api.post(API_URL, { filter: value }, config)
            .then(res => {
                if (option === ARCHITECT_ID) {
                    setSelectArchitects(res.data.ArchitectureFirms);
                } else if (option === DESIGN_OFFICE_ID) {
                    setSelectDesignOffice(res.data.designer_office);
                } else if (option === MANAGER_ID) {
                    setSelectManager(res.data);
                }
            })
            .catch(err => {
                console.error(`Get ${option} ERROR: `, err);
            })
        }
    }

    const onSelected = (value, name) => {
        const option = (name === 'architecte' ? ARCHITECT_ID : (name === 'chargé de projet CIC' ? MANAGER_ID : DESIGN_OFFICE_ID))

        onSaveSelect(value, option + '_id')

        if (!selectArchitects || !selectManager || !selectDesignOffice) {
            return;
        }

        const getSelectedUser = name === 'architecte' ? selectArchitects.filter(el => el.id === value.value) : (option === MANAGER_ID ? selectManager.filter(el => el.id === value.value) : selectDesignOffice.filter(el => el.id === value.value));

        setSelectedUser({
            ...selectedUser,
            [option]: getSelectedUser[0],
        })
    }

    React.useEffect(() => {
        const handleInputChange_useE = (value, option) => {
            const API_URL = (option === ARCHITECT_ID ? API_GET_ARCHITECTURE_FIRM_IDS(1, 0) : option === MANAGER_ID ? API_GET_MANAGERS_CIC : API_GET_DESIGN_OFFICE(1, 0))

            api.post(API_URL, { filter: value }, config)
            .then(res => {
                if (option === ARCHITECT_ID) {
                    setSelectArchitects(res.data.ArchitectureFirms);
                } else if (option === DESIGN_OFFICE_ID) {
                    setSelectDesignOffice(res.data.designer_office);
                } else if (option === MANAGER_ID) {
                    setSelectManager(res.data)
                }
            })
            .catch(err => {
                console.error(`Get ${option} ERROR: `, err);
            })
        }

        handleInputChange_useE("", ARCHITECT_ID);
        handleInputChange_useE("", DESIGN_OFFICE_ID);
        handleInputChange_useE("", MANAGER_ID);
    }, [config])

    const onOnlyManager = () => {
        onSaveSelect(1, 'project_manager_state');
        setOnlyManager(true);
    }

    return (
        <>
            <CreatableUserSelectForm
                username="chargé de projet CIC"
                data={selectManager && selectManager.map(el => ({
                    label: `${el.firstName} ${el.lastName}`,
                    value: el.id
                }))}
                create_new_title="Compléter les données du chargé de projet CIC"
                onInputChange={(value) => handleInputChange(value, MANAGER_ID)}
                onCreate={(values, cb) => onCreate(values, MANAGER_ID, cb)}
                type={MANAGER_ID}
                onSelect={onSelected}
                noOptionsMsg='Aucun chargé de projet trouvé'
                onClear={() => {setSelectedUser({...selectedUser, [MANAGER_ID]: undefined}); setOnlyManager(false); onSaveSelect(0, 'project_manager_state')}}
            />
            {
                selectedUser[MANAGER_ID] &&
                <UserDetails option={MANAGER_ID} />
            }
            <div className="d-flex align-items-center">
                <p className="m-0">Est-il la maîtrise d'oeuvre ?</p>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="ml-3">Non</span>
                    <MySwitch onChange={(value) => {
                        if (value) {
                            confirmAlert({
                                title: 'Êtes-vous sûr qu\'il sera maître d\'oeuvre ?',
                                message: 'Si oui, aucun cabinet architecte pourra être renseigné et le chargé de projet obtiendra tous les accès du cabinet d\'architecte dans le projet.',
                                buttons: [
                                    {
                                        label: 'Oui',
                                        onClick: () => onOnlyManager()
                                    },
                                    {
                                        label: 'Annuler',
                                    }
                                ]
                            })
                        } else {
                            onSaveSelect(0, 'project_manager_state');
                            setOnlyManager(false);
                            setSelectedUser({...selectedUser, [MANAGER_ID]: undefined});
                        }
                    }} />
                    <span>Oui</span>
                </div>
            </div>
            <CreatableUserSelectForm
                username="architecte"
                data={selectArchitects && selectArchitects.map(el => ({
                    label: `${el.siret} - ${el.firstName} ${el.lastName}`,
                    value: el.id
                }))}
                create_new_title="Compléter les données de l'architecte"
                onInputChange={(value) => handleInputChange(value, ARCHITECT_ID)}
                onCreate={(values, cb) => onCreate(values, ARCHITECT_ID, cb)}
                onSelect={onSelected}
                noOptionsMsg='Aucun architecte trouvé'
                onClear={() => setSelectedUser({...selectedUser, [ARCHITECT_ID]: undefined})}
                block={isOnlyManager}
            />
            {
                !isOnlyManager && selectedUser[ARCHITECT_ID] &&
                <UserDetails option={ARCHITECT_ID} />
            }
            <CreatableUserSelectForm
                label="Bureau d'Études (optionnel) :"
                username="bureau d'études"
                data={selectDesignOffice && selectDesignOffice.map(el => ({
                    label: `${el.siret} - ${el.firstName} ${el.lastName}`,
                    value: el.id
                }))}
                create_new_title="Compléter les données du bureau d'études"
                onInputChange={(value) => handleInputChange(value, DESIGN_OFFICE_ID)}
                onCreate={(values, cb) => onCreate(values, DESIGN_OFFICE_ID, cb)}
                onSelect={onSelected}
                noOptionsMsg="Aucun bureau d\'études trouvé"
                onClear={() => setSelectedUser({...selectedUser, [DESIGN_OFFICE_ID]: undefined})}
            />
            {
                selectedUser[DESIGN_OFFICE_ID] &&
                <UserDetails option={DESIGN_OFFICE_ID} />
            }
        </>
    )
}

const ProjectCreateFormsContact = ({ onSave }) => {
    const [contacts, setContacts] = React.useState({})

    const onSaveSelectedUser = (value, name) => {
        if (name === 'project_manager_state') {
            onSave({[name]: value});
        } else {
            setContacts({
                ...contacts,
                [name]: value.value
            })
            onSave({
                [name]: value.value
            });
        }
    }

    return (
        <>
            <h4 className="text-primary">Contacts</h4>
            <div className="px-2">
                <UserSelectors onSaveSelect={onSaveSelectedUser} />
            </div>
        </>
    );
};

export default ProjectCreateFormsContact;