import React from 'react';

const numericalRegex = /^[-+]?[0-9]*\.?[0-9]*$/;

const useNumerical = () => {
    const [error, setError] = React.useState(false);

    const onValue = (value, name) => {
        if (!value && !name) {
            setError({});
            return false;
        }
        if (!value) {
            return false;
        }
        if (value === '') {
            setError({...error, [name]: false});
            return false;
        }
        if (value[value.length - 1] === '.' && !value.match(/^[-+]?[0-9]+\.?$/)) {
            setError({...error, [name]: true});
            return true;
        }
        if (!value.match(numericalRegex)) {
            setError({...error, [name]: true});
            return true;
        } else {
            setError({...error, [name]: false});
            return false;
        }
    }

    return [error, onValue];
};

export default useNumerical;