export const loadUser = (payload) => ({
    type: 'loadUser',
    payload
})

export const removeUser = () => ({
    type: 'removeUser'
})

export const onlyProjectManager = (payload) => ({
    type: 'isOnlyHimOnProject',
    payload
})