import React from 'react';
import PropTypes from 'prop-types';
import IsolationFormFields from './isolation-form-fields';

const IsolationFormCategories = ({ categories, onChange, disabled }) => {
    const [category, setCategory] = React.useState(categories);

    React.useEffect(() => setCategory(categories), [categories]);

    const handleChange = (value, current_id) => {
        if (!disabled) {
            const newCategory = {
                ...category,
                fields: category.fields.map(el => {
                    if (el.id === current_id) {
                        return value;
                    }
                    return el;
                })
            }

            if (onChange) {
                onChange(newCategory);
            }

            setCategory(newCategory);
        }
    }

    return (
        <>
            <h3>{category.title}</h3>
            <div className="d-flex justify-content-between align-items-start">
                {
                    category.fields &&
                    category.fields.map((el, i) => <IsolationFormFields key={el.id} fields={el} onChange={(value) => handleChange(value, el.id)} disabled={disabled} />)
                }
            </div>
        </>
    );
};

IsolationFormCategories.propTypes = {
    categories: PropTypes.shape({
        title: PropTypes.string,
        fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    onChange: PropTypes.func.isRequired,
};

export default IsolationFormCategories;
