import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, TextArea } from 'components/form/input';
import api, { API_GET_PROJECT_INFO_CREATION, API_DISABLE_PROJECT, API_UPDATE_PROJECT, API_AUTOCOMPLETE_ADDRESS } from 'utils/api';
import { Datepicker } from 'components/form/datepicker';
import MySelect from 'components/form/select/select';
import { Button } from 'components/form/button';
import { notify, notify_error } from 'utils/utils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Select } from 'components/form/select';
import { ON_SAVE_MODIFICATION_STEP0_ERROR, ON_SAVE_STEP0_INVALID_DATES, ON_SAVE_STEP0_SUCCESS, ON_SAVE_STEP0_ERROR, UNAUTHORIZED_TO_DISABLE_PROJECT, ON_DISABLED_PROJECT_FAIL, ON_DISABLED_PROJECT_SUCCESS, UNABLE_TO_REMOVE_PROJECT } from 'utils/notifications';
import useAutocompleteDate from 'hooks/use-autocomplete-date';

const Step01 = ({ history }) => {
    const [projectData, setProjectData] = React.useState('');
    const [modifiedProjectData, setModifiedProjectData] = React.useState();
    const [address, setAddress] = React.useState({
        cities: [],
        departments: [],
    });
    const [error, setError] = React.useState({});
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const active_step = useSelector(state => state.stepNavigation.active);
    const config = useSelector(state => state.apiR);
    const [cities, departments, handleAddressCompletion, filterDepartments] = useAutocompleteDate();

    React.useEffect(() => {
        setAddress({
            cities: cities,
            departments: departments,
        })
    }, [cities, departments])

    React.useEffect(() => {
        api.get(API_GET_PROJECT_INFO_CREATION(project_id), config)
        .then(res => {
            setProjectData({
                ...res.data,
                start_date_consultation: {
                    date: new Date(res.data.startDate),
                    formatted: '',
                },
                end_date_consultation: {
                    date: new Date(res.data.endDate),
                    formatted: '',
                },
                signature_date: {
                    date: new Date(res.data.signatureDate),
                    formatted: '',
                },
                cic: res.data.projectManager,
                architect: res.data.architectureFirm,
                design_office: res.data.designOffice,
            });
            setModifiedProjectData({
                department: res.data.departement,
                zip_code: res.data.zipCode,
                number: res.data.number,
                name: res.data.name,
                id: res.data.id,
                address: res.data.address,
                city: res.data.city,
                collection: res.data.collection && res.data.collection.libellus,
            })
            api.post(API_AUTOCOMPLETE_ADDRESS, {filter: res.data.zipCode}, config)
            .then(res => {
                setAddress({
                    cities: [ ...res.data.map(el => ({ label: el.name, value: el.id })).slice(0,30) ],
                    departments: [ ...res.data.map(el => ({ label: el.departmentRegion, value: el.departmentNumber })).slice(0, 30) ]
                });
            })
            .catch(err => {
                console.error('Autocomplete address by zip_code ERROR: ', err);
            })
        })
        .catch(err => {
            console.error("Loading project data ERROR: ", err);
        })
    }, [config, project_id]);

    const handleChange = (value, name) => {
        if (error[name]) {
            setError({...error, [name]: false});
        }

        if (name === 'city') {
            if (modifiedProjectData.zip_code.length !== 5) {
                setModifiedProjectData({
                    ...modifiedProjectData,
                    zip_code: value.zipCode && value.zipCode.slice(0,5),
                    [name]: value.label
                })
            } else {
                setModifiedProjectData({
                    ...modifiedProjectData,
                    [name]: value.label
                });
            }
            filterDepartments(value.value);
        } else if (name === 'department') {
            setModifiedProjectData({
                ...modifiedProjectData,
                [name]: value.label
            })
        } else {
            setModifiedProjectData({
                ...modifiedProjectData,
                [name]: value,
            });
        }
    }

    const handleDateChange = (value, name) => {
        if (error.date) {
            setError({...error, date: false});
        }
        setModifiedProjectData({
            ...modifiedProjectData,
            [name]: value.formatted,
        });
        setProjectData({
            ...projectData,
            [name]: value
        })
    }

    const onSelect = (value) => {
        setModifiedProjectData({
            ...modifiedProjectData,
            collection: value.label
        })
    }

    const onSubmit = () => {
        const required_params = ["department", "zip_code", "number", "name", "address", "city", "collection"];
        let errored = false;
        let error_list = {}

        if (!modifiedProjectData) {
            return;
        }

        for (var i = 0; i < required_params.length; i++) {
            if (!modifiedProjectData[required_params[i]] && modifiedProjectData[required_params[i]] === '') {
                error_list[required_params[i]] = true;
                errored = true;
            }
        }

        setError({...error, ...error_list});
        if (errored) {
            notify_error(ON_SAVE_MODIFICATION_STEP0_ERROR);
            return;
        }

        if (error.date) {
            notify_error(ON_SAVE_STEP0_INVALID_DATES);
            return;
        }

        if (!modifiedProjectData.zip_code || (modifiedProjectData.zip_code && modifiedProjectData.zip_code.length !== 5)) {
            notify_error('Le code postal est érroné, veuillez vérifier qu\'il soit complet');
            setError({...error, zip_code: true});
            return;
        }

        api.put(API_UPDATE_PROJECT(project_id), modifiedProjectData, config)
        .then(res => {
            notify(ON_SAVE_STEP0_SUCCESS);
        })
        .catch(err => {
            notify_error(ON_SAVE_STEP0_ERROR);
            console.error('Save project data modifications ERROR: ', err);
        })
    }

    const onDisableProject = () => {
        api.post(API_DISABLE_PROJECT, {project_id: project_id}, config)
        .then(res => {
            history.push('/');
            notify(ON_DISABLED_PROJECT_SUCCESS);
        })
        .catch(err => {
            if (err.response && err.response.status === 403) {
                notify_error(UNAUTHORIZED_TO_DISABLE_PROJECT);
                return;
            }
            notify_error(ON_DISABLED_PROJECT_FAIL);
            console.error('Delete project ERROR: ', err);
        })
    }

    const confirmationDelete = () => {
        if (active_step !== 'Conception') {
            notify_error(UNABLE_TO_REMOVE_PROJECT)
            return;
        }
        confirmAlert({
            title: 'Voulez-vous vraiment supprimer le projet?',
            message: 'Les données resteront enregistrées en base de données mais le projet sera désormais indisponible depuis cette page web.',
            buttons: [
                {
                    label: 'Supprimer',
                    onClick: () => onDisableProject()
                },
                {
                    label: 'Annuler',
                }
            ]
        });
    };

    return (
        modifiedProjectData ?
        <>
            <h4 className="text-primary m-0">Projet</h4>
            <div className="row px-2">
                <Input error={error.number} value={modifiedProjectData.number || ""} onChange={(value) => handleChange(value, 'number')} placeholder="Numéro de projet" label="Numéro de projet" className="col-12 col-md-3" />
                <Input error={error.name} value={modifiedProjectData.name || ""} onChange={(value) => handleChange(value, 'name')} placeholder="Nom du projet" label="Nom du projet" className="col-12 col-md-9" />
                <TextArea error={error.address} value={modifiedProjectData.address || ""} onChange={(value) => handleChange(value, 'address')} inputSimulate={true} placeholder="Adresse du projet" label="Adresse du projet" className="m-0 col-12 col-sm-3" />
                <Input error={error.zip_code} onBlur={() => handleAddressCompletion(modifiedProjectData.zip_code)} value={modifiedProjectData.zip_code || ""} onChange={(value) => handleChange(value, 'zip_code')} placeholder="Code postal" label="Code postal" className="col-12 col-sm-3" />
                <Select
                    error={error.city}
                    placeholder="Ville"
                    label="Ville"
                    selectElements={address.cities}
                    className="col-12 col-sm-3"
                    defaultValue={address.cities.filter(el => el.label === modifiedProjectData.city)[0]}
                    onSelect={(value) => handleChange(value, 'city')}
                />
                <Select
                    error={error.department}
                    placeholder="Département"
                    label="Département"
                    selectElements={address.departments}
                    className="col-12 col-sm-3"
                    defaultValue={address.departments.filter(el => el.label === modifiedProjectData.department)[0]}
                    onSelect={(value) => handleChange(value, 'department')}
                />
            </div>
            <h4 className="text-primary m-0 mt-3">Gamme pré-sélectionnée</h4>
            <div className="px-2">
                <MySelect
                    error={error.collection}
                    selectElements='collections'
                    placeholder="Pré-sélectionnez une gamme"
                    label="Pré-sélectionnez une gamme"
                    className="m-0"
                    defaultValue={modifiedProjectData.collection}
                    noOptionsLabel={() => "Aucune gamme n'existe sous ce nom"}
                    onSelect={onSelect}
                />
            </div>
            <h4 className="text-primary mt-3">Contacts</h4>
            <div className="px-2">
                <h5 className="m-o mb-1"><small className="text-underline font-weight-bold">Chargé de projet CIC :</small></h5>
                <div className="px-2 pt-2 bg-light">
                    {
                        projectData &&
                        projectData.cic ?
                        <div className="row">
                            <p className="col-12 col-sm-4 col-md-3"><span className="text-underline">Prénom:</span> {projectData.cic.firstName}</p>
                            <p className="col-12 col-sm-4 col-md-3"><span className="text-underline">Nom:</span> {projectData.cic.lastName}</p>
                            <p className="col-12 col-sm-4 col-md-6"><span className="text-underline">Numéro de téléphone:</span> {projectData.cic.phoneNumber}</p>
                            <p className="col-12 col-sm-4 col-md-6"><span className="text-underline">Adresse mail:</span> {projectData.cic.email}</p>
                        </div>
                        :
                        <h6 className="text-muted">Aucun chargé de projet CIC n'a été renseigné sur ce projet.</h6>
                    }
                </div>
                <h5 className="m-o mb-1 mt-2"><small className="text-underline font-weight-bold">Architecte :</small></h5>
                <div className="px-2 pt-2 bg-light">
                    {
                        projectData &&
                        projectData.architect ?
                        <div className="row">
                            <p className="col-12 col-md-4"><span className="text-underline">Nom:</span> {projectData.architect.firstName}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Prenom:</span> {projectData.architect.lastName}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Numéro de téléphone:</span> {projectData.architect.phone}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Adresse mail:</span> {projectData.architect.email}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Siret:</span> {projectData.architect.siret}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Raison sociale:</span> {projectData.architect.socialReason}</p>
                        </div>
                        :
                            projectData &&
                            projectData.cic ?
                            <h6 className="text-muted">Le chargé de projet occupe la place du cabinet d'architecte dans ce projet.</h6>
                            :
                            <h6 className="text-muted">Aucun architect n'a été renseigné sur ce projet.</h6>
                    }
                </div>
                <h5 className="m-o mb-1 mt-2"><small className="text-underline font-weight-bold">Bureau d'étude :</small></h5>
                <div className="px-2 pt-2 bg-light">
                    {
                        projectData &&
                        projectData.design_office ?
                        <div className="row">
                            <p className="col-12 col-md-4"><span className="text-underline">Nom:</span> {projectData.design_office.firstName}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Prenom:</span> {projectData.design_office.lastName}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Numéro de téléphone:</span> {projectData.design_office.phone}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Adresse mail:</span> {projectData.design_office.email}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Siret:</span> {projectData.design_office.siret}</p>
                            <p className="col-12 col-md-4"><span className="text-underline">Raison sociale:</span> {projectData.design_office.socialReason}</p>
                        </div>
                        :
                        <h6 className="text-muted">Aucun bureau d'étude n'a été renseigné sur ce projet</h6>
                    }
                </div>
            </div>
            <h4 className="text-primary m-0 mt-3">Planification</h4>
            <div className="row px-2 mt-3">
                <div className="col-12 col-sm-4">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'start_date_consultation')}
                        selectedDate={new Date(projectData.start_date_consultation.date)}
                        label="Date de début de consultation"
                        minDate={projectData.start_date_consultation.date}
                        errored={() => setError({...error, date: true})}
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'end_date_consultation')}
                        selectedDate={projectData.end_date_consultation.date}
                        label="Date de fin de consultation"
                        minDate={projectData.start_date_consultation.date}
                        errored={() => setError({...error, date: true})}
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'signature_date')}
                        selectedDate={projectData.signature_date.date}
                        label="Date de signature de marché"
                        minDate={projectData.end_date_consultation.date}
                        errored={() => setError({...error, date: true})}
                    />
                </div>
            </div>
            <div className="pt-5 d-flex">
                <Button
                    outlined
                    extended
                    className="mr-2 py-2"
                    button_title="Supprimer le projet"
                    bg_color="dark"
                    onClick={confirmationDelete}
                />
                <Button
                    button_title="Sauvegarder les modifications"
                    bg_color="primary"
                    extended
                    className="py-2"
                    onClick={onSubmit}
                />
            </div>
        </>
        :
        <div></div>
    );
};

export default withRouter(Step01);