import React from 'react';

const PageHeaderStructure = props => {
    return (
        <div className="container-fluid bg-white m-b-30">
            <div className={"row" + (props.expanded ? " px-xl-6" : "")}>
                <div className="text-dark p-t-40 p-b-90 w-100 px-3 px-xl-0">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default PageHeaderStructure;