import React from 'react';
import { useSelector } from 'react-redux';
import StepsStructure from '../steps-structure';
import StepConception1 from './step-conception-1';
import StepConception2 from './step-conception-2';
import StepConception3 from './step-conception-3';
import StepConception4 from './step-conception-4';
import { ARCHITECTURE_FIRM_ID, ARCHITECT_ID, DESIGN_OFFICE_ID, PRESCRIBER_ID, NATIONAL_CIC_ID, PROJECT_MANAGER_ID, NAME_STEP_CONCEPTION_1, NAME_STEP_CONCEPTION_2, NAME_STEP_CONCEPTION_3, NAME_STEP_CONCEPTION_4 } from 'utils/constants';
var Scroll = require('react-scroll');
var scroller = Scroll.scroller;

const StepsConception = () => {
    const [onScroll, setOnScroll] = React.useState(false);
    const active_step = useSelector(state => state.stepNavigation.current_step);
    const project_collection = useSelector(state => state.stepNavigation.collection);

    if (!onScroll && active_step) {
        setOnScroll(true);
        setTimeout(() => {
            scroller.scrollTo(active_step, {
                duration: 300,
                delay: 100,
                smooth: true,
                offset: -150,
            })
        }, 500)
    }

    return (
        <StepsStructure>
            <StepConception1 name={NAME_STEP_CONCEPTION_1} ReadRights={[PRESCRIBER_ID, NATIONAL_CIC_ID]} SpecialRights={[PROJECT_MANAGER_ID]} WriteRights={[PROJECT_MANAGER_ID, ARCHITECTURE_FIRM_ID, ARCHITECT_ID, DESIGN_OFFICE_ID]} />
            <StepConception2 name={NAME_STEP_CONCEPTION_2} ReadRights={[PRESCRIBER_ID, NATIONAL_CIC_ID]} SpecialRights={[PROJECT_MANAGER_ID]} WriteRights={[PROJECT_MANAGER_ID, ARCHITECTURE_FIRM_ID, ARCHITECT_ID, DESIGN_OFFICE_ID]} />
            <StepConception3 name={NAME_STEP_CONCEPTION_3} ReadRights={[NATIONAL_CIC_ID]} WriteRights={[PRESCRIBER_ID]} gamme_projet={project_collection} />
            <StepConception4 name={NAME_STEP_CONCEPTION_4} ReadRights={[PRESCRIBER_ID, NATIONAL_CIC_ID]} SpecialRights={[PROJECT_MANAGER_ID]} WriteRights={[PROJECT_MANAGER_ID, ARCHITECTURE_FIRM_ID, ARCHITECT_ID]} />
        </StepsStructure>
    );
};

export default StepsConception;
