import React from 'react';
import { useSelector } from 'react-redux';
import StepsStructure from '../steps-structure';
import StepConsultation1 from './step-consultation-1';
import StepConsultation2 from './step-consultation-2';
import { PRESCRIBER_ID, NATIONAL_CIC_ID, NAME_STEP_CONSULTATION_1, NAME_STEP_CONSULTATION_2, NAME_STEP_CONSULTATION_3 } from 'utils/constants';
import StepConsultation3 from './step-consultation-3';
var Scroll = require('react-scroll');
var scroller = Scroll.scroller;

const StepsConsultation = ({ active_step }) => {
    const [onScroll, setOnScroll] = React.useState(false);
    const project_collection = useSelector(state => state.stepNavigation.collection);

    if (!onScroll && active_step) {
        setOnScroll(true);
        setTimeout(() => {
            scroller.scrollTo(active_step, {
                duration: 300,
                delay: 100,
                smooth: true,
                offset: -75,
            })
        }, 500)
    }

    return (
        <StepsStructure>
            <StepConsultation1 name={NAME_STEP_CONSULTATION_1} ReadRights={[NATIONAL_CIC_ID]} WriteRights={[PRESCRIBER_ID]} />
            <StepConsultation2 gamme_project={project_collection} name={NAME_STEP_CONSULTATION_2} ReadRights={[NATIONAL_CIC_ID]} WriteRights={[PRESCRIBER_ID]} />
            <StepConsultation3 name={NAME_STEP_CONSULTATION_3} ReadRights={[NATIONAL_CIC_ID]} WriteRights={[PRESCRIBER_ID]} />
        </StepsStructure>
    );
};

export default StepsConsultation;