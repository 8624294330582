import React from 'react';
import StepsStructure from '../steps-structure';
import Step01 from './step-0-1';
import { PRESCRIBER_ID } from 'utils/constants';

const Step0 = () => {
    return (
        <StepsStructure>
            <Step01 WriteRights={[PRESCRIBER_ID]} />
        </StepsStructure>
    );
};

export default Step0;