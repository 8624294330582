import React from 'react';
import MyCreatableSelect from 'components/form/select/creatable-select';
import { Button } from 'components/form/button';
import api, { API_ADD_COMPANY, API_GET_SEARCHED_COMPANIES, API_REMOVE_SELECTED_COMPANY, API_GET_ADDED_COMPANIES, API_CONFIRM_ADD_COMPANIES } from 'utils/api';
import { useSelector, useDispatch } from 'react-redux';
import CreateNeutralCompany from 'components/form/create/create-neutral-company';
import { notify_error, notify } from 'utils/utils';
import { setCurrentStep } from 'actions/steps-actions';
import { ON_SAVE_ADDED_COMPANY_ERROR, ON_NO_COMPANY_ADDED_ERROR, ON_COMPANIES_ADDED_SUCCESS, ON_COMPANIES_ADDED_FAIL } from 'utils/notifications';

const partner_company_type = 'partner';
const neutral_company_type = 'neutral';

const CompanyLine = ({ company, onDelete, type }) => (
    <li className="border-bottom" style={{listStyleType: 'none'}}>
        <div className="d-flex justify-content-between align-items-center pt-2">
            <p className="m-0">{company.siret} - {company.name}</p>
            {
                type !== partner_company_type &&
                <i className="mdi mdi-delete text-primary mr-2" style={{cursor: 'pointer'}} onClick={() => onDelete(company.id)}></i>
            }
        </div>
        {
            company.contactFirstName &&
            <small className="pb-2 pt-1 px-2 bg-light row">
                <div className="col-12 col-md-4"><span className="text-underline font-weight-bold">Contact:</span> {company.contactFirstName} {company.contactLastName}</div>
                {
                    company.phoneNumber ?
                    <div className="col-12 col-md-4"><span className="text-underline font-weight-bold">Téléphone fixe:</span> {company.phoneNumber}</div>
                    :
                    <div className="col-12 col-md-4"><span className="text-underline font-weight-bold">Téléphone portable:</span> {company.mobileNumber}</div>
                }
                <div className="col-12 col-md-4"><span className="text-underline font-weight-bold">Adresse mail:</span> {company.contactMail}</div>
            </small>
        }
    </li>
)


const StepConsultation1 = ({ CanConfirm }) => {
    const [partnercompaniesList, setPartnerCompaniesList] = React.useState(undefined)
    const [neutralcompaniesList, setNeutralCompaniesList] = React.useState(undefined)
    const [createNeutralCompany, setCreateNeutralCompany] = React.useState(false);
    const [companiesLists, setCompaniesList] = React.useState({
        [partner_company_type]: [],
        [neutral_company_type]: []
    });
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR)
    const dispatch = useDispatch();

    if (partnercompaniesList === undefined && neutralcompaniesList === undefined) {
        api.get(API_GET_ADDED_COMPANIES(project_id), config)
        .then(res => {
            setPartnerCompaniesList(res.data.companies_partner || []);
            setNeutralCompaniesList(res.data.companies_neutral || []);
        })
        .catch(err => {
            console.error('Get all the companies ERROR: ', err);
        })
    }

    const addNewCompany = (company_id, type) => {
        const data = {
            company_id: company_id,
            company_type: type
        }

        api.post(API_ADD_COMPANY(project_id), data, config)
        .then(res => {
            setNeutralCompaniesList([
                ...neutralcompaniesList,
                {
                    ...res.data,
                }
            ])
        })
        .catch(err => {
            notify_error(ON_SAVE_ADDED_COMPANY_ERROR);
            console.error(`Add new ${type} company ERROR: `, err);
        })
    }

    const removeCompany = (company_id) => {
        api.post(API_REMOVE_SELECTED_COMPANY(project_id), {company_id: company_id}, config)
        .then(res => {
            setNeutralCompaniesList([
                ...neutralcompaniesList.filter(element => element.id !== company_id),
            ])
        })
        .catch(err => {
            console.error('Remove selected company ERROR: ', err);
        })
    }

    const onChangeNeutralCompany = (valueType, actionType) => {
        if (actionType.action === "select-option") {
            if (!neutralcompaniesList.filter((data) => data.name === valueType.label).length) {
                addNewCompany(valueType.value, neutral_company_type);
            }
        } else if (actionType.action === "create-option") {
            setCreateNeutralCompany(true);
        }
    }

    const NewCompanyCreate = (company) => {
        if (!neutralcompaniesList.filter((data) => data.name === company.name).length) {
            setNeutralCompaniesList([
                ...neutralcompaniesList,
                {
                    name: company.name,
                    id: company.id,
                    ...company,
                }
            ])
            addNewCompany(company.id, neutral_company_type);
            setCreateNeutralCompany(false);
        }
    }

    const onsubmit = () => {
        if (neutralcompaniesList.length <= 0 && partnercompaniesList.length <= 0) {
            notify_error(ON_NO_COMPANY_ADDED_ERROR);
            return;
        }

        var body = {
            company_ids: JSON.stringify([...neutralcompaniesList.map(el => el.id), ...partnercompaniesList.map(el => el.id)])
        }

        api.post(API_CONFIRM_ADD_COMPANIES(project_id), body, config)
        .then(res => {
            notify(ON_COMPANIES_ADDED_SUCCESS)
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
        })
        .catch(err => {
            notify_error(ON_COMPANIES_ADDED_FAIL)
            console.error('Confirm all companies ERROR: ', err);
        })
    }

    const handleInputChange = (value, name) => {
        if (value === '') {
            return;
        }

        api.post(API_GET_SEARCHED_COMPANIES(project_id), {filter: value}, config)
        .then(res => {
            setCompaniesList({
                ...companiesLists,
                [name]: [
                    ...res.data.map(el => ({
                        label: el.siret + ' - ' + el.name,
                        value: el.id
                    })),
                ]
            });
        })
        .catch(err => {
            console.error('Get companies from filter ERROR: ', err);
        })
    }

    return (
        <>
            <h4 className="text-primary m-0">Entreprises partenaires</h4>
            <div className="container-fluid px-xl-5">
                <ul className="px-0 m-0 py-3">
                    {
                        partnercompaniesList && partnercompaniesList.map((element, i) => (
                            <CompanyLine type={partner_company_type} company={element} onDelete={(id) => removeCompany(id, partner_company_type)} key={i} />
                        ))
                    }
                </ul>
            </div>
            <h4 className="text-primary m-0">Entreprises neutres</h4>
            <div className="container-fluid px-xl-5">
                <ul className="p-0 m-0">
                    {
                        neutralcompaniesList && neutralcompaniesList.map((element, i) => (
                            <CompanyLine company={element} onDelete={(id) => removeCompany(id, neutral_company_type)} key={i} />
                        ))
                    }
                </ul>
                {
                    createNeutralCompany &&
                    <CreateNeutralCompany onSubmit={NewCompanyCreate} />
                }
                <div className="d-flex align-items-end mb-2">
                    <MyCreatableSelect
                        onInputChange={(value) => handleInputChange(value, neutral_company_type)}
                        extended
                        onChange={onChangeNeutralCompany}
                        label="Ajouter une entreprise neutre"
                        data={companiesLists[neutral_company_type]}
                        placeholder="Entreprises neutres"
                        formatCreateLabelText={(inputText) => `Créer l'entreprise ${inputText ? `"${inputText}"` : ''}`}
                        isClearable_opt
                    />
                </div>
            </div>
            {
                CanConfirm &&
                <div className=" d-flex justify-content-end mt-3">
                    <Button onClick={onsubmit} type="button" className="py-2 px-4" bg_color="primary" button_title="Confirmer" />
                </div>
            }
        </>
    );
};

export default StepConsultation1;