const feed = {
    feed_posts: [],
    reloadProjectPrice: null,
}

export default (state = feed, action) => {
    switch (action.type) {
        case 'loadPostsFeed':
            return {
                ...state,
                feed_posts: action.payload.map(el => el),
            };
        case 'addPostFeed':
            return {
                ...state,
                feed_posts: [
                    ...state.feed_posts.map(el => el),
                    action.payload
                ],
            };
        case 'reloadPriceProject':
            return {
                ...state,
                reloadProjectPrice: action.payload
            };
        default:
            return state;
    }
}
