import React from 'react';

const ProductsStructure = ({ children }) => {
    const [techInfo, footer] = children
    return (
        <>
            <div className="card squared">
                <div className="card-body">
                    {techInfo}
                </div>
            </div>
            {footer}
        </>
    );
};

export default ProductsStructure;