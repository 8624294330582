import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HomeStructure from './home-structure';
import NavBar from 'components/navigation/navbar';
import SideBar from 'components/navigation/sidebar';
import ProjectsPage from 'pages/page-content/projects-page';
import { StepsPage } from 'pages/page-content/steps-page';
import { HistoryPage } from 'pages/page-content/history-page';
import ProjectCreate from 'pages/page-content/projects-page/project-create';
import PriceListGeneralPage from 'pages/page-content/price-list-page/price-list-general-page';
import { ProfilePage } from 'pages/profile';
import { Button } from 'components/form/button';
import api, { API_GET_COLLECTIONS } from 'utils/api';
import { setAllCollections } from 'actions/steps-actions';
import Lottie from 'react-lottie';
import * as Locker from 'assets/anims/locked.json';

const lockOptionsAnims = {
    loop: false,
    autoplay: true,
    animationData: Locker.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const NotFound = withRouter(({ history }) => {
    return (
        <div className="w-100 py-6">
            <h3 className="text-primary text-center font-weight-bold">Erreur 404, la page à la quelle vous voulez accéder est indisponible.</h3>
            <Button button_title="Retour à la page d'accueil" bg_color="primary" outlined onClick={() => history.push('/')} className="m-auto" />
        </div>
    )
})

const NotAllowed = withRouter(({ history }) => {
    const [pause, setPause] = React.useState(false);

    return (
        <div className="w-100 py-6">
            <div className="m-auto bg-transparent" style={{width: 200, height: 200}}>
                <Lottie options={lockOptionsAnims} width="20" height="20" isPaused={pause} eventListeners={[{eventName: 'enterFrame', callback: () => setTimeout(() => setPause(true), 1200)}]} />
            </div>
            <h3 className="text-primary text-center font-weight-bold">Vous n'avez pas le droit d'accéder à cette page.</h3>
            <Button button_title="Retour à la page d'accueil" bg_color="primary" outlined onClick={() => history.push('/')} className="m-auto" />
        </div>
    )
})

const Home = () => {
    const config = useSelector(state => state.apiR);
    const dispatch = useDispatch();

    React.useEffect(() => {
        api.get(API_GET_COLLECTIONS, config)
        .then(res => {
            if (res.data) {
                dispatch(setAllCollections(res.data.map(el => ({label: el.libellus, value: el.id}))))
            }
        })
        .catch(err => {
            console.error('Get all collections ERROR: ', err);
        });
    }, [config, dispatch])

    return (
        <HomeStructure>
            <SideBar/>
            <NavBar />
            <Switch>
                <Route path="/" component={ProjectsPage} exact />
                <Route path="/projet/:id/etapes" component={StepsPage} exact />
                <Route path="/projet/:id/historique" component={HistoryPage} exact />
                <Route path="/projet/creer" component={ProjectCreate} exact />
                <Route path="/projet/grille_tarifaire" component={PriceListGeneralPage} exact />
                <Route path="/mon_profil" component={ProfilePage} exact />
                <Route path="/not_allowed" component={NotAllowed} exact />
                <Route path="*" component={NotFound} exact />
            </Switch>
        </HomeStructure>
    );
};

export default Home;