import React from 'react';

const StepElementVisited = ({children, tooltip}) => {
    return (
        <div data-toggle="tooltip" data-placement="top" title={tooltip}>
            <div className="avatar avatar-sm">
                <div className="avatar-title rounded-circle bg-primary text-white" style={{boxShadow: 'none'}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StepElementVisited;