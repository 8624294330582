import React from 'react';

const PageNumberIdentifier = ({ actual, pageNumber }) => {
    return (
        <div className={`mx-1 p-1 px-2 bg-${actual ? 'primary' : 'white'}`}>
            {
                actual ?
                <p className="text-white mb-0">{pageNumber}</p>
                :
                <p className="text-pagination mb-0">{pageNumber}</p>
            }
        </div>
    )
}

const DatatablePagination = ({ data }) => {
    const previousPage = React.useCallback(() => data.onChangePage(data.currentPage - 1), [data]);
    const nextPage = React.useCallback(() => data.onChangePage(data.currentPage + 1), [data]);

    const specificPage = React.useCallback((page) => data.onChangePage(page), [data])

    const firstPage = data.currentPage === 1 ? true : false;
    const lastPage = data.currentPage === Math.ceil(data.rowCount / data.rowsPerPage) ? true : false;

    const times = x => f => {
        if (x > 0) {
            f();
            times (x - 1) (f);
        }
    }

    const pages = [];

    times(Math.ceil(data.rowCount / data.rowsPerPage))(() => pages.push({}))

    return (
        <div className="d-flex align-items-center">
            {
                !firstPage ?
                <div onClick={previousPage} style={{cursor: 'pointer'}}>
                    <i className="mdi mdi-less-than px-2"></i>
                </div>
                :
                <i className="mdi mdi-less-than px-2"></i>
            }
            {
                pages.map((el, i) => {
                    if (data.rowCount) {
                        if (i < 2 + (data.currentPage > 2 ? data.currentPage - 2 : 1) || i > (data.rowCount / data.rowsPerPage) - 1) {
                            if (data.currentPage > 2 && (i < 1 || i > data.currentPage - 3)) {
                                return (<div onClick={() => specificPage(i + 1)} style={{cursor: 'pointer'}}>
                                    <PageNumberIdentifier actual={data.currentPage - 1 === i ? true : false} pageNumber={i + 1} />
                                </div>);
                            } else if (data.currentPage > 2 && i === 1) {
                                return <PageNumberIdentifier actual={false} pageNumber="..." />;
                            } else if (data.currentPage > 2) {
                                return null;
                            }
                        } else if (i === 2 + (data.currentPage > 2 ? data.currentPage - 2 : 1)) {
                            return <PageNumberIdentifier actual={false} pageNumber="..." />;
                        } else {
                            return null;
                        }
                    }
                    return (<div onClick={() => specificPage(i + 1)} style={{cursor: 'pointer'}}>
                        <PageNumberIdentifier actual={data.currentPage - 1 === i ? true : false} pageNumber={i + 1} />
                    </div>);
                })
            }
            {
                !lastPage ?
                <div onClick={nextPage} style={{cursor: 'pointer'}}>
                    <i className="mdi mdi-greater-than px-2"></i>
                </div>
                :
                <i className="mdi mdi-greater-than px-2"></i>
            }
        </div>
    );
};

export default DatatablePagination;