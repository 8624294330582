import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FileInputDropzone } from 'components/form/dropzone';
import { Button } from 'components/form/button';
import api, { API_GET_FILES_FROM_API, API_SET_PROJECT_TO_FINISH } from 'utils/api';
import { confirmAlert } from 'react-confirm-alert';
import { NAME_TYPE_FILE_EXECUTION_COMMISSIONING } from 'utils/constants';
import { setCurrentStep } from 'actions/steps-actions';
import { notify, notify_error } from 'utils/utils';
import { ON_FINISH_PROJECT, ON_FINISH_REDIRECT, ON_FINISH_PROJECT_ERROR, ON_FINISH_PROJECT_WITHOUT_FILES, ON_PRICE_LIST_NOT_COMPLETED } from 'utils/notifications';
var moment = require('moment');

const StepExecution3 = ({ CanConfirm, history }) => {
    const [canConf, setCanConf] = React.useState(CanConfirm);
    const config = useSelector(state => state.apiR);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const [files, setFiles] = React.useState([]);
    const filesRef = React.useRef(files);
    const commissioningDate = useSelector(state => state.stepNavigation.dates.comissioning)
    const dispatch = useDispatch();
    filesRef.current = files;

    React.useEffect(() => setCanConf(CanConfirm), [CanConfirm]);

    const getFiles = (file_type, file_field) => {
        api.post(API_GET_FILES_FROM_API(project_id), { type: file_type }, config)
        .then(res => {
            setFiles({
                ...filesRef.current,
                [file_field]: res.data.default,
            });
        })
        .catch(err => {
            console.error('Get uploaded files ERROR: ', err);
        })
    }

    React.useEffect(() => {
        getFiles(NAME_TYPE_FILE_EXECUTION_COMMISSIONING, 'commissioning');
        if (new Date() > new Date(commissioningDate)) {
            document.querySelector('#project_confirmation').classList.add('inactive');
            setCanConf(false);
        }
        // eslint-disable-next-line
    }, [])

    const errorHandleOnFinish = () => {
        if (!files.commissioning || !files.commissioning.length) {
            notify_error(ON_FINISH_PROJECT_WITHOUT_FILES);
            return false;
        }
        return true;
    }

    const onSubmitFinish = () => {
        api.post(API_SET_PROJECT_TO_FINISH(project_id), {}, config)
        .then(res => {
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
            notify(ON_FINISH_PROJECT)
            notify(ON_FINISH_REDIRECT);
            setTimeout(() => history.push('/'), 5000);
        })
        .catch(err => {
            if (err.response && err.response.status === 406) {
                notify_error(ON_PRICE_LIST_NOT_COMPLETED);
                console.error('Set project to finish ERROR: ', err);
                return;
            }
            console.error('Set project to finish ERROR: ', err);
            notify_error(ON_FINISH_PROJECT_ERROR);
        })
    }

    return (
        <>
            <small className="font-weight-bold">Vous ne pourrez téléverser ce fichier qu'après le: {moment(commissioningDate).format('DD/MM/YYYY')} !</small>
            <FileInputDropzone className="w-100" attachment_box_type='not_defined' onSubmit={() => getFiles(NAME_TYPE_FILE_EXECUTION_COMMISSIONING, 'commissioning')} initialFiles={files.commissioning} type={NAME_TYPE_FILE_EXECUTION_COMMISSIONING} removeFile={() => getFiles(NAME_TYPE_FILE_EXECUTION_COMMISSIONING, 'commissioning')} placeholder="Téléverser le rapport de mise en service" button_title="Téléverser" />
            {
                canConf &&
                <div className="pt-2">
                    <Button
                        onClick={() => {
                            if (errorHandleOnFinish()) {
                                confirmAlert({
                                    title: 'Le projet est-il terminé ?',
                                    message: 'À partir de ce moment vous ne pourrez plus modifier le projet, mais vous serez toujours en mesure de voir les étapes et télécharger les fichiers depuis l\'historique.',
                                    buttons: [
                                        {
                                            label: 'Finaliser le projet',
                                            onClick: () => onSubmitFinish()
                                        },
                                        {
                                            label: 'Annuler',
                                        }
                                    ]
                                });
                            }
                        }}
                        extended
                        button_title="PROJET TERMINÉ"
                        bg_color="primary"
                        bold
                    />
                </div>
            }
        </>
    );
};

export default withRouter(StepExecution3);