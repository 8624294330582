import React from 'react';
import Switch from '@material-ui/core/Switch';

const MySwitch = ({ onChange, defaultStatus }) => {
    const [confirmed, setConfirmed] = React.useState(defaultStatus);

    const onChecked = () => {
        onChange(!confirmed);
        setConfirmed(!confirmed);
    }

    React.useEffect(() => setConfirmed(defaultStatus), [defaultStatus]);

    return (
        <Switch
            checked={confirmed}
            onChange={onChecked}
        />
    );
};

export default MySwitch;