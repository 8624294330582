import React from 'react';

const theme_classes = " squared d-flex align-items-center"

const Button = ({
    id,
    outlined,
    tooltip,
    prefix,
    suffix,
    button_title,
    bg_color,
    text_color,
    bold,
    className,
    onClick,
    type,
    extended,
    style }) => {

    const outlined_opt = outlined ? " btn-outline-" : " btn-";
    const font_w_opt = bold ? " font-weight-bold" : "";
    const size = extended ? " w-100" : "";

    let classes = "btn d-flex justify-content-center" + outlined_opt + bg_color + font_w_opt + size + " text-" + text_color + theme_classes + " " + className;

    const Prefix = prefix ? () => (prefix) : undefined;
    const Suffix = suffix ? () => (suffix) : undefined;

    return (
        <button
            id={id}
            type={ type ? type : "button"}
            className={classes}
            data-toggle="tooltip"
            data-placement={tooltip ? tooltip.placement : ''}
            title={tooltip ? tooltip.content : ''}
            onClick={onClick}
            style={style}
        >
            {
                Prefix && <Prefix />
            }
            {button_title}
            {
                Suffix && <Suffix />
            }
        </button>
    );
};

export default Button;