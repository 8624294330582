import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from 'actions/api-actions';
import api, { API_GET_USER_DETAILS } from 'utils/api';
import { loadUser } from 'actions/user-actions';
import { accountPositionAPI } from 'utils/utils';

const DataLoader = ({ history }) => {
    const config = useSelector(state => state.apiR);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const Token = localStorage.getItem('Authorization');

    if (Token && !config.headers.Authorization) {
        dispatch(setToken(Token));
        if (user.position === '' || user.email === '') {
            api.get(API_GET_USER_DETAILS, {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            })
            .then(res => {
                dispatch(loadUser({
                    ...res.data[accountPositionAPI(res.data.position)],
                    position: res.data.position,
                    phone: res.data[accountPositionAPI(res.data.position)].phoneNumber || res.data[accountPositionAPI(res.data.position)].phone,
                }))
            })
            .catch(err => {
                console.error('Get user data on reload ERROR: ', err);
                history.push('/logout');
            })
        }
    }

    return null;
};

export default withRouter(DataLoader);