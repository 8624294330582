export const BAD_LOGIN_IDENTIFIERS = 'Les identifiants sont incorrects';

export const PRESCRIBER_ID = 'prescriber';

export const NATIONAL_CIC_ID = 'national_cic';

export const ARCHITECT_ID = 'architect';

export const DESIGN_OFFICE_ID = 'design_office';

export const ARCHITECTURE_FIRM_ID = 'architecture_firm';

export const PROJECT_MANAGER_ID = 'project_manager';

export const NAME_STEP_CONCEPTION_1 = "adding_architect_referent";

export const NAME_STEP_CONCEPTION_2 = "temperature_technical_information";

export const NAME_STEP_CONCEPTION_3 = "document_importation_study";

export const NAME_STEP_CONCEPTION_4 = "conception_validation";

export const NAME_STEP_CONSULTATION_1 = "company_partners_neutral";

export const NAME_STEP_CONSULTATION_2 = "collection_validation";

export const NAME_STEP_CONSULTATION_3 = "negociation";

export const NAME_STEP_EXECUTION_1 = "company_selection";

export const NAME_STEP_EXECUTION_2 = "execution_confirmation";

export const NAME_STEP_EXECUTION_3 = "project_confirmation";

export const defaultTimePicked = '00:00:00';

export const NAME_TYPE_FILE_CONCEPTION_2 = "conception_tmp_tech_info";

export const NAME_TYPE_FILE_CONCEPTION_3 = "conception_project_study";

export const NAME_TYPE_FILE_EXECUTION_FINAL_STUDY = "execution_company_selection_final_study";

export const NAME_TYPE_FILE_EXECUTION_ORDER_SOLD = "execution_company_selection_order_sold";

export const NAME_TYPE_FILE_HISTORY_POST = 'file_upload'

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const NAME_TYPE_FILE_EXECUTION_COMMISSIONING = "commissioning";
