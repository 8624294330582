import React from 'react';

const HistoryFormStructure = ({ children }) => {
    const [form_header, error_msg, form_inputs, form_navigation_butt] = children;
    return (
        <>
            <div className="card-header">
                {form_header}
            </div>
            <div className="card-body">
                {error_msg}
                {form_inputs}
                <nav>
                    {form_navigation_butt}
                </nav>
            </div>
        </>
    );
};

export default HistoryFormStructure;