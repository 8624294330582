import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import TitleHeader from 'components/header/title-header';
import IsolationFormContent from './isolation-form-content';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'components/form/button';
import { setIsolationForm } from 'actions/steps-actions';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-40%, -50%)',
        maxHeight: window.innerHeight - 300,
        maxWidth: window.innerWidth - 300,
        overflow: 'auto',
        zIndex: 1000,
    }
}

const IsolationFormModal = ({ isOpen, onClose, disabled }) => {
    const formData = useSelector(state => state.stepNavigation.isolation_form);
    const [form, setForm] = React.useState(formData);
    const dispatch = useDispatch();

    React.useEffect(() => setForm(formData), [formData])

    const handleClose = () => {
        onClose(false);
    }

    const handleChange = (value) => {
        console.log(value);
        setForm(value);
    }

    const handleSubmit = () => {
        dispatch(setIsolationForm(form));
        onClose(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            contentLabel="Formulaire d'isolation"
            style={modalStyles}
        >
            <div className="position-relative">
                <TitleHeader title="Formulaire d'isolation" />
                <div className="position-absolute" style={{top: -8, right: 0, cursor: 'pointer'}} onClick={handleClose}>
                    <i className="mdi mdi-close" style={{fontSize: 20}}></i>
                </div>
            </div>
            <div className="px-3">
                <IsolationFormContent options={form} onChange={handleChange} disabled={disabled}/>
            </div>
            <div className="w-100 d-flex justify-content-end">
                <Button button_title="Valider" bg_color="primary" bold className="ml-2" onClick={handleSubmit} />
            </div>
            <div className="w-100 d-flex justify-content-end py-2">Penser à enregistrer l'étape pour prendre en compte ce formulaire.</div>
        </Modal>
    );
};

IsolationFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default IsolationFormModal;
