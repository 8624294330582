import React from 'react';
import { withRouter } from 'react-router-dom';
import ConnectionStructure from 'pages/connection/connection-structure';
import api, { API_RESET_PASSWORD, API_RESET_PASSWORD_CHECK_VALIDITY } from 'utils/api';
import queryString from 'query-string';
import { Input } from 'components/form/input';
import { Button } from 'components/form/button';
import Lottie from 'react-lottie';
import * as errorAnim from 'assets/anims/error-anim.json';
import { notify } from 'utils/utils';
import { ToastContainer } from 'react-toastify';
import { ON_PASSWORD_MODIFIED } from 'utils/notifications';

const errorOptionsAnim = {
    loop: false,
    autoplay: true,
    animationData: errorAnim.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

const ResetPassword = ({ location, history }) => {
    const [badPassword, setBadPassword] = React.useState(false);
    const [urlData] = React.useState(queryString.parse(location.search))
    const [passwords, setPasswords] = React.useState({
        password: '',
        conf_password: '',
    })
    const [isValid, setValid] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (!urlData) {
            return;
        }

        api.get(API_RESET_PASSWORD_CHECK_VALIDITY(urlData.email, urlData.id, urlData.token))
        .then(res => {
            setLoading(false);
            setValid(true);
        })
        .catch(err => {
            setLoading(false);
            console.error('Check validity of token ERROR: ', err);
        })
    })

    const handleChange = (value, name) => {
        if (badPassword === true) {
            setBadPassword(false);
        }

        setPasswords({
            ...passwords,
            [name]: value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (passwords.password !== passwords.conf_password) {
            setBadPassword(true);
            return;
        }

        const body = {
            email: urlData.email,
            password: passwords.password,
        }

        api.post(API_RESET_PASSWORD(urlData.id, urlData.token), body)
        .then(res => {
            setPasswords({password: '', conf_password: ''});
            notify(ON_PASSWORD_MODIFIED)
            setTimeout(() => history.push('/'), 5000);
        })
        .catch(err => {
            console.error('Reseting password ERROR: ', err);
        })
    }

    return (
        <>
        <ToastContainer />
        {
            !isLoading ?
                isValid ?
                <ConnectionStructure>
                    <form onSubmit={onSubmit}>
                        {
                            badPassword &&
                            <p className="text-primary">Attention, les mots de passes ne sont pas égaux.</p>
                        }
                        <h5 className="text-center p-b-20 font-weight-bold">Entrez votre nouveau mot de passe</h5>
                        <Input type="password" value={passwords.password} onChange={(value) => handleChange(value, 'password')} label="Mot de passe" placeholder="Mot de passe" />
                        <Input type="password" value={passwords.conf_password} onChange={(value) => handleChange(value, 'conf_password')} label="Confirmer le mot de passe" placeholder="Confirmer le mot de passe" />
                        <Button className="mt-3 ml-auto" type="submit" bg_color="primary" bold outlined button_title="Réinitialiser" />
                    </form>
                    <div className="col-xl-8 col-md-4 d-none d-md-block bg-cover bg-primary" style={{backgroundImage: "url('assets/img/background_image_login.jpg')"}}></div>
                </ConnectionStructure>
                :
                <div className="position-absolute" style={{top: 25 + '%', left: 'calc(50% - 60px)'}}>
                    <Lottie options={errorOptionsAnim} width="120" height="120" />
                    <p className="text-center position-absolute mt-2">Vous n'avez plus accès à cette page...</p>
                    <Button onClick={() => history.push('/login')} className="mt-6 position-absolute" style={{width: 200, left: -50}} bg_color="dark" button_title="Vers la page de connection" outlined />
                </div>
            :
            <div className="position-absolute" style={{top: 25 + '%', left: 'calc(50% - 60px)'}}>
                <div className="spinner-border text-primary" style={{marginLeft: 5, marginTop: 5, width: 90, height: 90}} role="status">
                    <span className="sr-only">En attente de réponse...</span>
                </div>
            </div>
        }
        </>
    );
};

export default withRouter(ResetPassword);
