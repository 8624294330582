import React from 'react';
import { Datepicker } from 'components/form/datepicker';

const ProjectCreateFormsPlanification = ({ onSave, onError }) => {
    const [dates, setDates] = React.useState({
        start_date: {
            date: null,
            formatted: '',
        },
        end_date: {
            date: null,
            formatted: '',
        },
        signature_date: {
            date: null,
            formatted: '',
        }
    })
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        onError(error);
    }, [error, onError])

    const handleDateChange = (value, name) => {
        if (error) {
            setError(false);
        }

        setDates({
            ...dates,
            [name]: value
        })
        onSave({
            [name]: value.formatted
        })
    }

    return (
        <>
            <h4 className="text-primary m-0">Planification</h4>
            <div className="row px-2 mt-3">
                <div className="col-12 col-sm-4">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'start_date')}
                        selectedDate={dates.start_date.date}
                        label="Date de début de consultation"
                        errored={() => setError(true)}
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'end_date')}
                        selectedDate={dates.end_date.date}
                        label="Date de fin de consultation"
                        minDate={dates.start_date.date}
                        errored={() => setError(true)}
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'signature_date')}
                        selectedDate={dates.signature_date.date}
                        label="Date de signature de marché"
                        minDate={dates.end_date.date}
                        errored={() => setError(true)}
                    />
                </div>
            </div>
        </>
    );
};

export default ProjectCreateFormsPlanification;