import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { selectStyle } from './select';

const MyCreatableSelect = ({
    id,
    placeholder,
    onChange,
    isClearable_opt,
    data,
    formatCreateLabelText,
    label,
    extended,
    onInputChange,
    inputValue,
    defaultValue,
    noOptionsMsg
}) => {
    return (
        <div className={"form-group m-0" + (extended ? " w-100" : "")}>
            <label htmlFor={id} className="my-2">{label}</label>
            <CreatableSelect
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                isClearable={isClearable_opt ? true : false}
                styles={selectStyle}
                options={data}
                onInputChange={onInputChange}
                formatCreateLabel={formatCreateLabelText}
                inputValue={inputValue}
                defaultValue={defaultValue}
                value={defaultValue}
                noOptionsMessage={() => (noOptionsMsg ? noOptionsMsg : 'Faites une recherche pour trouver votre entreprise.')}
            />
        </div>
    );
};

export default MyCreatableSelect;