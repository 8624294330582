import React from 'react';
import PageFooter from 'components/footer/home-page-footer/page-footer';

const PriceListStructure = ({ children, finished }) => {
    return (
        <div className="container-fluid pull-up px-xl-6">
            <div className={"card p-3 squared border" + ((finished === 'perdue' || finished === 'terminé') ? ' step-card inactive' : '')}>
                {children}
            </div>
            <PageFooter />
        </div>
    );
};

export default PriceListStructure;