import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { defaultTimePicked } from 'utils/constants';
import 'moment/locale/fr';
var moment = require('moment');

moment.locale("fr");

const hitachiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#C3002F'
        }
    },
    input: {
        fontFamily: 'Source Sans Pro'
    }
})

const Datepicker = ({ handleDateChange, selectedDate, label, minDate, errored }) => {
    const handleDateChange_fct = (value) => {
        const formatedDate = moment(value).format('YYYY-MM-DD') + ' ' + defaultTimePicked;

        handleDateChange({
            date: value,
            formatted: formatedDate
        });
    }

    return (
        <MuiThemeProvider theme={hitachiTheme}>
            <MuiPickersUtilsProvider libInstance={moment} moment={moment} utils={MomentUtils} locale={"fr"}>
                <DatePicker
                    className="w-100"
                    label={label}
                    value={selectedDate || null}
                    onChange={handleDateChange_fct}
                    clearable
                    disablePast={minDate ? false : true}
                    format="DD/MM/YYYY"
                    minDateMessage="Cette date est invalide"
                    clearLabel="EFFACER"
                    cancelLabel="ANNULER"
                    disableToolbar
                    invalidLabel="Invalide"
                    invalidDateMessage="Format de date invalide"
                    minDate={minDate}
                    onError={(errorMsg) => {
                        if (errorMsg.includes('Cette date est invalide')) {
                            errored()
                        }
                    }}
                />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};

export default Datepicker;