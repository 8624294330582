import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FileInputDropzone } from 'components/form/dropzone';
import GammeSelect from 'components/form/select/gamme-select';
import { Button } from 'components/form/button';
import { NAME_TYPE_FILE_CONCEPTION_3, NAME_TYPE_FILE_CONCEPTION_2 } from 'utils/constants';
import api, { API_GET_FILES_FROM_API, API_CONFIRM_UPLOADED_FILES, API_UPLOAD_FILES, API_UPDATE_PROJECT } from 'utils/api';
import { notify_error, notify, openPriceList, onDownloadFile } from 'utils/utils';
import { setCurrentStep } from 'actions/steps-actions';
import { Input } from 'components/form/input';
import { ON_NOT_SAVED_RATIOS_MODIFICATIONS, ON_EMPTY_RATIOS_FIELDS, ON_NOT_6_REQUIRED_FILES_IMPORTED, ON_CONCEPTION3_VALIDATED_SUCCESS, ON_PRICE_LIST_NOT_COMPLETED, ON_CONCEPTION3_VALIDATED_FAIL, ON_DELETE_FILE_ERROR, ON_SAVE_RATIOS_ERROR, CAN_T_CONFIRM_WITH_REJECTED_FILES } from 'utils/notifications';
import useNumerical from 'hooks/use-numerical';

const StepConception3 = ({ gamme_projet, CanConfirm }) => {
    const [files, setFiles] = React.useState(undefined);
    const [filesPlans, setFilesPlans] = React.useState(undefined);
    const [ratios, setRatios] = React.useState({
        ratio_cold: '',
        ratio_hot: '',
        saved: true,
    });
    const [error, setError] = React.useState({
        ratio_cold: false,
        ratio_hot: false,
    });
    const project_id = useSelector(state => state.stepNavigation.project_id)
    const project_ratios = useSelector(state => state.stepNavigation.ratios)
    const config = useSelector(state => state.apiR);
    const dispatch = useDispatch();
    const [errored, onValue] = useNumerical();

    React.useEffect(() => setRatios({...project_ratios, saved: true}), [project_ratios]);

    React.useEffect(() => {
        api.post(API_GET_FILES_FROM_API(project_id), { type: NAME_TYPE_FILE_CONCEPTION_3 }, config)
        .then(res => {
            setFiles(res.data);
            api.post(API_GET_FILES_FROM_API(project_id), { type: NAME_TYPE_FILE_CONCEPTION_2 }, config)
            .then(res => {
                setFilesPlans(res.data.default);
            })
            .catch(err => {
                console.error('Get uploaded files ERROR: ', err);
            })
        })
        .catch(err => {
            console.error('Get uploaded files ERROR: ', err);
        })
    }, [config, project_id]);

    const onSubmit = () => {
        const files_list = [...files.etudes_dim_devis_esti, ...files.bilan_ge, ...files.bilan_ui, ...files.cctp_propre_gamme_def, ...files.doc_materiel_selec, ...files.dpgf_vierge ];

        if (!ratios.saved) {
            notify_error(ON_NOT_SAVED_RATIOS_MODIFICATIONS);
            return;
        } else if (!ratios.ratio_hot || !ratios.ratio_cold) {
            notify_error(ON_EMPTY_RATIOS_FIELDS);
            setError({...error, ratio_hot: true, ratio_cold: true});
            return;
        }

        if (files_list.length < 6) {
            notify_error(ON_NOT_6_REQUIRED_FILES_IMPORTED);
            return;
        }

        for (var i = 0; i < files_list.length; i++) {
            if (files_list[i].rejected) {
                notify_error(CAN_T_CONFIRM_WITH_REJECTED_FILES);
                return;
            }
        }

        var body = new FormData();

        body.append('file_names', JSON.stringify([ 'file.jpg' ]));

        api.post(API_CONFIRM_UPLOADED_FILES(project_id), body, config)
        .then(res => {
            notify(ON_CONCEPTION3_VALIDATED_SUCCESS);
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
        })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                notify_error(ON_PRICE_LIST_NOT_COMPLETED);
                console.error('Confirm uploaded files ERROR: ', err);
                return;
            }
            notify_error(ON_CONCEPTION3_VALIDATED_FAIL);
            console.error('Confirm uploaded files ERROR: ', err);
        })
    }

    const onRemoveFile = (filename, attachment_type) => {
        const body = new FormData();

        body.append('type', NAME_TYPE_FILE_CONCEPTION_3);
        body.append('deleted_files', JSON.stringify([['', filename ]]))

        api.post(API_UPLOAD_FILES(project_id), body, config)
        .then(res => {
            setFiles({
                ...files,
                [attachment_type]: [ ...(files[attachment_type] && files[attachment_type].filter(file => file.realName !== filename)) ],
            });
        })
        .catch(err => {
            notify_error(ON_DELETE_FILE_ERROR);
            console.error('Delete file ERROR: ', err);
        })
    }

    const onSubmitNewFile = () => {
        api.post(API_GET_FILES_FROM_API(project_id), { type: NAME_TYPE_FILE_CONCEPTION_3 }, config)
        .then(res => {
            setFiles(res.data);
        })
        .catch(err => {
            console.error('Get uploaded files ERROR: ', err);
        })
    }

    const FilesLister = ({ element, attachment_type }) => {
        return (
            <div className="d-flex justify-content-between align-items-center border-bottom">
                <p className={`m-0 ${element.rejected ? ' text-muted' : ''}`}>{element.realName}</p>
                {
                    element.rejected && <span className="badge badge-dark">Rejeté par l'architecte</span>
                }
                <i className="mdi mdi-delete text-primary" style={{fontSize: 24, cursor: 'pointer'}} onClick={() => onRemoveFile(element.realName, attachment_type)}></i>
            </div>
        )
    }

    const FileUploaderElement = ({ files, placeholder, attachment_type }) => {
        return (
            <div className="col-12 col-sm-6">
                {
                    files && files.length > 0 &&
                    <div className="d-flex flex-column w-100 px-3 border-dashed border-bottom-0 border-secondary">
                        {
                            files.map(el => (
                                <FilesLister element={el} attachment_type={attachment_type} />
                            ))
                        }
                    </div>
                }
                <FileInputDropzone className="mt-n3" attachment_box_type={attachment_type} onSubmit={onSubmitNewFile} type={NAME_TYPE_FILE_CONCEPTION_3} placeholder={placeholder} button_title="Ajouter" />
            </div>
        )
    }

    const onDownloadFiles = (filename) => {
        onDownloadFile(project_id, filename, config)
    }

    const handleChange = (value, name) => {
        if (onValue(value, name)) {
            setError({...error, [name]: true});
            return;
        } else if (error[name]) {
            setError({...error, [name]: false});
        }

        setRatios({...ratios, [name]: value, saved: false});
    }

    const onSubmitRatios = () => {
        api.put(API_UPDATE_PROJECT(project_id), ratios, config)
        .then(res => {
            setRatios({...ratios, saved: true});
            setError({...error, ratio_hot: false, ratio_cold: false});
        })
        .catch(err => {
            notify_error(ON_SAVE_RATIOS_ERROR);
            console.error('On save ratios ERROR: ', err);
        })
    }

    return (
        <div id="ConceptionConfirationDonnees">
            <h4 className="text-primary">Plans / coupes cotés importés :</h4>
            <div className="px-4 mb-3">
                {
                    filesPlans && filesPlans.length > 0 ?
                        filesPlans.map(el => (
                            <div className="py-1 border-bottom d-flex justify-content-between align-items-center">
                                <span>{el.realName}</span>
                                <i className="mdi mdi-download text-primary" style={{fontSize: 24, cursor: 'pointer'}} onClick={() => onDownloadFiles(el.realName)}></i>
                            </div>
                        ))
                        :
                        <p className="text-muted">Les plans n'ont pas encore été téléversé.</p>
                }
            </div>
            <h4 className="text-primary">Téléverser ces 6 documents obligatoires :</h4>
            <div className="row px-4">
                <Input error={error.ratio_hot || errored.ratio_hot} value={ratios.ratio_hot} className="col-12 col-sm-4" label={<span>Ratio chaud (W/m<sup>2</sup>)</span>} placeholder="Ratio chaud" onChange={(value) => handleChange(value, 'ratio_hot')} />
                <Input error={error.ratio_cold || errored.ratio_cold} value={ratios.ratio_cold} className="col-12 col-sm-4" label={<span>Ratio froid (W/m<sup>2</sup>)</span>} labelBottom={<span>Surpuissance en froid : 10%</span>} placeholder="Ratio froid" onChange={(value) => handleChange(value, 'ratio_cold')} />

                <div className="col-12 col-sm-4 d-flex align-items-end">
                    <Button button_title={!ratios.saved ? "Enregistrer les ratios" : "Ratios sauvegardés"} bg_color="dark" outlined={ratios.saved} extended className="h-50" onClick={onSubmitRatios} />
                </div>
            </div>
            <div className="form-group row p-3 pb-0 m-0">
                {
                    files &&
                    <>
                        <FileUploaderElement attachment_type='etudes_dim_devis_esti' files={files.etudes_dim_devis_esti} placeholder="Fiches récapitulatives Hitachi / Fiche récapitulative vierge" />
                        <FileUploaderElement attachment_type='bilan_ge' files={files.bilan_ge} placeholder="Plan(s) minute(s) de principe(s) frigorifique(s)" />
                        <FileUploaderElement attachment_type='bilan_ui' files={files.bilan_ui} placeholder="Bilan des GE / Bilan des UI" />
                        <FileUploaderElement attachment_type='cctp_propre_gamme_def' files={files.cctp_propre_gamme_def} placeholder="CCTP propre à l'affaire suivant la gamme définit" />
                        <FileUploaderElement attachment_type='doc_materiel_selec' files={files.doc_materiel_selec} placeholder="Bilan électrique" />
                        <FileUploaderElement attachment_type='dpgf_vierge' files={files.dpgf_vierge} placeholder="DPGF vierge" />
                        <Button extended outlined bg_color="primary" button_title="Remplir la grille tarifaire (obligatoire)" className="col-12 my-2" onClick={() => openPriceList(project_id)} />
                    </>
                }
            </div>
            <GammeSelect gamme_project={gamme_projet} className="d-flex align-items-center" />
            {
                CanConfirm &&
                <div className="d-flex justify-content-end w-100">
                    <Button
                        button_title="Confirmer"
                        bg_color="primary"
                        onClick={onSubmit}
                    />
                </div>
            }
        </div>
    );
};

export default StepConception3;
