import React from 'react';
import {connect, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'components/form/input';
import { Button } from 'components/form/button';
import api, { API_SAVE_PRICE_CONCURRENCE_COMPOSED } from 'utils/api';
import useNumerical from 'hooks/use-numerical';
import { reloadPriceProject } from 'actions/feed-actions';

const PriceCocurrenceFournisPose = ({ data, disabled, currentStepUniq, reloadPriceProject, reloadProjectPrice}, editable = false) => {
    const [form, setForm] = React.useState({
        competitors_price_1: data.competitorPriceOne,
        competitors_price_2: data.competitorPriceTwo,
        composed_price: data.composedPrice
    })
    const [isModified, setModified] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState();
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);
    const [error, onValue] = useNumerical();

    React.useEffect(() => {
        setForm({
            competitors_price_1: data.competitorPriceOne,
            competitors_price_2: data.competitorPriceTwo,
            composed_price: data.composedPrice
        });
    }, [data])

    const handleChange = (value, name) => {
        if (!disabled) {
            if (onValue(value, name)) {
                return;
            }

            setForm(form => ({
                ...form,
                [name]: value,
            }));
            setModified(true);
        }
    }

    const handleSave = () => {
        if (!disabled) {
            api.put(API_SAVE_PRICE_CONCURRENCE_COMPOSED(project_id), form, config)
              .then(res => {
                  console.log('dans le save', currentStepUniq)
                  if (currentStepUniq === 'execution_confirmation') {
                      // dispatch action to reload price on project
                      reloadPriceProject(true);
                  }
                  setModified(false);
              })
              .catch(err => {
                  setErrorMsg('Une erreur est survenue lors de la sauvegarde des prix...');
                  console.error('On save concurrent and composed prices ERROR: ', err);
              })
        }
    }

    console.log(data);
console.log('reloadProjectPricereloadProjectPrice', reloadProjectPrice);
    return (
        <>
            {
                errorMsg &&
                <p className="text-primary">{errorMsg}</p>
            }
            <Input onBlur={() => onValue(form.competitors_price_1, 'competitors_price_1')} error={error.competitors_price_1} value={form.competitors_price_1} onChange={(value) => handleChange(value, 'competitors_price_1')} label="Prix concurrent Daikin" placeholder="Prix concurrent Daikin" />
            <Input onBlur={() => onValue(form.competitors_price_2, 'competitors_price_2')} error={error.competitors_price_2} value={form.competitors_price_2} onChange={(value) => handleChange(value, 'competitors_price_2')} label="Prix concurrent Mitsubishi" placeholder="Prix concurrent Mitsubishi" />
            <Input onBlur={() => onValue(form.composed_price, 'composed_price')} error={error.composed_price} value={form.composed_price} onChange={(value) => handleChange(value, 'composed_price')} label="Prix fourni posé" placeholder="Prix fourni posé" />
            {
                isModified &&
                <Button
                    bg_color="primary"
                    button_title="Enregistrer"
                    onClick={handleSave}
                    extended
                    className="mt-2"
                />
            }
        </>
    );
};

PriceCocurrenceFournisPose.propTypes = {
    data: PropTypes.any,
};

const mapStateToProps = state => ({
    reloadProjectPrice: state.feedR.reloadProjectPrice,
})

const mapDispatchToProps = dispatch => ({
    reloadPriceProject: payload => dispatch(reloadPriceProject(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceCocurrenceFournisPose);
