import React from 'react';
import Switch from '@material-ui/core/Switch';
import { Select } from '.';
import { TextArea } from '../input';
import { Button } from '../button';
import api, { API_CHANGE_COLLECTION } from 'utils/api';
import { useSelector } from 'react-redux';
import { notify, notify_error } from 'utils/utils';
import { ON_CHANGE_COLLECTION_NO_DESCRIPTION, CHANGE_COLLECTION_SUCCESS, CHANGE_COLLECTION_FAIL } from 'utils/notifications';


const GammeSelect = ({ gamme_project, className, topClass, onSaved }) => {
    const [saveGamme, changeSaveGamme] = React.useState(true);
    const [newGamme, setNewGamme] = React.useState({});
    const [gammeSaved, setGammeSaved] = React.useState(false);
    const [inputError, setInputError] = React.useState(false);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);

    const handleChange = (value) => {
        if (inputError) {
            setInputError(false);
        }

        if (gammeSaved) {
            setGammeSaved(false);
        }

        setNewGamme({
            ...newGamme,
            description: value
        })
    }

    const onSelect = (value) => {
        if (gammeSaved) {
            setGammeSaved(false);
        }
        setNewGamme({
            ...newGamme,
            collection_id: value.value
        })
    }

    const onSubmit = () => {
        if (!newGamme.description) {
            notify_error(ON_CHANGE_COLLECTION_NO_DESCRIPTION);
            setInputError(true);
            return;
        }

        api.put(API_CHANGE_COLLECTION(project_id), newGamme, config)
        .then(res => {
            notify(CHANGE_COLLECTION_SUCCESS);
            setGammeSaved(true);
            if (onSaved) {
                onSaved();
            }
        })
        .catch(err => {
            notify_error(CHANGE_COLLECTION_FAIL);
            console.error('Change to the new gamme ERROR: ', err);
        })
    }

    return (
        <div className={topClass + " px-3 pb-3"}>
            <div className={className || "form-group"}>
                <p className="m-0">Y a-t-il une dérive au projet ? </p>
                <div className="d-flex align-items-center ml-auto">
                    <p className="m-0">Oui</p>
                    <Switch
                        checked={saveGamme}
                        onChange={() => changeSaveGamme(!saveGamme)}
                    />
                    <p className="m-0">Non</p>
                </div>
            </div>
            {
                !saveGamme &&
                <div className="w-100 px-3 py-2 bg-light my-2 border">
                    <Select
                        label="Gamme finalement retenue"
                        selectElements='collections'
                        placeholder="Sélectionnez la gamme finalement retenue"
                        onSelect={onSelect}
                        noOptionsLabel={() => "Aucune gamme trouvée"}
                    />
                    <TextArea
                        label="Expliquez pourquoi ce changement"
                        placeholder="Ecrivez ici votre explication..."
                        onChange={handleChange}
                        expanded
                        error={inputError}
                    />
                    {
                        !gammeSaved &&
                        <Button
                            outlined
                            bg_color="primary"
                            button_title="Sauvegarder le changement"
                            className="ml-auto"
                            onClick={onSubmit}
                        />
                    }
                </div>
            }
        </div>
    );
};

export default GammeSelect;