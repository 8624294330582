
export const egScheme = [
    {
        title: 'Informations générales',
        value: 'bigTitle'
    },
    {
        title: 'Référence des Groupes extérieurs',
        value: 'reference'
    },
    {
        title: 'Nombre de groupes sur le projet',
        value: 'projectGroupNbr'
    },
    {
        title: 'Informations techniques',
        value: 'bigTitle'
    },
    {
        title: 'Technologie HITACHI',
        value: 'techno'
    },
    {
        title: 'Solution 2 tubes ou 3 tubes',
        value: 'tube'
    },
    {
        title: 'Nombre d\'unité intérieure max connectable',
        value: 'amountUnits'
    },
    {
        title: 'Puissance (en CV)',
        value: 'power'
    },
    {
        title: 'P nom restitué froid (en kW) (à +35ºC)',
        value: 'pNameCold'
    },
    {
        title: 'P nom restituée chaud (en kW) (à +7ºC)',
        value: 'pNameHot'
    },
    {
        title: 'Pabs élec nominal froid (en kW)',
        value: 'pabsElecNominalCold'
    },
    {
        title: 'Pabs élect nominal chaud (en kW)',
        value: 'pabsElecNominalHot'
    },
    {
        title: 'EER (à 35ºC)',
        value: 'eer'
    },
    {
        title: 'SEER',
        value: 'seer'
    },
    {
        title: 'COP (à +7ºC)',
        value: 'cop'
    },
    {
        title: 'SCOP (climat moyen)',
        value: 'scop'
    },
    {
        title: 'Classe énergétique saisonnière ou rendement (en froid)',
        value: 'energyClassCold'
    },
    {
        title: 'Classe énergétique saisonnière ou rendement (en chaud)',
        value: 'energyClassHot'
    }
]

export const uiScheme = [
    {
        title: 'Informations générales',
        value: 'bigTitle'
    },
    {
        title: 'Référence',
        value: 'reference'
    },
    {
        title: 'Gamme',
        value: 'referenceGroup'
    },
    {
        title: 'Performances thermique',
        value: 'bigTitle'
    },
    {
        title: 'Conditions suivant la EN 14511-3',
        value: 'title'
    },
    {
        title: 'P nom froid (en kW)',
        value: 'techPerfPCold'
    },
    {
        title: 'P nom chaud (en kW)',
        value: 'techPerfPHot'
    },
    {
        title: 'Consomations à saisir en RT 2012 (en W)',
        value: 'title'
    },
    {
        title: 'T.PV',
        value: 'techPerfTPv'
    },
    {
        title: 'PV',
        value: 'techPerfPv'
    },
    {
        title: 'MV',
        value: 'techPerfMv'
    },
    {
        title: 'GV',
        value: 'techPerfGv'
    },
    {
        title: 'Performances acoustique',
        value: 'bigTitle'
    },
    {
        title: 'Puissance acoustique (en dB(A))',
        value: 'acousticPerfPower'
    },
    {
        title: 'Pression acoustique en froid (en dB(A))',
        value: 'title'
    },
    {
        title: 'T.PV',
        value: 'acousticPerfTPv'
    },
    {
        title: 'PV',
        value: 'acousticPerfPv'
    },
    {
        title: 'MV',
        value: 'acousticPerfMv'
    },
    {
        title: 'GV',
        value: 'acousticPerfGv'
    },
    {
        title: 'Débit d\'air (en m3/h) (à saisir en RT 2012)',
        value: 'title'
    },
    {
        title: 'T.PV',
        value: 'acousticPerfTPvAir'
    },
    {
        title: 'PV',
        value: 'acousticPerfPvAir'
    },
    {
        title: 'MV',
        value: 'acousticPerfMvAir'
    },
    {
        title: 'GV',
        value: 'acousticPerfGvAir'
    },
    {
        title: 'Données dimensionnelle',
        value: 'bigTitle'
    },
    {
        title: 'Poids net (en kg)',
        value: 'dimensionalDataWeight'
    },
    {
        title: 'Hauteur (en mm)',
        value: 'dimensionalDataHeight'
    },
    {
        title: 'Longueur (en mm)',
        value: 'dimensionalDataLength'
    },
    {
        title: 'Profondeur (en mm)',
        value: 'dimensionalDataDepth'
    },
    {
        title: 'Données générales',
        value: 'bigTitle'
    },
    {
        title: 'Frigo (Lig / Gaz)',
        value: 'generalDataFridge'
    },
    {
        title: 'Puissance (en CV)',
        value: 'generalDataPower'
    },
    {
        title: 'Pression statique (en PA)',
        value: 'bigTitle'
    },
    {
        title: 'Nom',
        value: 'staticPressureName'
    },
    {
        title: 'Min',
        value: 'staticPressureMin'
    },
    {
        title: 'Max',
        value: 'staticPressureMax'
    },
]

export const oScheme = [
    {
        title: 'Informations générales',
        value: 'bigTitle'
    },
    {
        title: 'Référence',
        value: 'reference'
    },
    {
        title: 'Gamme',
        value: 'orderGroup'
    },
    {
        title: 'Description du produit',
        value: 'perf'
    },
]