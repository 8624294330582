import React from 'react';

const Input = ({ onBlur, placeholder, expanded, className, label, error, value, onChange, centered, type, labelBottom }) => {
    function handleChange(e) {
        e.preventDefault();
        onChange(e.target.value);
    }

    const errored = error ? "is-invalid" : "";
    const center = centered ? "text-center" : "";

    return (
        <div className={"form-group m-0 " + (expanded ? "w-100 " : "") + className}>
            {
                label &&
                <label htmlFor={label + "Input"} className="my-2">{label}</label>
            }
            <input onBlur={onBlur ? onBlur : () => { return; }} type={type || "text"} className={"form-control " + errored + " " + center} value={value} id={label + "Input"} aria-describedby="helpId" onChange={handleChange} placeholder={placeholder}/>
            {
                labelBottom &&
                <label htmlFor={label + "Input"} className="my-2">{labelBottom}</label>
            }
        </div>
    );
};

export default Input;
