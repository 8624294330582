import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavbarUserMenu = ({ history }) => {
    const user = useSelector(state => state.user);
    const [show, setShow] = React.useState(false);

    const onLogout = () => {
        history.push('/logout');
    }

    return (
        <nav className=" ml-auto">
            <ul className="nav align-items-center">
                <li className={"nav-item dropdown " + (show ? 'show' : '')}>
                    <span className="nav-link dropdown-toggle" style={{cursor: 'pointer'}} role="button" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false" onClick={() => setShow(!show)}>
                        <div className="avatar avatar-sm avatar-online">
                                <span className="avatar-title rounded-circle bg-dark">{user.email && user.email[0]}</span>
                        </div>
                    </span>
                    <div className={"dropdown-menu dropdown-menu-right squared" + (show ? ' show' : '')}>
                        <Link to="/mon_profil"><div className="dropdown-item text-primary" style={{cursor: 'pointer'}}> Mon profil</div></Link>
                        <div className="dropdown-item text-primary" onClick={onLogout} style={{cursor: 'pointer'}}> Se déconnecter</div>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default withRouter(NavbarUserMenu);