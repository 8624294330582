export const NEUTRAL_COMPANY_CREATION_SUCCESS = (company_name) => `La nouvelle entreprise à bien été crée comme: ${company_name}.`;

export const NEUTRAL_COMPANY_CREATION_FAIL = 'Une erreur est survenue lors de la création de l\'entreprise...';

export const DELETE_FILE_ERROR = (filename) => `Une erreur est survenue lors de la suppression du fichier: ${filename}`;

export const ON_CHANGE_COLLECTION_NO_DESCRIPTION = 'Veuillez dire pourquoi vous voulez changer de gamme...';

export const CHANGE_COLLECTION_SUCCESS = 'Le changement a bien été pris en compte !';

export const CHANGE_COLLECTION_FAIL = 'Une erreur est survenue lors de la sauvegarde du changement';

export const CHANGE_AMOUNT_PRODUCTS_ERROR = product_name => `Une erreur est survenue lors de la sauvegarde de la quantité d'élements pour le produit: ${product_name}`;

export const SHOW_PRODUCT_DETAILS_ERROR = 'Une erreur est survenue lors de l\'affichage des details de ce produit...';

export const ALREADY_ADDED_PRODUCT_TO_PROJECT = 'Ce produit a déjà été ajouté au projet...';

export const ADD_NEW_PRODUCT_ERROR = product_name => `Une erreur est survenue lors de l'ajout du produit: ${product_name}`;

export const REMOVE_ADDED_PRODUCT_ERROR = product_name => `Une erreur est survenue lors de la suppression du produit: ${product_name}`;

export const FILE_READY_TO_BE_UPLOADED = 'Le fichier est prêt à être téléversé.';

export const CAN_NOT_CREATE_EMPTY_PROJECT = 'Vous ne pouvez pas créer de projets vides...';

export const INVALID_PROJECT_DATES = 'Vérifier bien que les dates soient valides avant de créer le projet...';

export const NO_USERS_ON_PROJECT_ERROR = 'Veuillez selectionnez au moins un cabinet d\'architecte ou un chargé de projet CIC en maîtrise d\'oeuvre, pour créer le projet...';

export const NO_COLLECTION_ON_PROJECT_ERROR = 'Veuillez choisir une gamme pour votre projet...';

export const ADDING_PROJECT_MANAGER_ERROR = 'Une erreur est survenue lors de l\'ajout du chargé de projet CIC au projet';

export const PROJECT_NUMBER_ALREADY_EXISTS = "Ce projet existe déjà... Essayez de changer le nom ou le numéro.";

export const PROJECT_REMAINING_FIELDS_ERROR = "Certains champs ne sont pas remplis, veuillez tout renseigner avant de créer le projet.";

export const USER_EMAIL_ALREADY_IN_USE = 'L\'adresse mail renseignée est déjà utilisée par un autre utilisateur...';

export const ON_CREATE_USER_ERROR = 'Une erreur est survenue lors de la creation de l\'utilisateur...';

export const ON_SAVE_MODIFICATION_STEP0_ERROR = 'Verifiez que toutes les données soient bien remplies lors de la sauvegarde...';

export const ON_SAVE_STEP0_INVALID_DATES = 'Verifiez que les dates soient valides pour sauvegarder les données...';

export const ON_SAVE_STEP0_SUCCESS = "Les données modifiées ont bien été sauvegardées en base de données.";

export const ON_SAVE_STEP0_ERROR = "Une erreur est survenue lors de la sauvegarde des modifications...";

export const ON_DISABLED_PROJECT_SUCCESS = "Le projet est désormais inaccessible...";

export const UNAUTHORIZED_TO_DISABLE_PROJECT = "Vous n'avez pas le droit de supprimer ce projet.";

export const ON_DISABLED_PROJECT_FAIL = "Une erreur est survenue lors de la suppression du projet...";

export const ON_REQUIRED_FIELDS = "Tous les champs suivis d'un (*) doivent être remplis pour continuer...";

export const ON_ADD_ARCHITECT_REFERENT_ERROR = "Une erreur est survenue lors de l'ajout de l'architecte référent...";

export const ON_ARCHITECT_ADDED_SUCCESS = "L'architecte référent à été ajouté !";

export const ON_DOWNLOAD_COLLECTION_FILE_ERROR = 'Une erreur est survenue lors du téléchargement des fichiers de la gamme...';

export const ON_EMPTY_FIELDS_CONCEPTION2 = "Tous les champs sont obligatoires pour confirmer cette étape...";

export const ON_SAVE_CONCEPTION2_SUCCESS = "Les données techniques ainsi que les températures on bien été sauvegardées.";

export const ON_SAVE_CONCEPTION2_FAIL = 'Une erreur est survenue lors de la confirmation de cette étape...';

export const ON_NOT_SAVED_RATIOS_MODIFICATIONS = 'Vous devez sauvegarder les modifications des ratios avant de confirmer...';

export const ON_EMPTY_RATIOS_FIELDS = 'Il est obligatoire de remplir les ratios avant de pouvoir continuer...';

export const ON_NOT_6_REQUIRED_FILES_IMPORTED = "Tous les fichiers demandés doivent être téléversés afin de confirmer l'étape... Vérifier bien que tous aient bien fini d'être téléversés.";

export const ON_CONCEPTION3_VALIDATED_SUCCESS = "L'étape est validée !";

export const ON_PRICE_LIST_NOT_COMPLETED = 'Il est obligatoire de compléter la grille tarifaire avant de continuer.';

export const ON_CONCEPTION3_VALIDATED_FAIL = "Une erreur est survenue lors de la confirmation de l'étape...";

export const ON_DELETE_FILE_ERROR = "Une erreur est survenue lors de la suppression de ce fichier...";

export const ON_SAVE_RATIOS_ERROR = 'Une erreur est survenue lors de la sauvegarde des ratios...';

export const WAIT_UNTIL_SAVE_TO_CONTINUE = 'Attendez la fin de la sauvegarde avant de continuer.';

export const ON_CONFIRM_REJECT_NO_DESCRIPTION = "Pour confirmer le changement vous devez entrer une description de l'action.";

export const ON_CONFIRM_REJECT_FILE_SUCCESS = "Les modifications ont bien été enregistrées. N'oubliez pas d'envoyer les modifications à la fin.";

export const ON_CONFIRM_REJECT_FILE_FAIL = 'Une erreur est survenue lors de la rejette du fichier...';

export const ON_CONFIRM_CONCEPT4_INVALID_DATES = 'Verifiez bien que les dates soient marqués comme valides...';

export const ON_CONFIRM_CONCEPT4_SUCCESS = 'L\'étape "Conception" à bien été confirmée.';

export const ON_CONFIRM_CONCEPT4_FAIL = "Une erreur est survenu lors de la confirmation de l'étape conception...";

export const ON_SEND_MODIFICATION_REJECTED_FILES_SUCCESS = "Vos apportations ont bien été prises en compte et envoyées au prescripteur.";

export const ON_SEND_MODIFICATION_REJECTED_FILES_FAIL = "Une erreur est survenue lors de la prise en compte des modifications.";

export const ON_SAVE_ADDED_COMPANY_ERROR = 'Une erreur est survenue lors de l\'ajout de l\'entreprise...';

export const ON_NO_COMPANY_ADDED_ERROR = 'Vous devez ajouter au moins une entreprise.';

export const ON_COMPANIES_ADDED_SUCCESS = "Les entreprises ont bien été sauvegardées.";

export const ON_COMPANIES_ADDED_FAIL = "Une erreur est survenue lors de la confirmation de cette étape...";

export const ON_SAVE_COMPANY_PRICE_ERROR = (company_name) => `Une erreur est survenue lors de la sauvegarde du prix pour l'entreprise: ${company_name} ...`;

export const ON_INVALID_DATES_CONSULTATION2 = 'Verifiez que la date est bien valide avant de continuer...';

export const ON_CONFIRM_STEP_CONSULTATION2_ERROR = "Une erreur est survenue lors de la confirmation de l'étape consultation...";

export const ON_CONFIRM_SIGNATURE_DATE_SUCCESS = 'La date de signature a bien été confirmée.';

export const ON_SAVE_NEW_SIGNATURE_DATE_SUCCESS = "La nouvelle date de signature a bien été sauvegardée.";

export const ON_SAVE_NEW_SIGNATURE_DATE_FAIL = "Une erreur est survenue lors de la sauvegarde de la date de signature...";

export const ON_CHANGE_COMPANY_STATE_ERROR = 'Une erreur est survenue lors du changement de l\'état de l\'entreprise...';

export const ON_SAVED_COMPANY_STATES = 'L\'entreprise gagnante a bien été validée.';

export const ON_SAVE_COMPANIES_STATES_ERROR = 'Une erreur est survenue lors de la sauvegarde des états des entreprises...';

export const ON_LOOSE_PROJECT = 'Le projet a été perdu, vous allez être redirigé vers la page d\'accueil dans 5 secondes.';

export const ON_SAVE_MODIFICATIONS_BUTTON = 'Les modifications ont bien été sauvegardés.';

export const ON_CHANGE_COMMISSIONING_DATE_ERROR = 'Une erreur est survenue lors de la selection de la date de mise en service...';

export const INVALID_DATE_FINISH_PROJECT = 'Verifiez que la date de mise en service est bien valide avant de terminer le projet...';

export const ON_FINISH_PROJECT = 'Félicitations vous avez finalisé ce projet, il continuera à être disponible avec le badge "Terminé".';

export const ON_FINISH_REDIRECT = 'Vous allez être redirigé vers la page d\'accueil dans 5 secondes.';

export const ON_FINISH_PROJECT_ERROR = 'Une erreur est survenue lors de la confirmation et la finalisation du projet';

export const ON_UNEXISTING_PROJECT = 'Ce projet n\'existe plus, vous allez être redirigé vers la page d\'accueil...';

export const ON_DISABLED_PROJECT_CAN_NOT_ACCESS = 'Ce projet n\'est plus accessible... Vous allez être redirigé vers la page d\'accueil';

export const ON_SAVED_PROFILE_DATA_SUCCESS = "Vos données on bien été sauvegardées !";

export const ON_PASSWORD_MODIFIED = 'Votre mot de passe à bien été modifié ! Vous allez être redirigé dans quelques instants...';

export const ON_DOWNLOAD_FILE_ERROR = filename => `Une erreur est survenue lors du téléchargement du fichier: ${filename}`;

export const ON_FINISH_PROJECT_WITHOUT_PRODUCTS = 'Vous devez choisir au moins un produit par catégorie pour pouvoir finaliser le projet.';

export const ON_FINISH_PROJECT_WITHOUT_FILES = 'Vous devez téléverser les fichiers demandés avant de pourvoir continuer...';

export const AN_ERROR_OCCURED = 'Une erreur est survenue, vérifiez que toutes les données soient bien remplies.';

export const CAN_T_CONFIRM_WITH_REJECTED_FILES = 'Vous ne pouvez pas confirmer cette étape tant que vous n\'avez pas modifié les fichiers rejectés par l\'architecte.';

export const FULLFILL_PRICE_LIST_BEFORE_FINISH_PROJECT = 'Vous devez compléter la grille tarifaire avant de pouvoir finaliser le projet...';

export const UNABLE_TO_REMOVE_PROJECT = 'Le projet est trop avancé pour supprimer le projet, cette option est désormais désactivée...';

export const SAVE_PRODUCT_MODIFICATION_BEFORE_CONTINUE = 'Sauvegardez les modifications apportés sur les produits avant de continuer...';

export const ON_CONFIRM_EXECUTION_STEP = 'L\'étape à bien été confirmée... Vous ne pourrez compléter l\'étape suivante qu\'après la date de mise en service.';

export const ON_CONFIRM_EXECUTION_STEP_FAIL = 'La confirmation de l\'étape a échouée...';

export const ON_CONFIRM_CONCEPT4_REJECTED_FILES = 'Vous ne pouvez pas confirmer l\'étape tant que des fichiers sont rejetés...';

export const FILL_ISOLATION_FORM_BEFORE_CONTINUATION = 'Vous devez remplir le formulaire d\'isolation avant de pouvoir continuer';

export const ERROR_ON_SET_A_NEGOCIATION = 'Une erreur est survenue lors de l\'ajout d\'une nouvelle négociation.';
