import React from 'react';
import HistoryFeedStructure from './history-feed-structure';
import HistoryFeedContent from './history-feed-content';

const HistoryFeed = () => {
    return (
        <HistoryFeedStructure>
            <div className="card-title mb-2 text-primary font-weight-bold">Historique</div>
            <HistoryFeedContent />
        </HistoryFeedStructure>
    );
};

export default HistoryFeed;