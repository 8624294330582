import React from 'react';
import NavbarStructure from './navbar-structure';
import NavbarUserMenu from './navbar-user-menu';

const NavBar = () => {
    return (
        <NavbarStructure>
            <NavbarUserMenu/>
        </NavbarStructure>
    );
};

export default NavBar;