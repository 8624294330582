import React from 'react';
import { Input } from 'components/form/input';
import { Button } from 'components/form/button';
import { useSelector, useDispatch } from 'react-redux';
import api, { API_SET_REFERENT_ARCHITECT, API_GET_REFERENT_ARCHITECT } from 'utils/api';
import { NAME_STEP_CONCEPTION_2 } from 'utils/constants';
import { setCurrentStep } from 'actions/steps-actions';
import { notify, notify_error } from 'utils/utils';
import { ON_REQUIRED_FIELDS, ON_ARCHITECT_ADDED_SUCCESS, ON_ADD_ARCHITECT_REFERENT_ERROR } from 'utils/notifications';
import useNumerical from 'hooks/use-numerical';

let loading = false;

const getArchitectData = async (config, project_id, cb) => {
    let projectArchiRef = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
    }

    await api.get(API_GET_REFERENT_ARCHITECT(project_id), config)
    .then(res => {
        projectArchiRef = {
            first_name: res.data.firstName,
            last_name: res.data.lastName,
            phone: res.data.phone,
            email: res.data.email
        }
    })
    .catch(err => {
        console.error('Get architect data ERROR: ', err);
    })

    cb({
        type: 'ARCHITECT',
        ...projectArchiRef
    })
}

const StepConception1 = ({ CanConfirm }) => {
    const user = useSelector(state => state.user);
    const config = useSelector(state => state.apiR);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const dispatch = useDispatch();
    const [architect, setArchitect] = React.useState("");
    const [error, setError] = React.useState({});
    const [errored, onValue] = useNumerical();

    if (architect === "" && !loading) {
        loading = true;
        getArchitectData(config, project_id, (value) => {
            setArchitect(value);
            loading = false;
        })
    }

    function onSubmit() {
        let errored = false;

        for (var i = 0; architect && i < Object.keys(architect).length; i++) {
            if (architect[Object.keys(architect)[i]] === '') {
                errored = true;
                setError({...error, [Object.keys(architect)[i]]: true});
            }
        }

        if (errored) {
            notify_error(ON_REQUIRED_FIELDS)
            return;
        }

        api.post(API_SET_REFERENT_ARCHITECT(project_id), architect, config)
        .then(res => {
            notify(ON_ARCHITECT_ADDED_SUCCESS);
            dispatch(setCurrentStep({current_step: NAME_STEP_CONCEPTION_2, active: 'Conception'}));
        })
        .catch(err => {
            if (err.response && err.response.status === 409) {
                notify_error('Un utilisateur extistant utilise déjà cet addresse mail...');
                console.error('Set referent architect ERROR: ', err);
                return;
            }
            notify_error(ON_ADD_ARCHITECT_REFERENT_ERROR)
            console.error('Set referent architect ERROR: ', err);
        })
    }

    function handleChange(value, name) {
        if (name === 'phone') {
            if (onValue(value, name)) {
                setError({...error, [name]: true,})
                return;
            }
        }

        if (error[name]) {
            setError({...error, [name]: false});
        }

        setArchitect({
            ...architect,
            [name]: value,
        })
    }

    function onAutofill() {
        setArchitect({
            first_name: user.firstName,
            last_name: user.lastName,
            phone: user.phone,
            email: user.email,
            type: 'ARCHITECT_FIRM'
        })
    }

    return (
        <div id="ConceptionDonneesReferent">
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="text-primary m-0">Architecte / personne suivant le projet</h4>
                <Button outlined tooltip={{placement: 'top', content: 'Autocompletion avec mes données'}} bg_color="dark" button_title="Identique à mon compte" onClick={onAutofill} />
            </div>
            <div className="row px-2 pb-3">
                <Input error={error.first_name} value={architect.first_name || ""} onChange={(value) => handleChange(value, 'first_name')} placeholder="Prénom *" label="Prénom *" expanded className="col-12 col-sm-6" />
                <Input error={error.last_name} value={architect.last_name || ""} onChange={(value) => handleChange(value, 'last_name')} placeholder="Nom *" label="Nom *" expanded className="col-12 col-sm-6" />
                <Input error={error.email} value={architect.email || ""} onChange={(value) => handleChange(value, 'email')} placeholder="Email *" label="Email *" expanded className="col-12 col-sm-6" />
                <Input error={errored.phone} value={architect.phone || ""} onChange={(value) => handleChange(value, 'phone')} placeholder="Téléphone *" label="Téléphone *" expanded className="col-12 col-sm-6" />
            </div>
            {
                CanConfirm &&
                <div className="d-flex justify-content-end">
                    <Button bg_color="primary" button_title="Confirmer" onClick={onSubmit} />
                </div>
            }
        </div>
    );
};

export default StepConception1;