import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Stepper } from 'components/stepper';
import { Button } from 'components/form/button';
import { toggleHistoryDrawer } from 'actions/steps-actions';
import { openPriceList, getUserRights } from 'utils/utils';
import { PRESCRIBER_ID, NATIONAL_CIC_ID } from 'utils/constants';

const StepsPageHeader = ({ toggleHistoryDrawer, project_id }) => {
    const user_position = useSelector(state => state.user.position);

    function toggleHistory() {
        const history_viewer = document.querySelector('#HistoryViewer');
        const history_button = document.querySelector('#toggleHistory');
        const step_header_grid = document.querySelector('#stepHeaderGrid');
        if (window.innerWidth <= 1480) {
            toggleHistoryDrawer(true);
        } else {
            history_button.classList.toggle('active');
            history_viewer.classList.toggle('d-none');
            step_header_grid.classList.toggle('col-12');
            step_header_grid.classList.toggle('col-lg-8');
            step_header_grid.classList.toggle('col-md-10');
        }
    }

    return (
        <div id="stepHeaderGrid" className="col-lg-8 col-md-10">
            <div className="d-flex justify-content-center align-items-center justify-content-md-between flex-column flex-md-row w-100">
                <div className="d-flex justify-content-md-start justify-content-center">
                    <Stepper />
                </div>
                <div className="d-flex justify-content-md-end justify-content-center">
                    {
                        getUserRights(user_position, [PRESCRIBER_ID, NATIONAL_CIC_ID]) &&
                        <Button
                            bg_color="primary"
                            button_title="Grille tarifaire"
                            className="mt-3 mt-md-0 mr-2"
                            id="toggleTarifaire"
                            prefix={
                                <i className="mdi mdi-view-list mr-1" style={{fontSize: 20}}></i>
                            }
                            onClick={() => openPriceList(project_id)}
                        />
                    }
                    <Button
                        outlined
                        bg_color="primary"
                        button_title="Historique"
                        className="mt-3 mt-md-0"
                        prefix={
                            <i className="mdi mdi-email mr-1"style={{fontSize: 20}}></i>
                        }
                        id="toggleHistory"
                        onClick={toggleHistory}
                        />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    toggleHistoryDrawer: payload => dispatch(toggleHistoryDrawer(payload)),
})

export default connect(null, mapDispatchToProps)(StepsPageHeader);
