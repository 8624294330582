import React from 'react';

const ProfilePageStructure = ({ children }) => {
    const [presentation, personal_info, confirm] = children;

    return (
        <div className="container pull-up">
            <div className="card squared border">
                <div className="card-header">
                    {presentation}
                </div>
                <hr className="pt-3 mb-0" />
                <div className="card-body pb-3">
                    {personal_info}
                    <div className="ml-auto">
                        {confirm}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePageStructure;