import React from 'react';
import PriceListRow from '../price-list-element/price-list-row';
import { Button } from 'components/form/button';
import PriceListColumnStructure from './price-list-column-structure';
import DeriveAdder from './derive-adder';

const PriceListColumn = ({ data, i, title, removeDerivation, currentStatus, component, onNewDerivation, lastStep, disabled, currentStepUniq }) => {
    const [addDerive, setAddDerive] = React.useState(false);

    const handleNewDerivation = () => {
        if (!disabled) {
            if (onNewDerivation) {
                onNewDerivation();
            }
            setAddDerive(false);
        }
    }

    return (
        <PriceListColumnStructure i={i}>
            {title}
            {
                data ?
                (data.length === 1 ?
                <PriceListRow data={data[0]} className="px-2" type={title} disabled={disabled} currentStepUniq={currentStepUniq} />
                :
                data.map((element, i) => (
                    <div className={`${i <= data.length - 2 ? "border-bottom pb-3" : ""}`}>
                        {
                            i >= 1 &&
                            <h5 className="py-3 border-bottom font-weight-bolder">Dérive de la gamme</h5>
                        }
                        <PriceListRow type={title} data={element} className="px-2" disabled={disabled} currentStepUniq={currentStepUniq} />
                    </div>
                )))
                :
                component &&
                <>
                    {component}
                </>
            }
            <>
                {
                    !addDerive && lastStep ?
                    <Button
                        bg_color="primary"
                        button_title="Ajouter une dérive"
                        extended
                        disabled={disabled}
                        onClick={() => setAddDerive(true)}
                    />
                    :
                    lastStep &&
                    <DeriveAdder cancelAction={() =>{if (!disabled) setAddDerive(false)}} onGammeAdded={handleNewDerivation} />
                }
                {
                    data && data.length > 1 && lastStep &&
                    <Button
                      disabled={disabled}
                        bold
                        outlined
                        bg_color="primary"
                        button_title="Supprimer la dérive"
                        prefix={
                            <i className="mdi mdi-delete mr-2" style={{fontSize: 20}}></i>
                        }
                        extended
                        onClick={removeDerivation}
                    />
                }
            </>
        </PriceListColumnStructure>
    );
};

export default PriceListColumn;
