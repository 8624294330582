import React from 'react';
import PropTypes from 'prop-types';
import IsolationFormOptionCheckbox from './isolation-form-option-checkbox';
import IsolationFormOptionInput from './isolation-form-option-input';

const IsolationFormOptionCheckInput = ({ title, checkboxVal, inputVal, onChange, disabled }) => {
    const [values, setValues] = React.useState({
        value: checkboxVal,
        input: inputVal
    });

    React.useEffect(() => {
        setValues({
            value: checkboxVal,
            input: inputVal
        });
    }, [checkboxVal, inputVal]);

    const handleChange = (value, name) => {
        if (!disabled) {
            const newValues = {
                ...values,
                [name]: value,
            }

            setValues(newValues);

            if (onChange) {
                onChange(newValues);
            }
        }
    }

    return (
        <div className="d-flex align-items-center">
            <IsolationFormOptionCheckbox title={title} checkboxVal={values.value} onChange={(value) => handleChange(value, 'value')} />
            <div className="pl-2">
                <IsolationFormOptionInput inputSimulate={true} inputVal={values.input} onChange={(value) => handleChange(value, 'input')} />
            </div>
        </div>
    );
};

IsolationFormOptionCheckInput.propTypes = {
    title: PropTypes.string.isRequired,
    checkboxVal: PropTypes.bool.isRequired,
    inputVal: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
export default IsolationFormOptionCheckInput;
