import React from 'react';

const ConnectionStructure = ({ children }) => {
    return (
        <div className="container-fluid">
            <div className="row ">
                <div className="col-xl-4 col-md-8 shadow-lg bg-white">
                    <div className="row align-items-center m-h-100">
                        <div className="mx-auto col-md-8 my-5">
                            <div className="p-b-50 text-center">
                                <img src="../assets/img/FA_HITACHI_BLA_RGB.png" width="200rem" alt="" />
                            </div>
                            {children[0]}
                        </div>
                    </div>
                </div>
                {children[1]}
            </div>
        </div>
    );
};

export default ConnectionStructure;