import React from 'react';
import { useSelector } from 'react-redux';
import api, { API_AUTOCOMPLETE_ADDRESS } from 'utils/api';

const useAutocompleteDate = () => {
    const [cities, setCities] = React.useState([]);
    const [departments, setDepartments] = React.useState([]);
    const config = useSelector(state => state.apiR);

    const handleAddressCompletion = zip_code => {
        if (zip_code === '') {
            setCities([]);
            setDepartments([]);
            return;
        }

        api.post(API_AUTOCOMPLETE_ADDRESS, {filter: zip_code}, config)
        .then(res => {
            const departements = res.data.map(el => el.departmentRegion).filter((value, index, self) => self.indexOf(value) === index);

            setCities([ ...res.data.map(el => ({ label: el.name, value: el.id, zipCode: el.zipCode.replace('.0', ''), ...el })) ]);
            setDepartments([ ...departements.slice(0, 30).map((el, i) => ({ label: el, value: i })) ]);
        })
        .catch(err => {
            console.error('Autocomplete address by zip_code ERROR: ', err);
        })
    }

    const filterDepartments = (city_id) => {
        let selectedCity = cities.filter(el => el.id === city_id)[0];

        if (!selectedCity) {
            return;
        }

        setCities([...cities.filter(el => el.departmentRegion === selectedCity.departmentRegion)])
        setDepartments([{ label: selectedCity.departmentRegion, value: 0 }]);
    }

    return [cities, departments, handleAddressCompletion, filterDepartments];
};

export default useAutocompleteDate;