import React from 'react';

const SidebarFooter = () => {
    return (
        <div className="text-center py-4">
            <img src='/assets/img/FA_HITACHI_Tagline_NEG_RGB.png' alt="Cooling & Heating" className="w-50" />
        </div>
    );
};

export default SidebarFooter;