import React from 'react';
import MySelect from 'components/form/select/select';

const ProjectCreateFormsCollection = ({ onSave }) => {
    const onSelect = (select) => {
        onSave({
            collection_id: select.value,
        })
    }

    return (
        <>
            <h4 className="text-primary m-0">Gamme pré-sélectionnée</h4>
            <div className="px-2">
                <MySelect
                    selectElements='collections'
                    placeholder="Pré-sélectionnez une gamme"
                    label="Pré-sélectionnez une gamme"
                    className="m-0"
                    noOptionsLabel={() => "Aucune gamme n'existe sous ce nom"}
                    onSelect={onSelect}
                />
            </div>
        </>
    );
};

export default ProjectCreateFormsCollection;