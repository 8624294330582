import React from 'react';

const SimpleCardStructure = ({ extended, pulled_up, children }) => {
    const topClass = "container" + (extended ? "-fluid px-xl-6 " : " ") + (pulled_up ? "pull-up" : "")
    const [card_header, card_content] = children;

    return (
        <div className={topClass}>
            <div className="row">
                <div className="col-12">
                    <div className="card squared p-3">
                        <div className="card-header">
                            {card_header}
                            <hr className="my-0"></hr>
                        </div>
                        <div className="card-body pt-0">
                            {card_content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SimpleCardStructure;