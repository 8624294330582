import React from 'react';

const PriceListColumnStructure = ({ children, i }) => {
    const [columnTitle, columnContent, columnFooter] = children
    return (
        <div className={`col-12 col-xl-2 col-md-${i < 3 ? 4 : 6} p-2`}>
            <div className="card border squared">
                <div className="card-header text-capitalize font-weight-bold pb-0 column-title">
                    {columnTitle}
                    <hr className="mb-0"></hr>
                </div>
                <div className="card-body">
                    {columnContent}
                </div>
                <div className="card-footer p-0 border-0 mt-2">
                    {columnFooter}
                </div>
            </div>
        </div>
    );
};

export default PriceListColumnStructure;