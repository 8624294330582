import React from 'react';
import ConnectionStructure from '../connection-structure';
import LoginForm from 'components/connection/login/login-form';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Login = () => {
    const config = useSelector(state => state.apiR);

    return (
        <ConnectionStructure>
            {
                config.headers.Authorization ?
                <Redirect to="/" />
                :
                <LoginForm />
            }
            <div className="col-xl-8 col-md-4 d-none d-md-block bg-cover bg-primary" style={{backgroundImage: "url('assets/img/background_image_login.jpg')"}}></div>
        </ConnectionStructure>
    );
};

export default Login;