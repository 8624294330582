import React from 'react';
import Datatable from 'react-data-table-component';

const theme = {
    title: {
        fontColor: '#C3002F'
    }
}

const findStepTitle = (title) => {
    switch(title) {
        case 'ordered':
            return 'Commandé';
        case 'negotiated':
            return 'Négocié';
        default:
            return title;
    }
}

const columnsList = [
    {
        name: "Etape",
        selector: "step",
        format: el => <span className="text-capitalize">{findStepTitle(el.step.replace('Quotes', ''))}</span>
    },
    {
        name: "Prix",
        selector: "price",
    },
    {
        name: "Remise Accessoires (%)",
        cell: (element) => <span>{element.accessories}</span>
    },
    {
        name: "Remise Petit tertiaire (%)",
        cell: (element) => <span>{element.smallTertiary}</span>
    },
    {
        name: "Remise Mono/Multi (%)",
        cell: (element) => <span>{element.monoMulti}</span>
    },
    {
        name: "Remise DRV (%)",
        cell: (element) => <span>{element.drv}</span>
    }
]

const PriceListDatatableExpandContent = ({ data, className }) => {
    const emptyData = "Aucune donnée n'a pu être trouvé."

    return (
        <div className={className}>
            <Datatable
                customTheme={theme}
                title={data.collection && data.collection.libellus}
                columns={columnsList}
                data={data.status}
                noDataComponent={emptyData}
                highlightOnHover
                pointerOnHover
                className="text-primary"
            />
        </div>
    );
};

export default PriceListDatatableExpandContent;