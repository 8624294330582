import React from 'react';
import TextArea from 'components/form/input/textarea';
import { FileInputDropzone } from 'components/form/dropzone';
import { NAME_TYPE_FILE_HISTORY_POST } from 'utils/constants';
import { notify } from 'utils/utils';
import { FILE_READY_TO_BE_UPLOADED } from 'utils/notifications';

export const HistoryFormFile = ({ returnValue, value, error }) => {
    const [inputValue, setInputValue] = React.useState('');
    const [files, setFiles] = React.useState([]);

    if (value === 'resetData' && inputValue !== '') {
        setInputValue('');
    }

    const handleFilesChanges = async (files) => {
        notify(FILE_READY_TO_BE_UPLOADED);
        setFiles(files);
        returnValue({
            content: inputValue,
            file_name: files.file_name,
            data: files.data,
        })
    }

    const handleChange = (value) => {
        setInputValue(value);
        returnValue({
            content: value,
            file_name: files.file_name,
            data: files.data,
        })
    }

    return (
        <div id="file-input">
            <FileInputDropzone attachment_box_type='not_defined' type={NAME_TYPE_FILE_HISTORY_POST} history={true} onSubmit={handleFilesChanges} placeholder=" Faire glissez les fichiers à envoyer." button_title="Téléverser un fichier"/>
            <div className="mt-4">
                <TextArea error={error} placeholder="Votre message..." value={inputValue} onChange={handleChange} />
            </div>
        </div>
    );
};

export const HistoryFormMail = ({ returnValue, value, error }) => {
    const [inputValue, setInputValue] = React.useState('')
    const [files, setFiles] = React.useState();

    if (value === 'resetData' && inputValue !== '') {
        setInputValue('');
    }

    const handleFilesChanges = (files) => {
        notify(FILE_READY_TO_BE_UPLOADED);
        setFiles(files);
        returnValue({
            content: inputValue,
            data: files.data,
            file_name: files.file_name,
        })
    }

    const handleChange = (value) => {
        setInputValue(value);
        returnValue({
            content: value,
            data: files.data,
            file_name: files.file_name,
        })
    }

    return (
        <div id="mail-input">
            <FileInputDropzone attachment_box_type='not_defined' type={NAME_TYPE_FILE_HISTORY_POST} history={true} onSubmit={handleFilesChanges} placeholder="Faire glissez le fichier mail (.eml) à envoyer." button_title="Téléverser un fichier"/>
            <div className="mt-4">
                <TextArea error={error} placeholder="Votre message..." value={inputValue} onChange={handleChange} />
            </div>
        </div>
    );
};

export const HistoryFormText = ({ returnValue, value, error }) => {
    const [inputValue, setInputValue] = React.useState('')

    if (value === 'resetData' && inputValue !== '') {
        setInputValue('');
    }

    const handleChange = (value) => {
        setInputValue(value);
        returnValue({
            content: value
        });
    }

    return (
        <div id="text-input">
            <TextArea error={error} placeholder="Votre message..." value={inputValue} onChange={handleChange} />
        </div>
    );
};