import React from 'react';
import PageHeaderStructure from 'components/header/page-header/page-content-header';
import { SimpleCardStructure } from 'components/card/simple-card';
import { ProjectCreateForm } from 'components/project/project-create-form';
import TitleHeader from 'components/header/title-header';

const ProjectCreate = () => {
    return (
        <>
            <PageHeaderStructure>
            </PageHeaderStructure>
            <SimpleCardStructure extended pulled_up>
                <TitleHeader title="Nouveau projet" />
                <ProjectCreateForm />
            </SimpleCardStructure>
        </>
    );
};

export default ProjectCreate;