import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'components/form/button';
import api, { API_GET_COMPANIES_STATE, API_SET_COMPANY_STATE, API_CONFIRM_COMPANY_STATE } from 'utils/api';
import { notify_error, notify } from 'utils/utils';
import { confirmAlert } from 'react-confirm-alert';
import { setCurrentStep } from 'actions/steps-actions';
import { ON_CHANGE_COMPANY_STATE_ERROR, ON_SAVED_COMPANY_STATES, ON_LOOSE_PROJECT, ON_SAVE_COMPANIES_STATES_ERROR, ON_SAVE_MODIFICATIONS_BUTTON } from 'utils/notifications';

const partner_company_id = 'partner';
const neutral_company_id = 'neutral';

const CompanyLine = ({ company, type, winner, onWinner }) => {
    const [companyData, setCompanyData] = React.useState(company);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);

    React.useEffect(() => {
        setCompanyData(company);
    }, [company])

    const onSelectElement = (value) => {
        let body = {
            company_id: companyData.company.id,
            company_state: 'winner'
        }

        api.post(API_SET_COMPANY_STATE(project_id), body, config)
        .then(res => {
            onWinner(companyData.company.id);
        })
        .catch(err => {
            notify_error(ON_CHANGE_COMPANY_STATE_ERROR)
            console.error('Change the state of the company ' + companyData.company.id + ' ERROR: ', err);
        })
    }

    return (
        <div className="company-line row d-flex align-items-center justify-content-between">
            <div className="col-2 d-flex justify-content-center align-items-center">
                <div className="p-2" onClick={onSelectElement}>
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className={`text-${(companyData.company && winner === companyData.company.id) ? 'primary' : 'muted'}`} style={{width: 15, height: 15, cursor: 'pointer'}} version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 510 510"><g>
                        <g id="radio-button-on"><path
                            fill="currentColor"
                            d={`M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5
                            ${ (companyData.company && winner === companyData.company.id) ? '    C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z    ' : ''}
                            M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z`}
                        ></path></g>
                    </g></svg>
                </div>
            </div>
            <span className="col-5">{companyData.company.siret} - {companyData.company.name}</span>
            <span className="col-5 d-flex">{type}{(companyData.company && winner === companyData.company.id) ? <p className="text-primary ml-auto mr-4 mb-0">Gagnante</p> : ''}</span>
        </div>
    )
}

const StepExecution1 = ({ CanConfirm, history }) => {
    const [companies, setCompanies] = React.useState({
        [partner_company_id]: [],
        [neutral_company_id]: []
    })
    const [winner, setWinner] = React.useState(0);
    const [isModified, setModified] = React.useState(false);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const config = useSelector(state => state.apiR);
    const dispatch = useDispatch();
    let timer = null;

    React.useEffect(() => {
        api.get(API_GET_COMPANIES_STATE(project_id), config)
        .then(res => {
            setCompanies({
                [partner_company_id]: res.data[partner_company_id + '_companies'].map(el => {
                    if (el.companyState && el.companyState.libellus === 'winner') {
                        setWinner(el.company.id);
                    }
                    return el;
                }),
                [neutral_company_id]: res.data[neutral_company_id + '_companies'].map(el => {
                    if (el.companyState && el.companyState.libellus === 'winner') {
                        setWinner(el.company.id);
                    }
                    return el;
                }),
            })
        })
        .catch(err => {
            console.error('Get added companies to project ERROR: ', err);
        })
    }, [project_id, config])

    React.useEffect(() => {
        return () => clearTimeout(timer);
    }, [timer]);

    const onlyOneWinner = () => {
        if (winner === 0) {
            confirmAlert({
                title: 'Voulez-vous vraiment sauvegarder les états des entreprises ?',
                message: 'Aucune entreprise n\'a été marquée gagnante, le projet sera donc perdu.',
                buttons: [
                    {
                        label: 'Continuer',
                        onClick: () => setProjectLooser()
                    },
                    {
                        label: 'Annuler',
                    }
                ]
            });
            return false;
        }
        return true;
    }

    const confirmCompany = () => {
        if (!onlyOneWinner()) {
            return;
        }
        onSubmitChanges(() => {
            notify(ON_SAVED_COMPANY_STATES);
        })
    }

    const onSubmitChanges = (cb) => {
        const body = new FormData();

        const companies_list =  [...companies[neutral_company_id], ...companies[partner_company_id]];

        body.append('project_companies', JSON.stringify(companies_list.map(el => {
            if (el.company.id === winner) {
                return [el.company.id, 'winner'];
            }
            return [el.company.id, el.companyState && el.companyState.libellus]
        })))

        api.post(API_CONFIRM_COMPANY_STATE(project_id), body, config)
        .then(res => {
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
            if (cb) {
                cb();
            }
        })
        .catch(err => {
            notify_error(ON_SAVE_COMPANIES_STATES_ERROR);
            console.error('Confirm company states ERROR: ', err);
        })
    }

    const setProjectLooser = () => {
        if (winner) {
            let body = {
                company_id: winner,
                company_state: 'in_consultation'
            }

            setCompanies({
                [neutral_company_id]: companies[neutral_company_id].map(el => {
                    if (el.companyState && el.companyState.libellus === 'winner') {
                        return {
                            ...el,
                            companyState: {
                                libellus: 'in_consultation',
                            }
                        };
                    }
                    return el;
                }),
                [partner_company_id]: companies[partner_company_id].map(el => {
                    if (el.companyState && el.companyState.libellus === 'winner') {
                        return {
                            ...el,
                            companyState: {
                                libellus: 'in_consultation',
                            }
                        };
                    }
                    return el;
                })
            })

            api.post(API_SET_COMPANY_STATE(project_id), body, config)
            .then(res => {
                setWinner(0);
                onSubmitChanges(() => {
                    notify(ON_LOOSE_PROJECT);
                    setTimeout(() => history.push('/'), 5000);
                })
            })
            .catch(err => {
                notify_error(ON_CHANGE_COMPANY_STATE_ERROR)
                console.error('Change the state of the company ' + winner + ' ERROR: ', err);
            })
        } else {
            onSubmitChanges(() => {
                notify(ON_LOOSE_PROJECT);
                setTimeout(() => history.push('/'), 5000);
            })
        }
    }

    const onWinner = (company_id) => {
        setModified(true);
        setWinner(company_id);
        setCompanies({
            [partner_company_id]: companies[partner_company_id].map(el => {
                if (el.company.id === company_id) {
                    return {
                        ...el,
                        companyState: {
                            libellus: 'winner',
                        }
                    };
                }
                if (el.companyState.libellus === 'winner') {
                    return {
                        ...el,
                        companyState: {
                            libellus: 'in_consultation',
                        }
                    }
                }
                return el;
            }),
            [neutral_company_id]: companies[neutral_company_id].map(el => {
                if (el.company.id === company_id) {
                    return {
                        ...el,
                        companyState: {
                            libellus: 'winner',
                        }
                    };
                }
                if (el.companyState.libellus === 'winner') {
                    return {
                        ...el,
                        companyState: {
                            libellus: 'in_consultation',
                        }
                    }
                }
                return el;
            }),
        })
    }

    return (
        <>
            <h4 className="text-primary">Sélectionnez l'entreprise gagnante</h4>
            <div className="px-3">
                {
                    companies[partner_company_id].length === 0 && companies[neutral_company_id].length === 0 &&
                    <p className="text-muted">Aucune entreprise n'a encore été rajoutée</p>
                }
                {
                    companies[partner_company_id].map((element, i) => (
                        <CompanyLine onWinner={onWinner} winner={winner} company={element} type="Partenaire" key={i} />
                    ))
                }
                {
                    companies[neutral_company_id].map((element, i) => (
                        <CompanyLine onWinner={onWinner} winner={winner} company={element} type="Neutre" key={i} />
                    ))
                }
            </div>
            <div className="d-flex justify-content-end pt-2">
                {
                    CanConfirm &&
                    <>
                        <Button
                            button_title='Aucune entreprise gagnante'
                            bg_color="dark"
                            outlined
                            onClick={() => {
                                confirmAlert({
                                    title: 'Voulez-vous vraiment sauvegarder les états des entreprises ?',
                                    message: 'Aucune entreprise n\'a été marquée gagnante, le project sera donc perdu.',
                                    buttons: [
                                        {
                                            label: 'Continuer',
                                            onClick: () => setProjectLooser()
                                        },
                                        {
                                            label: 'Annuler',
                                        }
                                    ]
                                })
                            }}
                        />
                        {
                            isModified &&
                            <Button
                                button_title="Sauvegarder les modifications"
                                bg_color="dark"
                                className="ml-2"
                                onClick={() => {
                                    notify(ON_SAVE_MODIFICATIONS_BUTTON);
                                    setModified(false);
                                }}
                            />
                        }
                        <Button
                            button_title="Confirmer"
                            bg_color="primary"
                            className="ml-2"
                            onClick={confirmCompany}
                        />
                    </>
                }
            </div>
        </>
    );
};

export default withRouter(StepExecution1);