const steps = {
    completed: 0,
    active: 1,
    current_step: '',
    project_id: null,
    pane_open: false,
    collection: "",
    collections: [],
    dates: {}
}

export default (state = steps, action) => {
    switch (action.type) {
        case "NAVIGATION_STEPPER":
            return {
                ...state,
                active: action.payload,
            }
        case "OPEN_HISTORY_VIEW_PANE":
            return {
                ...state,
                pane_open: action.payload
            }
        case "CURRENT_PROJECT_ID":
            return {
                ...state,
                project_id: action.payload
            }
        case "CURRENT_PROJECT_STEP":
            return {
                ...state,
                current_step: action.payload.current_step,
                active_status: action.payload.status,
            }
        case "CURRENT_PROJECT_COLLECTION":
            return {
                ...state,
                collection: action.payload,
            }
        case "SET_COLLECTIONS":
            return {
                ...state,
                collections: action.payload
            }
        case 'SET_PROJECT_DATES':
            return {
                ...state,
                dates: {
                    start_consultation: action.payload.startDate || state.dates.start_consultation,
                    end_consultation: action.payload.endDate || state.dates.end_consultation,
                    signature: action.payload.signatureDate || state.dates.signature,
                    comissioning: action.payload.commissioningDate || state.dates.comissioning,
                }
            }
        case 'SET_PROJECT_PRICE':
            return {
                ...state,
                current_price: action.payload,
            }
        case 'SET_PROJECT_RATIOS':
            return {
                ...state,
                ratios: {
                    ratio_hot: action.payload.ratioHot,
                    ratio_cold: action.payload.ratioCold,
                }
            }
        case 'SET_ISOLATION_FORM':
            return {
                ...state,
                isolation_form: action.payload,
            }
        default:
            return state;
    }
}