import React from 'react';
import { Button } from 'components/form/button';
import MySwitch from 'components/form/button/switch';
import { TextArea } from 'components/form/input';
import { Datepicker } from 'components/form/datepicker';
import api, { API_CONFIRM_STEP_CONCEPTION, API_GET_FILES_FROM_API, API_SUBMIT_ALL_CHANGES_FOR_FILES, API_SUBMIT_CHANGE_ON_FILE } from 'utils/api';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentStep, navigateSteps } from 'actions/steps-actions';
import { NAME_TYPE_FILE_CONCEPTION_3, defaultTimePicked } from 'utils/constants';
import { notify_error, notify, onDownloadFile } from 'utils/utils';
import { ON_CONFIRM_REJECT_NO_DESCRIPTION, ON_CONFIRM_REJECT_FILE_SUCCESS, ON_CONFIRM_REJECT_FILE_FAIL, ON_CONFIRM_CONCEPT4_INVALID_DATES, ON_CONFIRM_CONCEPT4_SUCCESS, ON_CONFIRM_CONCEPT4_FAIL, ON_SEND_MODIFICATION_REJECTED_FILES_SUCCESS, ON_SEND_MODIFICATION_REJECTED_FILES_FAIL, WAIT_UNTIL_SAVE_TO_CONTINUE, ON_CONFIRM_CONCEPT4_REJECTED_FILES } from 'utils/notifications';
var moment = require('moment');

const FileDownloaderConfirm = ({ element, key, onRejected }) => {
    const [switchState, setSwitchState] = React.useState(element.rejected);
    const [changeDescription, setChangeDescription] = React.useState('');
    const [isConfirmed, setIsConfirmed] = React.useState(element.rejected);
    const [error, setError] = React.useState(false);
    const config = useSelector(state => state.apiR);
    const project_id = useSelector(state => state.stepNavigation.project_id);

    React.useEffect(() => {
        if (element.rejected) {
            onRejected(true);
        }
    }, [element.rejected, onRejected])

    function onChange (value) {
        if (isConfirmed) {
            api.post(API_SUBMIT_CHANGE_ON_FILE(project_id), {file_name: element.realName}, config)
            .then(res => {
                setChangeDescription('');
                setIsConfirmed(false);
                onRejected(-1);
            })
            .catch(err => {
                console.error('On unreject file ERROR: ', err);
            })
        }
        setSwitchState(!value);
    }

    const handleChange = (value) => {
        if (isConfirmed) {
            setIsConfirmed(false);
        }
        setError(false);
        setChangeDescription(value);
    }

    const onConfirmChange = () => {
        const body = {
            file_name: element.realName,
            content: changeDescription
        }

        if (body.content === '') {
            notify_error(ON_CONFIRM_REJECT_NO_DESCRIPTION);
            setError(true);
            return;
        }

        api.post(API_SUBMIT_CHANGE_ON_FILE(project_id), body, config)
        .then(res => {
            notify(ON_CONFIRM_REJECT_FILE_SUCCESS)
            setIsConfirmed(true);
            onRejected(1);
        })
        .catch(err => {
            notify_error(ON_CONFIRM_REJECT_FILE_FAIL);
            console.error('Submitting changes ERROR: ', err);
        })
    }

    const downloadFile = (filename) => {
        onDownloadFile(project_id, filename, config);
    }

    return (
        <div className="row px-3 pt-3" key={key} >
            <div className="col-12 col-sm-6 d-flex justify-content-between align-items-center">
                <p className="m-0">{element.realName}</p>
                <Button bg_color="primary" outlined button_title="Télécharger" onClick={() => downloadFile(element.realName)} />
            </div>
            <div className="col-12 col-sm-6 d-flex flex-row align-items-center flex-md-column justify-content-center">
                <p className="m-0 text-primary text-center pr-3 pr-sm-0">Êtes-vous conforme avec le document?</p>
                <div className="d-flex flex-row justify-content-around align-items-center">
                    <span>Non</span>
                    <MySwitch onChange={onChange} defaultStatus={!switchState} />
                    <span>Oui</span>
                </div>
            </div>
            {
                switchState &&
                <div className="col-12 px-3 bg-light border">
                    <TextArea error={error} className="mb-0" value={changeDescription} onChange={handleChange} label="Expliquez pourquoi:" placeholder="Votre commentaire..." expanded />
                    <small className="text-muted">Toutes informations entrées et non sauvegardées ne serons pas prises en compte.</small>
                    {
                        !isConfirmed &&
                        <Button onClick={onConfirmChange} bg_color="primary" className="ml-auto mb-3" button_title="Sauvegarder" />
                    }
                </div>
            }
        </div>
    );
}

const StepConception4 = ({ CanConfirm, name }) => {
    const projectDates = useSelector(state => state.stepNavigation.dates);
    const [dates, setDate] = React.useState({
        start_date: {
            date: new Date(projectDates.start_consultation),
            formatted: moment(new Date(projectDates.start_consultation)).format('YYYY-MM-DD') + ' ' + defaultTimePicked,
        },
        end_date: {
            date: new Date(projectDates.end_consultation),
            formatted: moment(new Date(projectDates.end_consultation)).format('YYYY-MM-DD') + ' ' + defaultTimePicked,
        }
    });
    const [files, setFiles] = React.useState([])
    const [saveData, setSaveData] = React.useState(false);
    const project_id = useSelector(state => state.stepNavigation.project_id);
    const active_step = useSelector(state => state.stepNavigation.active);
    const config = useSelector(state => state.apiR);
    const dispatch = useDispatch();
    const [error, setError] = React.useState(false);
    const [rejection, setRejection] = React.useState(0);

    React.useEffect(() => {
        setDate({
            start_date: {
                date: new Date(projectDates.start_consultation),
                formatted: moment(new Date(projectDates.start_consultation)).format('YYYY-MM-DD') + ' ' + defaultTimePicked,
            },
            end_date: {
                date: new Date(projectDates.end_consultation),
                formatted: moment(new Date(projectDates.end_consultation)).format('YYYY-MM-DD') + ' ' + defaultTimePicked,
            }
        })
    }, [projectDates.start_consultation, projectDates.end_consultation])

    const onSubmit = () => {
        const body = {
            start_date: dates.start_date.formatted,
            end_date: dates.end_date.formatted
        }

        if (rejection !== 0) {
            notify_error(ON_CONFIRM_CONCEPT4_REJECTED_FILES);
            return;
        }

        if (error) {
            notify_error(ON_CONFIRM_CONCEPT4_INVALID_DATES);
            return;
        }

        setSaveData(true);
        api.post(API_CONFIRM_STEP_CONCEPTION(project_id), body, config)
        .then(res => {
            setTimeout(() => {
                notify(ON_CONFIRM_CONCEPT4_SUCCESS)
            }, 1000)
            setSaveData(false);
            dispatch(navigateSteps(active_step + 1))
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
        })
        .catch(err => {
            setSaveData(false);
            notify_error(ON_CONFIRM_CONCEPT4_FAIL);
            console.error('Confirm conception step ERROR: ', err);
        })
    }

    const handleDateChange = (value, name) => {
        if (error) {
            setError(false);
        }

        setDate({
            ...dates,
            [name]: value
        })
    }

    const sendModifications = () => {
        var body = new FormData();

        body.append('file_names', JSON.stringify(files.map(el => el.realName)));

        api.post(API_SUBMIT_ALL_CHANGES_FOR_FILES(project_id), body, config)
        .then(res => {
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
            setRejection(0);
            notify(ON_SEND_MODIFICATION_REJECTED_FILES_SUCCESS);
        })
        .catch(err => {
            notify_error(ON_SEND_MODIFICATION_REJECTED_FILES_FAIL);
            console.error('Send changes for files ERROR: ', err);
        })
    }

    React.useEffect(() => {
        api.post(API_GET_FILES_FROM_API(project_id), {type: NAME_TYPE_FILE_CONCEPTION_3}, config)
        .then(res => {
            setFiles([...res.data.etudes_dim_devis_esti, ...res.data.bilan_ge, ...res.data.bilan_ui, ...res.data.cctp_propre_gamme_def, ...res.data.doc_materiel_selec, ...res.data.dpgf_vierge ]);
        })
        .catch(err => {
            console.error('Get files from api ERROR: ', err);
        })
    }, [config, project_id]);

    return (
        <>
            <h4 className="text-primary m-0">Documents importés par le prescripteur Hitachi</h4>
            {
                files.length > 0 ?
                <div className="py-3">
                    {
                        <>
                            {files.map((element, i) => (
                              <FileDownloaderConfirm element={element} key={i} onRejected={(value) => setRejection(rejection + value)} />
                            ))}
                            <Button
                              onClick={() => {
                                  files.map((element, i) => {
                                      onDownloadFile(project_id, element.realName, config);
                                  })
                              }}
                              className="ml-auto mt-3"
                              bg_color="primary"
                              button_title="Télécharger tous les fichiers"
                            />
                        </>
                    }
                    {
                        rejection !== 0 &&
                        <Button onClick={sendModifications} className="ml-auto mt-3" bg_color="dark" button_title="Envoyer les modifications" />
                    }
                </div>
                :
                <div className="py-3 m-auto">
                    <p className="font-weight-bold">Aucun fichier n'a encore été téléversé</p>
                </div>
            }
            <h4 className="text-primary m-0">Dates de début et de fin de consultation</h4>
            <div className="row px-3 pb-3">
                <div className="col-12 col-sm-6">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'start_date')}
                        selectedDate={dates.start_date.date}
                        label="Date de début"
                        errored={() => setError(true)}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Datepicker
                        handleDateChange={(value) => handleDateChange(value, 'end_date')}
                        selectedDate={dates.end_date.date}
                        minDate={dates.start_date.date}
                        label="Date de fin"
                        errored={() => setError(true)}
                    />
                </div>
            </div>
            {
                CanConfirm &&
                <div className="d-flex justify-content-end">
                    <Button bg_color="primary" button_title={
                            saveData ?
                            <div class="spinner-border mx-5" style={{fontSize: 9, height: 20, width: 20}} role="status"><span class="sr-only">Loading...</span></div>
                            :
                            "Confirmer et valider la conception"
                        } onClick={saveData ? () => notify_error(WAIT_UNTIL_SAVE_TO_CONTINUE) : onSubmit} />
                </div>
            }
        </>
    );
};

export default StepConception4;
