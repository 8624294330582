import React from 'react';
import {withRouter} from 'react-router-dom';

const SidebarHeader = ({history}) => {
    function onHome(e) {
        e.preventDefault();
        if (window.location.href !== '/') {
            history.push('/');
            return;
        }
        window.scrollTo(0,0);
    }

    return (
        <div className="px-lg-2 pl-2 d-flex align-items-center justify-content-between">
            <img className="admin-brand-logo" src='/assets/img/FA_HITACHI_NEG_RGB.png' width="500" alt="atmos Logo" onClick={onHome} style={{cursor: 'pointer'}} />
            <div className="ml-auto">
                <div className="admin-close-sidebar text-light btn"></div>
            </div>
        </div>
    );
};

export default withRouter(SidebarHeader);