import React from 'react';
import { Input } from 'components/form/input';
import ConnectionStructure from '../connection-structure';
import api, { API_FORGOT_PASSWORD } from 'utils/api';
import { Button } from 'components/form/button';

const ForgotPassword = ({ location }) => {
    const [username, setUsername] = React.useState('');
    const [sended, setSended] = React.useState(false);
    const [error, setError] = React.useState('')

    const handleChange = (value) => {
        if (error) {
            setError('');
        }
        if (sended === undefined) {
            setSended(false);
        }
        setUsername(value);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!username || !username.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)) {
            setError('Veuillez renseigner une adresse mail valide...');
            setSended(undefined);
            return;
        }

        api.post(API_FORGOT_PASSWORD, {username: username})
        .then(res => {
            setSended(true);
        })
        .catch(err => {
            if (err.response && err.response.status === 400) {
                setError('L\'identifiant renseigné n\'est rataché à aucun compte existant...');
                console.error('Send username to reset password ERROR: ', err);
                return;
            }
            setError('Une erreur est survenue lors de l\'envoi du mail...');
            console.error('Send username to reset password ERROR: ', err);
        })
    }

    return (
        <>
            <ConnectionStructure>
                {
                    !sended ?
                    <form onSubmit={onSubmit}>
                        <h5 className="text-center p-b-20 font-weight-bold">Reinitialiser votre mot de passe</h5>
                        {
                            error &&
                            <p className="text-center text-primary">{error}</p>
                        }
                        <Input error={sended === undefined} value={username} onChange={handleChange} placeholder="Votre adresse mail de connexion" label="Votre adresse mail de connection" />
                        <Button className="mt-3 ml-auto" type="submit" bg_color="primary" outlined bold button_title="Envoyer" />
                    </form>
                    :
                    <div className="w-100 d-flex justify-content-center align-items-center">
                        <p>Un email a été envoyé à: {username}. Merci d'utiliser le lien fourni par mail pour continuer l'opération.</p>
                    </div>
                }
                <div className="col-xl-8 col-md-4 d-none d-md-block bg-cover bg-primary" style={{backgroundImage: "url('assets/img/background_image_login.jpg')"}}></div>
            </ConnectionStructure>
        </>
    );
};

export default ForgotPassword;
