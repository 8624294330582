import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'components/form/button';
import { openPriceList, notify_error } from 'utils/utils';
import api, { API_CONFIRM_STEP_CONSULTATION, API_SET_A_NEW_NEGOCIATION } from 'utils/api';
import { setCurrentStep, navigateSteps } from 'actions/steps-actions';
import { ON_CONFIRM_STEP_CONSULTATION2_ERROR, ON_PRICE_LIST_NOT_COMPLETED, ERROR_ON_SET_A_NEGOCIATION } from 'utils/notifications';

const StepConsultation3 = ({ CanConfirm }) => {
    const { project_id, active } = useSelector(state => state.stepNavigation);
    const config = useSelector(state => state.apiR);
    const dispatch = useDispatch();

    const handleNegociation = () => {
        api.post(API_SET_A_NEW_NEGOCIATION(project_id), {}, config)
        .then(res => {
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
        })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                notify_error(ON_PRICE_LIST_NOT_COMPLETED);
                console.error('On set a new negociation ERROR:', err);
                return;
            }
            notify_error(ERROR_ON_SET_A_NEGOCIATION);
            console.error('On set a new negociation ERROR:', err);
        })
    }

    const handleSubmit = () => {
        api.post(API_CONFIRM_STEP_CONSULTATION(project_id), {}, config)
        .then(res => {
            dispatch(setCurrentStep({current_step: res.data.currentStep, active: res.data.currentStatus}));
            dispatch(navigateSteps(active + 1))
        })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                notify_error(ON_PRICE_LIST_NOT_COMPLETED);
                console.error('Confirm consultation step ERROR: ', err);
                return;
            }
            notify_error(ON_CONFIRM_STEP_CONSULTATION2_ERROR);
            console.error('Confirm consultation step ERROR: ', err);
        })
    }

    return (
        <>
            <h4 className="text-primary">Négociation</h4>
            <p>Si il y a eu des négociations, alors cliquez sur "Modifier les prix des entreprises", sinon pour sauvegarder les prix tels qu'ils sont dirigez-vous sur "Confirmer l'étape et la consultation"</p>
            <Button onClick={() => openPriceList(project_id)} bg_color="dark" extended button_title="Compléter la grille tarifaire (obligatoire)" />
            {
                CanConfirm &&
                <div className="py-2 w-100 d-flex align-items-center">
                    <Button bg_color="primary" extended className="mr-1" onClick={handleNegociation} outlined button_title="Modifier les prix des entreprises" />
                    <Button bg_color="primary" extended className="ml-1" onClick={handleSubmit} button_title="Confirmer l'étape et la consultation" />
                </div>
            }
        </>
    );
};

StepConsultation3.propTypes = {
    CanConfirm: PropTypes.bool.isRequired,
};

export default StepConsultation3;