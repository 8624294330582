import React from 'react';
import { useSelector } from 'react-redux';
import { Input } from '../input';
import { Button } from '../button';
import api, { API_CREATE_NEUTRAL_COMPANY } from 'utils/api';
import { notify, notify_error } from 'utils/utils';
import { NEUTRAL_COMPANY_CREATION_SUCCESS, NEUTRAL_COMPANY_CREATION_FAIL } from 'utils/notifications';
import { Select } from '../select';
import useAutocompleteDate from 'hooks/use-autocomplete-date';

const CreateNeutralCompany = ({ onSubmit }) => {
    const [newCompany, setNewCompany] = React.useState('');
    const [inputsError, setInputsError] = React.useState({
        agency: true,
        department: true,
        name: true,
        postal_address: true,
        zip_code: true,
        town: true,
        siret: true,
        contact_first_name: true,
        contact_last_name: true,
        function: true,
        phone_number: true,
        email: true,
    });
    const [cities, departments, handleAddressCompletion, filterDepartments] = useAutocompleteDate();
    const config = useSelector(state => state.apiR);

    const submitting = () => {
        var errored = {...inputsError}

        if (Object.keys(newCompany).length < 12) {
            for (var u = 0; u < Object.keys(inputsError).length; u++) {
                errored[Object.keys(inputsError)[u]] = false;
            }
            for (var i = 0; i < Object.keys(newCompany).length; i++) {
                errored[Object.keys(newCompany)[i]] = true;
            }
            setInputsError(errored);
            return;
        }

        for (var k = 0; k < Object.keys(newCompany).length; k++) {
            if (!newCompany[Object.keys(newCompany)[k]]) {
                errored[Object.keys(newCompany)[k]] = false;
            }
        }

        if (newCompany && newCompany.email && !newCompany.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)) {
            errored.email = false;
        }

        setInputsError(errored);

        for (var j = 0; j < Object.keys(errored).length; j++) {
            if (!errored[Object.keys(errored)[j]]) {
                notify_error('Tous les champs obligatoires doivent être remplis...')
                return;
            }
        }

        api.post(API_CREATE_NEUTRAL_COMPANY, newCompany, config)
        .then(res => {
            notify(NEUTRAL_COMPANY_CREATION_SUCCESS(res.data.name));
            onSubmit({
                ...newCompany,
                id: res.data.id
            });
        })
        .catch(err => {
            notify_error(NEUTRAL_COMPANY_CREATION_FAIL);
            console.error('Create new neutral company ERROR: ', err);
        })
    }

    const handleChange = (value, name) => {
        if (inputsError[name] !== true) {
            setInputsError({...inputsError, [name]: true})
        } else if (inputsError[name] === undefined) {
            setInputsError({...inputsError, [name]: true})
        }

        if (name.includes('number') && !value.match(/^[-+]?[0-9]*\.?[0-9]*$/) && value !== '') {
            if (!newCompany[name]) {
                setNewCompany({
                    ...newCompany,
                    [name]: ''
                })
            }
            setInputsError({...inputsError, [name]: false});
            return;
        }

        setNewCompany({
            ...newCompany,
            [name]: value
        })
    }

    const handleSelect = (value, name) => {
        if (name === 'town') {
            if (newCompany.zip_code.length !== 5) {
                setNewCompany({
                    ...newCompany,
                    zip_code: value.zipCode && value.zipCode.slice(0,5),
                    [name]: value.label
                })
            } else {
                setNewCompany({
                    ...newCompany,
                    [name]: value.label
                });
            }
            filterDepartments(value.value);
        } else {
            setNewCompany({
                ...newCompany,
                [name]: value.label
            })
        }
    }

    return (
        <div className="row my-2 p-3 bg-light border">
            <Input error={!inputsError.agency} expanded label="Agence HITACHI *" placeholder="Agence HITACHI" className="col-12 col-md-3 pb-2" value={newCompany.agency} onChange={(value) => handleChange(value, 'agency')} />
            <Input error={!inputsError.siret} expanded label="Siret *" placeholder="Siret" className="col-12 col-md-3 pb-2" value={newCompany.siret} onChange={(value) => handleChange(value, 'siret')} />
            <Input error={!inputsError.name} expanded label="Nom de l'entreprise *"placeholder="Nom de l'entreprise" className="col-12 col-md-6 pb-2" value={newCompany.name} onChange={(value) => handleChange(value, 'name')} />
            <Input error={!inputsError.postal_address} expanded label="Adresse *" placeholder="Adresse" className="col-12 col-md-12 pb-2" value={newCompany.postal_address} onChange={(value) => handleChange(value, 'postal_address')} />
            <Input error={!inputsError.zip_code} onBlur={() => handleAddressCompletion(newCompany.zip_code)} expanded label="Code postal *"placeholder="Code postal" className="col-12 col-md-4 pb-2" value={newCompany.zip_code} onChange={(value) => handleChange(value, 'zip_code')} />
            <Select
                error={!inputsError.town}
                label="Ville *"
                placeholder="Ville"
                className="col-12 col-md-4 pb-2"
                selectElements={cities}
                defaultValue={cities.filter(el => el.label === newCompany.town)[0]}
                onSelect={(value) => handleSelect(value, 'town')}
                noOptionsLabel={() => 'Entrez un code postal pour choisir la ville'}
            />
            <Select
                error={!inputsError.department}
                label="Departement *"
                placeholder="Departement"
                className="col-12 col-md-4 pb-2"
                selectElements={departments}
                onSelect={(value) => handleSelect(value, 'department')}
                noOptionsLabel={() => 'Entrez un code postal pour choisir le département'}
            />
            <Input expanded label="Site internet (facultatif)" placeholder="Site internet (facultatif)" className="col-12 col-md-12 pb-2" value={newCompany.website_url} onChange={(value) => handleChange(value, 'website_url')} />
            <Input error={!inputsError.contact_first_name} expanded label="Nom *" placeholder="Nom" className="col-12 col-md-3 pb-2" value={newCompany.contact_first_name} onChange={(value) => handleChange(value, 'contact_first_name')} />
            <Input error={!inputsError.contact_last_name} expanded label="Prénom *" placeholder="Prénom" className="col-12 col-md-3 pb-2" value={newCompany.contact_last_name} onChange={(value) => handleChange(value, 'contact_last_name')} />
            <Input error={!inputsError.function} expanded label="Fonction *" placeholder="Fonction" className="col-12 col-md-6 pb-2" value={newCompany.function} onChange={(value) => handleChange(value, 'function')} />
            <Input expanded label="Téléphone portable (facultatif)"placeholder="Téléphone portable" className="col-12 col-md-3 pb-2" value={newCompany.mobile_number} onChange={(value) => handleChange(value, 'mobile_number')} />
            <Input error={!inputsError.phone_number} expanded label="Téléphone fixe *"placeholder="Téléphone fixe" className="col-12 col-md-3 pb-2" value={newCompany.phone_number} onChange={(value) => handleChange(value, 'phone_number')} />
            <Input error={!inputsError.email} expanded label="Adresse mail *"placeholder="Adresse mail" className="col-12 col-md-6 pb-2" value={newCompany.email} onChange={(value) => handleChange(value, 'email')} />
            <Button button_title="Créer" bg_color="primary" className="ml-auto" onClick={submitting} />
        </div>
    );
};

export default CreateNeutralCompany;