import { combineReducers } from 'redux';
import stepNavigation from './steps-reducer';
import apiR from './api-reducer';
import user from './user-reducer';
import feedR from './feed-reducer';

export default combineReducers({
    stepNavigation,
    feedR,
    user,
    apiR,
})