import React from 'react';
import { egScheme, uiScheme, oScheme } from './products-details-constants';

const ProductsDetailsTypes = ({ data, type }) => {
    const productsScheme = {
        eg: egScheme,
        ui: uiScheme,
        o: oScheme
    }

    return (
        <>
            {
                productsScheme[type] &&
                productsScheme[type].map(el => {
                    if (el.value === 'bigTitle') {
                        return (<h4 className="font-weight-bold m-0 py-3">{el.title}</h4>);
                    }
                    if (el.value === 'title') {
                        return (<h5 className="m-0 py-2"> - {el.title}</h5>);
                    }
                    if (!data) {
                        return (<p className="m-0 p-2"><span className="text-underline font-weight-bold mr-1">{el.title}:</span>---</p>);
                    }
                    return (<p className="m-0 p-2"><span className="text-underline font-weight-bold mr-1">{el.title}:</span>{data && data[el.value]}</p>);
                })
            }
        </>
    );
};

export default ProductsDetailsTypes;