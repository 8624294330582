import React from 'react';
import PropTypes from 'prop-types';
import IsolationFormCategories from './isolation-form-categories';

const IsolationFormGroup = ({ groups, onChange, disabled }) => {
    const [group, setGroup] = React.useState(groups);

    React.useEffect(() => setGroup(groups), [groups]);

    const handleChange = (value, current_id) => {
        if (!disabled) {
            const newGroup = {
                ...group,
                categories: group.categories.map(el => {
                    if (el.id === current_id) {
                        return value;
                    }
                    return el;
                })
            }

            if (onChange) {
                onChange(newGroup);
            }

            setGroup(newGroup);
        }
    }

    return (
        <div className="py-2">
            <h1 className="font-weight-bold">{group.title}</h1>
            {
                group.categories &&
                group.categories.map((el, i) => <IsolationFormCategories disabled={disabled} categories={el} onChange={(value) => handleChange(value, el.id)} key={el.id} />)
            }
        </div>
    );
};

IsolationFormGroup.propTypes = {
    groups: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default IsolationFormGroup;
