export const navigateSteps = (payload) => ({
    type: 'NAVIGATION_STEPPER',
    payload
})

export const toggleHistoryDrawer = (payload) => ({
    type: "OPEN_HISTORY_VIEW_PANE",
    payload
})

export const setCurrentProjectId = (payload) => ({
    type: "CURRENT_PROJECT_ID",
    payload
})

export const setCurrentStep = (payload) => ({
    type: "CURRENT_PROJECT_STEP",
    payload
})

export const setProjectGamme = (payload) => ({
    type: "CURRENT_PROJECT_COLLECTION",
    payload,
})

export const setAllCollections = (payload) => ({
    type: 'SET_COLLECTIONS',
    payload
})

export const setAllProjectDates = (payload) => ({
    type: 'SET_PROJECT_DATES',
    payload
})

export const setProjectCurrentPrice = (payload) => ({
    type: 'SET_PROJECT_PRICE',
    payload
})

export const setProjectRatios = (payload) => ({
    type: 'SET_PROJECT_RATIOS',
    payload
})

export const setIsolationForm = (payload) => ({
    type: 'SET_ISOLATION_FORM',
    payload
})