import React from 'react';
import { Button } from 'components/form/button';
import api, { API_USER_LOGIN, API_EMAIL_LOGIN } from 'utils/api';
import { setToken } from 'actions/api-actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BAD_LOGIN_IDENTIFIERS } from 'utils/constants';
import { loadUser } from 'actions/user-actions';
import { accountPositionAPI } from 'utils/utils';


const LoginForm = ({ setTokenHeader, loadUserData, history }) => {
    const [form, setValue] = React.useState({
        username: "",
        password: ""
    });

    const [errorLogin, setErrorLogin] = React.useState(undefined);

    const handleChange = (name, data) => {
        setValue({...form, [name]: data})

        document.querySelectorAll('.form-control').forEach(element => {
            if (!element) {
                return;
            }
            if (element.classList.contains('is-invalid')) {
                element.classList.remove('is-invalid');
                setErrorLogin(undefined);
            }
        })
    }

    const onLogin = (e) => {
        e.preventDefault();
        api.post(API_USER_LOGIN, form)
        .then(res => {
            if (!res.data.account || !res.data.token) {
                setErrorLogin('Une erreur est survenue lors de la connexion...');
                return;
            }
            loadUserData({
                position: res.data.account.position,
                ...res.data.account[accountPositionAPI(res.data.account.position)]
            });
            setTokenHeader(res.data.token);
            setValue({username: "", password: ""});
            history.push('/');
        })
        .catch(err => {
            api.post(API_EMAIL_LOGIN, form)
            .then(res => {
                loadUserData({
                    position: res.data.account.position,
                    ...res.data.account[accountPositionAPI(res.data.account.position)]
                });
                setTokenHeader(res.data.token);
                history.push('/register?token=' + res.data.token);
            })
            .catch(err => {
                if (err.response && (err.response.status === 403 || err.response.status === 401)) {
                    setErrorLogin(BAD_LOGIN_IDENTIFIERS);
                    setValue({username: "", password: ""});
                    document.querySelectorAll('.form-control').forEach(element => {
                        if (!element) {
                            return;
                        }
                        element.classList.add('is-invalid');
                    })
                }
                setErrorLogin(BAD_LOGIN_IDENTIFIERS);
                console.debug("Login user request ERROR: ", err);
            })
        })
    }

    return (
        <>
            <h3 className="text-center p-b-20 font-weight-bold">Connectez-vous</h3>
            {
                errorLogin &&
                <div className="text-center">
                    <p className="text-primary font-weight-bold">{errorLogin}</p>
                </div>
            }
            <form className="needs-validation" onSubmit={onLogin}>
                <div className="form-row">
                    <div className="form-group floating-label col-md-12">
                        <label>Adresse email</label>
                        <input id="loginUsername" type="email" value={form.username} onChange={(e) => handleChange('username', e.target.value)} required className="form-control" placeholder="Adresse email"/>
                    </div>
                    <div className="form-group floating-label col-md-12">
                        <label>Mot de passe</label>
                        <input id="loginPassword" type="password" value={form.password} onChange={(e) => handleChange('password', e.target.value)} required className="form-control" placeholder="Mot de passe"/>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <Button
                        outlined
                        bold
                        type="submit"
                        bg_color="primary"
                        className="py-2 px-4"
                        button_title="Se connecter"
                    />
                </div>

            </form>
            <p className="text-right p-t-10">
                <a href="/email_reset_password" className="text-underline">Mot de passe oublié ?</a>
            </p>
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    setTokenHeader: payload => dispatch(setToken(payload)),
    loadUserData: payload => dispatch(loadUser(payload)),
})

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));